import React from "react"

export default  function OptionsSelect({label, options, selectRef, className, onchange}) {
  if (Array.isArray(options)) {
    return (
      <label className='w-100'>
        <select className={`form-select ${className} `} onChange={onchange} value={selectRef}>
          {options.map((item, index) => (
            <option key={index} value={item.type}>
              {item.label}
            </option>
          ))}
        </select>
      </label>
    )
  }
}