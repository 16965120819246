import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import CarboneService from '../../service/carbone'
import {GenerateReportModal} from '../../_metronic/partials/modals/GenerateReportModal'
import axios from 'axios'
import {Loader} from '../../components/Loader'
import Swal from 'sweetalert2'

import reportsService from '../../service/reports'
const ReportsPage = () => {
  const [reportDatas, setReportDatas] = useState({})
  const [newReport, setNewReport] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [templateId, setTemplateId] = useState(null)
  const [file, setFile] = useState(null)
  const [name, setName] = useState('')
  const currentUser = JSON.parse(localStorage.getItem('user'))

  const [isLoading, setIsLoading] = useState(true)
  const getData = () => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `templates`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((res) => {
        setReportDatas(res.data)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const handleUploadFile = async () => {
    // const newTemplate = await CarboneService.addNewTemplate(file)
    // setTemplateId(newTemplate.data.templateId);
    // setNewReport(false);
    // setShowModal(true);
    const newTemplate = await CarboneService.addTemplateToDb(file, name)
    if (newTemplate.name !== '') {
      console.log(newTemplate.name)

      setReportDatas((prev) => [
        ...prev,
        {
          reportName: newTemplate.name,
          id: newTemplate.id,
        },
      ])
      setName('')
      setFile(null)
      setNewReport(false)
    }
  }

  const columns = [
    {
      name: 'Template Name',
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: '',
      right: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              Swal.fire({
                html: 'Are you sure to delete <strong>' + row?.name + '</strong> ?',
                icon: 'warning',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'No',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await reportsService.deleteReport(row?.id)
                  const cloned = [...reportDatas]
                  setReportDatas(cloned.filter((dt) => dt.id !== row.id))
                  Swal.fire(row.name + ' Removed successfully!', '', 'success')
                }
              })
            }}
            className='btn  btn-primary '
          >
            Delete Template
          </span>
        )
      },
    },
  ]

  if (isLoading) {
    return (
      <div className='text-center'>
        <Loader />
      </div>
    )
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <PageTitle breadcrumbs={[]}>Templates</PageTitle>
        {newReport ? (
          <div>
            <span
              onClick={() => setNewReport((prev) => !prev)}
              className='btn  btn-primary pull-left'
            >
              Back
            </span>

            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type='text'
              className='form-control'
              placeholder='Template Name'
            />

            <div className='mt-4'>Supported file types: DOCX file</div>
            <input
              accept='.docx'
              type='file'
              onChange={(e) => {
                setFile(e.target.files[0])
              }}
              className='form-control mt-4'
            />
            <button disabled={!file} onClick={handleUploadFile} className='btn btn-primary mt-2'>
              Upload schema
            </button>
          </div>
        ) : (
          <>
            <span
              onClick={() => setNewReport((prev) => !prev)}
              className='btn  btn-primary pull-right'
            >
              Add Template
            </span>
            <DataTable
              className='table-striped custom-filtered-table'
              pagination
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              columns={columns}
              data={reportDatas}
            />
            {templateId && (
              <GenerateReportModal
                templateId={templateId}
                show={showModal}
                handleClose={() => setShowModal(false)}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export {ReportsPage}
