import React from 'react'

const CustomCheck = ({checkStatus,checkType, setCheckStatus,setFamilyFilterDatas,familyFilterDatas}) => {
  const CHECK_STATUS_CASES = {
    notChecked: 0,
    checked: 1,
    rejected: 2,
  }

  const handleChangeCheckStatus = () => {
        


    if (checkStatus === CHECK_STATUS_CASES.notChecked) {
      setCheckStatus(CHECK_STATUS_CASES.checked)
    } else if (checkStatus === CHECK_STATUS_CASES.checked) {
      setCheckStatus(CHECK_STATUS_CASES.rejected)
    } else {
      setCheckStatus(CHECK_STATUS_CASES.notChecked)
    }
  }
  return (
    <>
    <div
      className='custom-check-wrapper rounded'
      style={{
        border: checkStatus !== CHECK_STATUS_CASES.notChecked ? '' : '1px solid #dbdfe9',
      }}
      onClick={() => handleChangeCheckStatus()}
    >
      {checkStatus === CHECK_STATUS_CASES.notChecked && ''}
      {checkStatus === CHECK_STATUS_CASES.checked && (
        <i className='ki-duotone ki-check-square text-success fs-1'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      )}
      {checkStatus === CHECK_STATUS_CASES.rejected && (
        <i className='ki-duotone ki-cross-square text-danger fs-1'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      )}
    </div>
      <span className='d-flex' style={{marginLeft:'5px'}}>
        <label className='checkIsInclude' style={{opacity: checkStatus !== CHECK_STATUS_CASES.checked ? '0.3' : ''}}> In </label>
        <label className='checkIsExclude' style={{marginLeft:'5px',opacity: checkStatus !== CHECK_STATUS_CASES.rejected ? '0.3' : ''}}> Ex </label>
        <label className='checkIsEmpty'style={{marginLeft:'5px',opacity: checkStatus !== CHECK_STATUS_CASES.notChecked ? '0.3' : '', }}> Em </label>
      </span>
      
      </>
  )
}

export {CustomCheck}
