import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import WgsForm from '../../NewRun/Forms/WgsForm'
import WesForm from '../../NewRun/Forms/WesForm'
import TpsForm from '../../NewRun/Forms/TpsForm'
import RnaseqForm from '../../NewRun/Forms/RnaseqForm'
import SomTestRnaForm from '../../NewRun/Forms/SomTestRnaForm'
import SomTestTumorForm from '../../NewRun/Forms/SomTestTumorForm'
import SomTestTumorRnaForm from '../../NewRun/Forms/SomTestTumorRnaForm'
import PrsForm from '../../NewRun/Forms/PrsForm'

const SampleInformationStep = ({
  selectedProcessType,
  sampleData,
  fileType,
  userProcessTypes,
  enrichmentKits,
  institutes,
  setInstitutes,
  physicians,
  setPhysicians,
  analysts,
  setAnalysts,
  setPhysiciansString,
  setAnalystsString,
  setInstitutesString,
  enrichmentKit,
  setEnrichmentKit,
  enrichmentOrRegion,
  setEnrichmentOrRegion,
  setSampleData,
  validateSampleId,
}) => {
  return (
    <div className='w-100'>
      <div className='pb-10  '>
        <h2 className='fw-bolder text-dark'>Sample Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      {selectedProcessType === 'wgs' && (
        <WgsForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
          validateSampleId={validateSampleId}
        />
      )}
      {selectedProcessType === 'wes' && (
        <WesForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
          userProcessTypes={userProcessTypes}
          enrichmentOrRegion={enrichmentOrRegion}
          setEnrichmentOrRegion={setEnrichmentOrRegion}
          enrichmentKits={enrichmentKits}
          enrichmentKit={enrichmentKit}
          setEnrichmentKit={setEnrichmentKit}
          validateSampleId={validateSampleId}
        />
      )}
      {selectedProcessType === 'tps' && (
        <TpsForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          selectedProcessType={selectedProcessType}
          validateSampleId={validateSampleId}
        />
      )}
      {selectedProcessType === 'rnaseq' && (
        <RnaseqForm
          sampleData={sampleData}
          setSampleData={setSampleData}
          validateSampleId={validateSampleId}
          selectedProcessType={selectedProcessType}
        />
      )}
      {selectedProcessType === 'som-test-rna' && (
        <SomTestRnaForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          userProcessTypes={userProcessTypes}
          enrichmentOrRegion={enrichmentOrRegion}
          setEnrichmentOrRegion={setEnrichmentOrRegion}
          enrichmentKits={enrichmentKits}
          enrichmentKit={enrichmentKit}
          institutes={institutes}
          setInstitutes={setInstitutes}
          physicians={physicians}
          setPhysicians={setPhysicians}
          setEnrichmentKit={setEnrichmentKit}
          analysts={analysts}
          setAnalysts={setAnalysts}
          setPhysiciansString={setPhysiciansString}
          setAnalystsString={setAnalystsString}
          setInstitutesString={setInstitutesString}
          selectedProcessType={selectedProcessType}
          validateSampleId={validateSampleId}
        />
      )}
      {selectedProcessType === 'som-test-tumor' && (
        <SomTestTumorForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          userProcessTypes={userProcessTypes}
          enrichmentOrRegion={enrichmentOrRegion}
          setEnrichmentOrRegion={setEnrichmentOrRegion}
          enrichmentKits={enrichmentKits}
          enrichmentKit={enrichmentKit}
          institutes={institutes}
          setInstitutes={setInstitutes}
          physicians={physicians}
          setPhysicians={setPhysicians}
          setEnrichmentKit={setEnrichmentKit}
          analysts={analysts}
          setAnalysts={setAnalysts}
          setPhysiciansString={setPhysiciansString}
          setAnalystsString={setAnalystsString}
          setInstitutesString={setInstitutesString}
          selectedProcessType={selectedProcessType}
          validateSampleId={validateSampleId}
        />
      )}
      {selectedProcessType === 'som-test-tumor-rna' && (
        <SomTestTumorRnaForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          userProcessTypes={userProcessTypes}
          enrichmentOrRegion={enrichmentOrRegion}
          setEnrichmentOrRegion={setEnrichmentOrRegion}
          enrichmentKits={enrichmentKits}
          enrichmentKit={enrichmentKit}
          institutes={institutes}
          setInstitutes={setInstitutes}
          physicians={physicians}
          setPhysicians={setPhysicians}
          setEnrichmentKit={setEnrichmentKit}
          analysts={analysts}
          setAnalysts={setAnalysts}
          setPhysiciansString={setPhysiciansString}
          setAnalystsString={setAnalystsString}
          setInstitutesString={setInstitutesString}
          selectedProcessType={selectedProcessType}
          validateSampleId={validateSampleId}
        />
      )}
      {selectedProcessType === 'prs' && (
        <PrsForm
          sampleData={sampleData}
          fileType={fileType}
          setSampleData={setSampleData}
          validateSampleId={validateSampleId}
          selectedProcessType={selectedProcessType}
        />
      )}
    </div>
  )
}

export {SampleInformationStep}
