/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'

const FaqPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'FAQ'})}</PageTitle>
      <div className='row'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div className='accordion' id='kt_accordion_1'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_1'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_1'
                  >
                    What is GenNext?
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_1'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_1'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    GenNext is a decentralized peer-to-peer (P2P) genomic data analysis platform.
                    Due to the design and structure of GenNext, genomic data is not shared with
                    anyone other than the computer where the analysis will be performed and the
                    person performing the analysis.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_2'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_2'
                  >
                    Why GenNext?
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_2'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_2'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    GenNext is differentiated from other data analysis platforms on the market
                    thanks to its unique architecture and network structure, providing an
                    exceptional user experience. <br />
                    <br />
                    <b>Successful data analysis and reporting:</b> The GenNext platform incorporates
                    the latest literature in genetics/genomics, enabling successful analysis of
                    next-generation sequencing data.
                    <br />
                    <br />
                    <b> Privacy and security:</b> With GenNext, data is not stored and saved in a
                    centralized location, which means there is less risk of your data being
                    compromised by malicious actors. <br />
                    <br />
                    <br />
                    <b> High-throughput and fast data transfer:</b> Thanks to GenNext s specially
                    developed network structure, data is transferred directly between users. This
                    significantly reduces the time and bandwidth required to transfer data to
                    centralized servers or cloud platforms used in traditional big data analysis
                    platforms. <br />
                    <br />
                    <b>Secure analysis of personal data and EU-GDPR compliance:</b>
                    In a decentralized P2P network, data is stored and analyzed locally on users
                    devices. This provides greater privacy and security compared to traditional big
                    data analytics platforms that depend on storing and processing data in a central
                    location. In addition, this structure allows data to be analyzed in compliance
                    with EU-GDPR (The European General Data Protection Regulation) and KVKK
                    (Personal Data Protection Law-Turkiye).
                    <br />
                    <br />
                    <b>Alignment with Web3.0 principles:</b>
                    Decentralized P2P networks are one of the underlying technologies of Web3.0,
                    which can be defined as a decentralized and user-owned internet. GenNexts
                    network design, which enables decentralized P2P data transfer and analysis, is
                    in line with the principles underlying Web3.0.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_3'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_3'
                  >
                    What is GenNext klinik ?
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_3'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    GenNext klinik is a genomic data analysis and reporting tool within the GenNext
                    platform. With its rich analysis and annotation tools, advanced filtering and
                    reporting options, and user-friendly interface, GenNext klinik offers a unique
                    genomic data analysis experience.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_4'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_4'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_4'
                  >
                    What can I do with GenNext klinik ?
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_4'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_4'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    <b> Comprehensive analyses and streamlined diagnostic process:</b> GenNext
                    klinik aims to accurately detect and prioritize genomic variants thanks to its
                    comprehensive workflow and rich annotation options developed by following the
                    current literature and based on Best-Practice applications. This makes it a
                    powerful aid in the diagnosis of genetic diseases. Using the pre-customized
                    filtering options integrated into the platform, the clinically important
                    variants among thousands are prioritized with GenNext klinik ,which helps
                    accelerate the diagnostic process. In addition, thanks to the multi-sample
                    analysis options, the genetic disease diagnosis process is supported by taking
                    heredity into account with Duo and Trio analyzes.
                    <br />
                    <br />
                    <b>Find actionable variants and treatment options in cancer:</b> GenNext klinik
                    uses a comprehensive somatic variant detection workflow and helps identify
                    genetic alterations that can support the diagnostic-treatment process. Using
                    up-to-date literature and databases, identified variants are reported and
                    presented in terms of their diagnostic significance, pathogenicity and drug
                    interactions that can help the therapeutic process.
                    <br />
                    <br />
                    <b>Fast reporting: </b>With GenNext klinik ‘s automated report generation
                    feature, you can report on the variants you select after analysis using report
                    templates and print the report format instantly. You can also upload the report
                    format you want to use to the platform and access variant reports in the format
                    you specify in seconds.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {FaqPage}
