const Avatar = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  // user.first_name = "Ali"
  const split = user?.first_name.split(' ')
  var avatar = split[0].charAt(0)
  if (split[1]) {
    avatar = avatar + split[1].charAt(0)
  }
  return (
    <div className='symbol avatar symbol-50px me-5'>
      <span> {avatar.toUpperCase()}</span>
      {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/user.png')} /> */}
    </div>
  )
}

export default Avatar
