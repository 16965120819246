import axios from './index'
 
const deleteReport = async (templateId) => {
  const response = await axios.delete(`/templates/${templateId}`)
  return response.data;
}
 
const reportsService = {
 
  deleteReport
}

export default reportsService
