/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {SampleInformation} from '../../components/RunDetail/SampleInformation'
import {RunInformation} from '../../components/RunDetail/RunInformation'
import {QCSummary} from '../../components/RunDetail/QCSummary'
import {Comments} from '../../components/RunDetail/Comments'
import {GeneList} from '../../components/RunDetail/GeneList'
import {FamilyFilter} from '../../components/RunDetail/FamilyFilter'
import {VaritanFiltering} from '../../components/RunDetail/VariantFiltering'
import {NotCompleted} from '../../components/RunDetail/NotCompleted'
import {kFormatter} from '../../utils'
import {Loader} from '../../components/Loader'
import runsService from '../../service/runs'
import Swal from 'sweetalert2'

const RunDetailPage = (props) => {
  const intl = useIntl()
  const {id} = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [lockButtons, setLockButtons] = useState(false)
   const [annotators, setAnnotators] = useState(
    process.env.REACT_APP_DEFAULT_ANNOTATORS !== undefined && process.env.REACT_APP_DEFAULT_ANNOTATORS !== null ? process.env.REACT_APP_DEFAULT_ANNOTATORS.split(',') : []
  )
  const [hasFilterData, setHasFilterData] = useState({})
  const [familyFilterDatas, setFamilyFilterDatas] = useState({
    filterType: 'solo',
    filterDetails: {
      dominant: true,
      recessive: false,
    },
  })
  const [selectedFamilyFilterButton, setSelectedFamilyFilterButton] = React.useState('solo')

  const [familyFilterGeneList, setFamilyFilterGeneList] = useState()
  const [clickedHPOItems, setClickedHPOItems] = useState([
    {
      id: '1',
      name: ' Add more genes',
      list: [],
      listStr: '',
    },
  ])
  const [selectedHPOItem, setSelectedHPOItem] = useState({})
  const [geneSymbolListStr, setGeneSymbolListStr] = useState('')
  const [selectedGeneList, setSelectedGeneList] = useState({})
  const [previewStr, setPreviewStr] = useState('')
  const [previewList, setPreviewList] = useState([])
  const runNodeUrl = process.env.REACT_APP_NODE_URL
  const [intervalId, setIntervalId] = useState(null)

  const checkHasFilterData = async (type) => {
    const getFilterInfo = await runsService.getFilterInfo(id)
    setHasFilterData(getFilterInfo.data)
  }

  const checkHasFilter = async (type, node = null) => {
    const getFilterInfo = await runsService.getFilterInfo(id)
    setAnnotators(
      getFilterInfo.data[type] &&
        Array.isArray(getFilterInfo.data[type].annots) &&
        getFilterInfo.data[type].annots.length > 0
        ? getFilterInfo.data[type].annots
        : annotators
    )
    if (getFilterInfo.status === 200) {
      if (getFilterInfo.data[type] !== null) {
        let prefix = 'S'
        if (type === 'duo') {
          prefix = 'D'
        }
        if (type === 'trio') {
          prefix = 'T'
        }
        const checkFilterCravat = await runsService.checkFilterStatusCravat(
          prefix,
          id,
          JSON.parse(localStorage.getItem('user')).id,
          `https://${node ? node : runData.onNode}.node.${runNodeUrl}`
        )

        if (checkFilterCravat.status !== 200) {
          setLockButtons(true)
          const newIntervalId = setInterval(async () => {
            try {
              const checkRe = await runsService.checkFilterStatusCravat(
                prefix,
                id,
                JSON.parse(localStorage.getItem('user')).id,
                `https://${node ? node : runData.onNode}.node.${runNodeUrl}`
              )
              if (checkRe.status === 200) {
                setLockButtons(false)
                clearInterval(newIntervalId)
              }
            } catch (retryError) {}
          }, 10000)
          setIntervalId(newIntervalId)
        }
      } else {
        setLockButtons(false)
        if (intervalId) {
          clearInterval(intervalId) // Burada setInterval'i durduruyoruz.
        }
      }
    }
  }

  // function mergeUniqueLists(array) {
  //   const mergedList = []
  //   const seen = new Set()

  //   for (const item of array) {
  //     for (const element of item.list) {
  //       if (!seen.has(element)) {
  //         mergedList.push(element)
  //         seen.add(element)
  //       }
  //     }
  //   }

  //   return mergedList
  // }
  const applyFilterHandler = async () => {
    var jobPrefix = 'S'
    if (familyFilterDatas.filterType === 'solo') {
      // const filteredHpo = mergeUniqueLists(clickedHPOItems)
      setFamilyFilterDatas(familyFilterDatas)
      // const mergeHPOandCustom = familyFilterGeneList + '\n' + filteredHpo.join('\n')
      familyFilterDatas.filterDetails.geneList = previewStr.split('\n')
      familyFilterDatas.filterDetails.geneList = familyFilterDatas.filterDetails.geneList.filter(
        (str) => str !== ''
      )
      familyFilterDatas.filterDetails.strict = false

      familyFilterDatas.filterDetails.annots = annotators
      // const filterGenerate = await runsService.runFilterGeneration(familyFilterDatas)
      // const filterSession = {
      //   jobId: jobPrefix + '_' + runData.id,
      //   username: JSON.parse(localStorage.getItem('user')).id,
      //   filter: filterGenerate.data,
      // }
      // const filterSessionService = await runsService.createFilterSession(
      //   filterSession,
      //   `https://${runData.onNode}.node.${runNodeUrl}`
      // )
      // const cravatUrl = `https://${runData.onNode}.node.${runNodeUrl}/result/index.html?job_id=${
      //   filterSession.jobId
      // }&username=${filterSession.username}&token=${JSON.parse(localStorage.getItem('user')).token}`
      // window.open(cravatUrl, '_blank', 'noreferrer')
      // return
    }

    if (familyFilterDatas.filterType === 'duo') {
      jobPrefix = 'D'
      if (familyFilterDatas.filterDetails.caseSelect === null) {
        Swal.fire(' Please Select a Case!', '', 'error')
        return
      }
      familyFilterDatas.filterDetails.strict = false
    } else if (familyFilterDatas.filterType === 'trio') {
      jobPrefix = 'T'
      if (familyFilterDatas.filterDetails.fatherSelect === null) {
        Swal.fire(' Please Select Father!', '', 'error')
        return
      } else if (familyFilterDatas.filterDetails.motherSelect === null) {
        Swal.fire(' Please Select Mother!', '', 'error')
        return
      }
    }
    // const filteredHpo = mergeUniqueLists(clickedHPOItems)
    setFamilyFilterDatas(familyFilterDatas)
    const mergeHPOandCustom = previewStr !== '' ? previewStr : ''
    // const mergeHPOandCustom = {previewStr !== ''  ? previewStr.join('\n') : ''
    familyFilterDatas.filterDetails.runId = id
    familyFilterDatas.filterDetails.proband = runData?.samples[0].sampleId
    familyFilterDatas.filterDetails.geneList = mergeHPOandCustom.split('\n')
    familyFilterDatas.filterDetails.annots = annotators
    familyFilterDatas.filterDetails.geneList = familyFilterDatas.filterDetails.geneList.filter(
      (str) => str !== ''
    )
    const annotatePayload = {
      filterType: familyFilterDatas.filterType,
      sampleId: runData?.samples[0].id,
      filterData: familyFilterDatas.filterDetails,
    }

    const annotateFilter = await runsService.annotateFilter(id, annotatePayload)
    if (annotateFilter.status === 200) {
      const filterGenerate = await runsService.runFilterGeneration(familyFilterDatas)
      const filterSession = {
        jobId: jobPrefix + '_' + runData.id,
        username: JSON.parse(localStorage.getItem('user')).id,
        filter: filterGenerate.data,
      }

      const filterSessionService = await runsService.createFilterSession(
        filterSession,
        `https://${runData.onNode}.node.${runNodeUrl}`
      )
      const cravatUrl = `https://${runData.onNode}.node.${runNodeUrl}/result/index.html?job_id=${
        filterSession.jobId
      }&username=${filterSession.username}&token=${JSON.parse(localStorage.getItem('user')).token}`
      window.open(cravatUrl, '_blank', 'noreferrer')
    } else if (annotateFilter.status === 201) {
      setLockButtons(true)
      setTimeout(() => {
        const intervalId = setInterval(async () => {
          try {
            const checkFilterStatus = await runsService.checkFilterStatusCravat(
              jobPrefix,
              id,
              JSON.parse(localStorage.getItem('user')).id,
              `https://${runData.onNode}.node.${runNodeUrl}`
            )

            if (checkFilterStatus.status === 200) {
              setLockButtons(false)
              const filterGenerate = await runsService.runFilterGeneration(familyFilterDatas)
              const filterSession = {
                jobId: jobPrefix + '_' + runData.id,
                username: JSON.parse(localStorage.getItem('user')).id,
                filter: filterGenerate.data,
              }
              // const filterSessionService = await runsService.createFilterSession(
              //   filterSession,
              //   `https://${runData.onNode}.node.${runNodeUrl}`
              // )
              // // const cravatUrl = `https://${
              // //   runData.onNode
              // // }.node.${runNodeUrl}/result/index.html?job_id=${filterSession.jobId}&username=${
              // //   filterSession.username
              // // }&token=${JSON.parse(localStorage.getItem('user')).token}`
              // // window.open(cravatUrl, '_blank', 'noreferrer')
              // // console.log(cravatUrl)
              // // console.log(filterSessionService)
              clearInterval(intervalId)
            }
          } catch (retryError) {}
        }, 10000)
      }, 10000) // 5 saniye bekleme süresi burada
    }
  }
  const [runData, setRunData] = useState({})
  const [filterInfo, setFilterInfo] = useState({})

  const getData = async () => {
    try {
      var getRunDetail = await runsService.getRunDetail(id)
      if (getRunDetail.status === 200) {
        setRunData(getRunDetail.data)
        setIsLoading(false)
        checkHasFilter('solo', getRunDetail.data.onNode) // Sonra diğerleri çalışacak
      }
    } catch (error) {
      window.location.href = '/runs'
    }
  }
  useEffect(() => {
    getData()
    // checkHasFilter('solo')
    checkHasFilterData()
  }, [])
  if (isLoading) {
    return (
      <div className='text-center'>
        <Loader />
      </div>
    )
  }

  if (runData?.status !== 'Completed') {
    return (
      <>
        <NotCompleted runData={runData} />
      </>
    )
  }

  // if (runData?.processType === 'ces') {
  //   return (
  //     <>
  //     <NewRunDetailPage runData={runData} familyFilterGeneList={familyFilterGeneList} setFamilyFilterGeneList={setFamilyFilterGeneList}/>
  //     </>
  //   )
  // }
  const checkData = (data) => {
    if (data) {
      return data
    } else {
      return 'N/A'
    }
  }
  const runsBreadCrumbs = [
    {
      title: 'Back',
      path: '/runs',
      isSeparator: false,
      isActive: true,
    },
  ]
  return (
    <>
      <PageTitle back='runs' breadcrumbs={runsBreadCrumbs}>
        {intl.formatMessage({id: 'RUN.DETAIL'})}
      </PageTitle>
      <div className='row'>
        <div className='col-xl-12'>
          {/* <Link className='btn btn-primary mb-2' to='/runs'>
            Back
          </Link> */}

          <div className='my-2 runDetailHeader'>
            <SampleInformation
              runId={id}
              id={runData?.samples[0].id}
              sampleId={runData?.samples[0].sampleId}
              sampleName={runData?.samples[0].sampleName}
              project={runData.project}
              isSolved={runData?.isSolved}
              refferingPhysicians={
                runData?.samples[0].physicians ? runData?.samples[0].physicians : []
              }
              analysts={runData?.samples[0].analysts ? runData?.samples[0].analysts : []}
              phenoTypes={runData?.samples[0].phenotypes ? runData?.samples[0].phenotypes : []}
              cohorts={runData?.samples[0].cohorts ? runData?.samples[0].cohorts : []}
              submissionDate={runData?.submissionDate}
            />

            <RunInformation
              processType={runData?.processType}
              enrichmenKit={checkData(runData?.enrichmentKit)}
              currentNode={runData?.onNode}
              currentRunId={id}
              pipelineVersion={'2.1.1'}
              genomeBuild={runData?.genomeBuild}
              runStatus={runData?.status}
            />
            <QCSummary
              roh={checkData(runData?.samples[0]?.sampleQuality.roh)}
              totalReads={checkData(kFormatter(runData?.samples[0]?.sampleQuality.totalReads))}
              meanCoverage={checkData(runData?.samples[0]?.sampleQuality.meanCoverage)}
              medianCoverage={checkData(runData?.samples[0]?.sampleQuality.medianCoverage)}
              gender={checkData(runData?.samples[0]?.sampleQuality.gender)}
              sampleId={runData?.samples[0].sampleId}
              currentNode={runData?.onNode}
              dataType={runData?.samples[0]?.dataType}
              runId={id}
              contamination={checkData(runData?.samples[0]?.sampleQuality.contamination)}
              coverage={runData?.samples[0]?.sampleQuality[0]}
              readLenght={checkData(runData?.samples[0]?.sampleQuality.avgReadLength)}
            />
            <Comments runId={id} comment={runData.comments} />
          </div>
          <div className='my-4 runDetailBody'>
            <GeneList
              currentRunId={runData?.id}
              clickedHPOItems={clickedHPOItems}
              setClickedHPOItems={setClickedHPOItems}
              selectedHPOItem={selectedHPOItem}
              setSelectedHPOItem={setSelectedHPOItem}
              geneSymbolListStr={geneSymbolListStr}
              previewStr={previewStr}
              setPreviewStr={setPreviewStr}
              previewList={previewList}
              setPreviewList={setPreviewList}
              setGeneSymbolListStr={setGeneSymbolListStr}
              setFamilyFilterGeneList={setFamilyFilterGeneList}
              selectedGeneList={selectedGeneList}
              setSelectedGeneList={setSelectedGeneList}
            />
            <FamilyFilter
              applyFilterHandler={applyFilterHandler}
              filterInfo={filterInfo}
              familyFilterDatas={familyFilterDatas}
              selectedFamilyFilterButton={selectedFamilyFilterButton}
              setSelectedFamilyFilterButton={setSelectedFamilyFilterButton}
              setFamilyFilterDatas={setFamilyFilterDatas}
              checkHasFilter={checkHasFilter}
              setFilterInfo={setFilterInfo}
              hasFilterData={hasFilterData}
              checkHasFilterData={checkHasFilterData}
              lockButtons={lockButtons}
              setLockButtons={setLockButtons}
              currentRunId={runData?.id}
              sampleId={runData?.samples[0].sampleId}
            />
          </div>
          <div className='my-4 runDetailFooter'>
            <VaritanFiltering
              currentRunId={runData?.id}
              currentNode={runData?.onNode}
              familyFilterDatas={familyFilterDatas}
              applyFilterHandler={applyFilterHandler}
              lockButtons={lockButtons}
              dataType={runData?.samples[0]?.dataType}
              annotators={annotators}
              setAnnotators={setAnnotators}
              setFamilyFilterDatas={setFamilyFilterDatas}
              hasFilterData={hasFilterData}
              clickedHPOItems={clickedHPOItems}
              selectedHPOItem={selectedHPOItem}
              previewStr={previewStr}
              selectedFamilyFilterButton={selectedFamilyFilterButton}
              geneSymbolListStr={geneSymbolListStr}
              selectedGeneList={selectedGeneList}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {RunDetailPage}
