import {useEffect, useState} from 'react'
import somaticService from '../../../service/somatic'
import {Loader} from '../../Loader'

const TxCitations = ({txC}) => {
  const [pmIds, setPmIds] = useState([])
  const [pmDatas, setPmDatas] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [links, setLinks] = useState([])
  // useEffect(() => {
  //   getPm(pmIds)
  // }, [txC])
  const getPm = async (result) => {
    var reqData = result.filter((item) => {
      return item !== 0
    })
    var reqRes = await somaticService.getPMDetails(reqData)
    setPmDatas(reqRes.data)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    setPmDatas([])
    setPmIds([])
    let links = []
    if (txC !== null) {
      let items = txC.split(';')
      if (!isNaN(items[0].trim())) {
        var result = items?.map(function (x) {
          if (!isNaN(x) === true) {
            return parseInt(x, 10)
          }
          return 0
        })
        var textResult = items?.map(function (x) {
          if (isNaN(x) === true) {
            return x
          }
        })
        if (textResult.length > 0) {
          const links = []
          textResult?.map((item) => {
            if (item === '' || item === undefined) {
              return
            }
            let link = item.match(/\bhttps?:\/\/\S+/gi)
            if (link) {
              let title = item.split('(')[0].trim()
              links.push({title: title, link: link[0]})
            }
          })
          setLinks(links)
        }
        console.log(links)
        setPmIds(result)
        getPm(result)
      } else {
        items.forEach((item) => {
          let link = item.match(/\bhttps?:\/\/\S+/gi)
          if (link) {
            let title = item.split('(')[0].trim()
            links.push({title: title, link: link[0]})
          }
        })
        setLinks(links)
      }
    } else {
      setIsLoading(false)
    }
  }, [txC])
  if (isLoading) {
    return (
      <div className={`m-10 card mx-10 h-auto text-center mb-10 `}>
        <h5 className='m-4'> Citations</h5>
        <Loader />
      </div>
    )
  }
  return (
    <div className={`m-10 card mx-10 h-auto citations-widget `}>
      <h5 className='m-4'> Citations</h5>
      {pmDatas.length > 0 &&
        pmDatas.map((item, index) => {
          return (
            <div className='mt-2 mx-4  mb-2' key={index}>
              <a
                href={`https://www.doi.org/${item?.doi?.id}`}
                rel='noreferrer'
                className='text-link fs-8'
                target='_BLANK'
              >
                {item?.title}
              </a>
            </div>
          )
        })}
      {links.length > 0 &&
        links.map((item, index) => {
          return (
            <div className='m-4' key={index}>
              <a href={item.link} rel='noreferrer' className='text-link' target='_BLANK'>
                {item.title}
              </a>
            </div>
          )
        })}
    </div>
  )
}

export default TxCitations
