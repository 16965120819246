import {useEffect, useRef, useState} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../_metronic/partials'
import RunsService from '../../service/runs'
import {Loader} from '../Loader'
const DashboardChart = ({className}) => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()
  const [runs, setRuns] = useState([])
  const [totalRunsCount, setTotalRunsCount] = useState(0)
  const [chartFilterType, setChartFilterType] = useState(0)
  const [filteredRuns, setFilteredRuns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const getData = async () => {
    setIsLoading(true)
    const getAllRuns = await RunsService.getAllRuns()
    if (getAllRuns.status === 200) {
      setRuns(getAllRuns.data)
      setFilteredRuns(getAllRuns.data)
      setTotalRunsCount(getAllRuns.data.length)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const chartFilter = (id) => {
    const today = new Date()
    const firstDayOfWeek = new Date(today)
    const firstDayOfMonth = new Date(today)
    const todayDate = today.getDate()
    const firstDayOfWeekDate = todayDate - today.getDay() + (today.getDay() === 0 ? -6 : 1)
    firstDayOfWeek.setDate(firstDayOfWeekDate)
    firstDayOfMonth.setDate(1)
    setChartFilterType(id)
    if (id === 1) {
      var filtered = runs.filter(
        (item) => new Date(item.submissionDate).toDateString() === today.toDateString()
      )
    } else if (id === 2) {
      var filtered = runs.filter((item) => {
        const submissionDate = new Date(item.submissionDate)
        return submissionDate >= firstDayOfWeek && submissionDate <= today
      })
    } else if (id === 3) {
      var filtered = runs.filter((item) => {
        const submissionDate = new Date(item.submissionDate)
        return submissionDate >= firstDayOfMonth && submissionDate <= today
      })
    } else {
      var filtered = runs
    }
    setFilteredRuns(filtered)
    setTotalRunsCount(filtered?.length)
  }
  useEffect(() => {
    chartFilter(0)
  }, [setChartFilterType])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, filteredRuns))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, isLoading, filteredRuns])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Number of Tests</span>
          <span className='text-muted fw-semibold fs-7'>
            Total Number of Tests : {totalRunsCount}
          </span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          <span
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary  ${
              chartFilterType === 0 ? 'active' : ''
            } px-4 me-1`}
            onClick={() => chartFilter(0)}
            id='kt_charts_widget_5_year_btn'
          >
            All
          </span>
          <span
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
              chartFilterType === 1 ? 'active' : ''
            } px-4 me-1`}
            onClick={() => chartFilter(1)}
            id='kt_charts_widget_5_year_btn'
          >
            Daily
          </span>
          <span
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary  ${
              chartFilterType === 2 ? 'active' : ''
            } px-4 me-1`}
            onClick={() => chartFilter(2)}
            id='kt_charts_widget_5_month_btn'
          >
            Weekly
          </span>
          <span
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
              chartFilterType === 3 ? 'active' : ''
            } px-4 me-1`}
            onClick={() => chartFilter(3)}
            id='kt_charts_widget_5_week_btn'
          >
            Monthly
          </span>
        </div>
      </div>

      <div className='card-body text-center'>
        {isLoading && <Loader />}
        {!isLoading && (
          <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px'}}></div>
        )}
      </div>
    </div>
  )
}

export {DashboardChart}
function getChartOptions(height, runs) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const errorColor = getCSSVariableValue('--bs-primary')
  const runningColor = getCSSVariableValue('--bs-warning')
  const successColor = getCSSVariableValue('--bs-success')
  const submittedColor = getCSSVariableValue('--bs-brand')
  var processTypesCount = {
    wes: {completed: 0, running: 0, error: 0, submitted: 0},
    wgs: {completed: 0, running: 0, error: 0, submitted: 0},
    tps: {completed: 0, running: 0, error: 0, submitted: 0},
    rnaSeq: {completed: 0, running: 0, error: 0, submitted: 0},
    ces: {completed: 0, running: 0, error: 0, submitted: 0},
    targetted: {completed: 0, running: 0, error: 0, submitted: 0},
    somatic: {completed: 0, running: 0, error: 0, submitted: 0},
  }
  runs.map((item) => {
    if (item.processType === 'som-test-tumor-rna') {
      if (item.status === 'Completed') {
        processTypesCount['somatic'].completed += 1
      } else if (item.status === 'Running') {
        processTypesCount['somatic'].running += 1
      } else if (item.status === 'Error') {
        processTypesCount['somatic'].error += 1
      } else if (item.status === 'Submitted') {
        processTypesCount['somatic'].submitted += 1
      }
      return null
    }
    if (item.processType === 'som-test-tumor') {
      if (item.status === 'Completed') {
        processTypesCount['somatic'].completed += 1
      } else if (item.status === 'Running') {
        processTypesCount['somatic'].running += 1
      } else if (item.status === 'Error') {
        processTypesCount['somatic'].error += 1
      } else if (item.status === 'Submitted') {
        processTypesCount['somatic'].submitted += 1
      }
      return null
    }
    if (item.processType === 'som-test-rna') {
      if (item.status === 'Completed') {
        processTypesCount['somatic'].completed += 1
      } else if (item.status === 'Running') {
        processTypesCount['somatic'].running += 1
      } else if (item.status === 'Error') {
        processTypesCount['somatic'].error += 1
      } else if (item.status === 'Submitted') {
        processTypesCount['somatic'].submitted += 1
      }
      return null
    }

    if (item.status === 'Completed') {
      processTypesCount[item.processType].completed += 1
    } else if (item.status === 'Running') {
      processTypesCount[item.processType].running += 1
    } else if (item.status === 'Error') {
      processTypesCount[item.processType].error += 1
    } else if (item.status === 'Submitted') {
      processTypesCount[item.processType].submitted += 1
    }
  })
  var maxCount = 0

  for (var category in processTypesCount) {
    var totalCount =
      processTypesCount[category].completed +
      processTypesCount[category].running +
      processTypesCount[category].error +
      processTypesCount[category].submitted
    if (totalCount > maxCount) {
      maxCount += totalCount
    }
  }

  return {
    series: [
      {
        name: 'Error',
        data: [
          processTypesCount.wes.error,
          processTypesCount.wgs.error,
          processTypesCount.targetted.error,
          processTypesCount.rnaSeq.error,
          processTypesCount.somatic.error,
        ],
      },
      {
        name: 'Submitted',
        data: [
          processTypesCount.wes.submitted,
          processTypesCount.wgs.submitted,
          processTypesCount.targetted.submitted,
          processTypesCount.rnaSeq.submitted,
          processTypesCount.somatic.submitted,
        ],
      },
      {
        name: 'Running',
        data: [
          processTypesCount.wes.running,
          processTypesCount.wgs.running,
          processTypesCount.targetted.running,
          processTypesCount.rnaSeq.running,
          processTypesCount.somatic.running,
        ],
      },
      {
        name: 'Success',
        data: [
          processTypesCount.wes.completed,
          processTypesCount.wgs.completed,
          processTypesCount.targetted.completed,
          processTypesCount.rnaSeq.completed,
          processTypesCount.somatic.completed,
        ],
      },
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '12%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['WES', 'WGS', 'TPS', 'RNA-seq', 'Somatic'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: maxCount + 10,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return ' ' + val
        },
      },
    },
    colors: [errorColor, submittedColor, runningColor, successColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
