import React from "react"

export default  function SwitchInput({label, switchRef, disabled, onchange, className, labelClass}) {
    return (
      <div className={`d-flex align-items-center gap-2 mt-4 mb-4 ${className}`}>
        <input
          id={'zff'}
          type='checkbox'
          role='switch'
          value={switchRef}
          checked={switchRef}
          onChange={onchange}
          disabled={disabled}
        />
        <h5 className='mb-0'>
          {' '}
          <label className={labelClass} htmlFor={'zff'}>
            {label}
          </label>{' '}
        </h5>
      </div>
    )
  }
