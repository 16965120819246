import React, {useEffect} from 'react'
import somaticService from '../../../service/somatic'

const CosmicTable = ({cosmic}) => {
  let splitted
  let cosvId
  let occurrences
  const nihService = async (id) => {
    const data = await somaticService.getCosmic(id)
    window.open(
      `https://cancer.sanger.ac.uk/cosmic/search?genome=38&q=${data.data[1][0]}`,
      '_blank'
    )
  }
  if (cosmic !== '.' && cosmic !== null && cosmic !== undefined) {
    splitted = cosmic?.split(';')
    cosvId = splitted[0].split('=')[1]

    occurrences = splitted[1]
      .split('=')[1]
      .split(',')
      .map((item) => {
        let [count, label] = item.split('(')
        return {count: parseInt(count), label: label.slice(0, -1)}
      })
  }

  // return (
  //   <div className={`m-10 card mx-10 h-auto`}>
  //     <h5 className='m-4'> Cosmic</h5>
  //     <table className='table border'>
  //       <thead>
  //         <tr>
  //           <th scope='col' className='p-5'>
  //             ID
  //           </th>
  //           <th scope='col' className='p-5'>
  //             Occurrences
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         <tr>
  //           <th scope='row' className='p-5 cursor-pointer' onClick={() => nihService(cosvId)}>
  //             {cosvId}
  //           </th>
  //           <td className='p-5'>
  //             {occurrences?.map((item, index) => {
  //               return (
  //                 <span className='badge badge-success mx-2 mt-2' key={index}>
  //                   {item.label}({item.count})
  //                 </span>
  //               )
  //             })}
  //           </td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   </div>
  // )
  function sliceText(text, no) {
    // Metni belirtilen karakter sırasından böl
    let first = text.slice(0, no)
    let second = text.slice(no)
    return {
      first: first,
      second: second,
    }
  }
  return (
    <div className={`m-10 card mx-10 h-auto`}>
      <h5 className='m-4'> Cosmic</h5>
      <div
        className='cosmic-list m-5'
        style={{
          maxWidth: '100%',
        }}
      >
        <div className='cosmic-item d-flex flex-column'>
          <label
            className='cosmic-title fs-7 my-2 font-weight-bold mx-2 cursor-pointer'
            onClick={() => nihService(cosvId)}
          >
            {cosvId}
          </label>
          <div className='cosmic-each'>
            {occurrences?.map((item, index) => {
              console.log(item.label.length)
              if (item.label.length > 30) {
                var val = sliceText(item.label, 15)
                return (
                  <span className='badge badge-success mx-1 fs-9' key={index}>
                    {val.first} <br></br> {val.second} ({item.count})
                  </span>
                )
              }
              return (
                <span className='badge badge-success mx-1  fs-9' key={index}>
                  {item.label}({item.count})
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CosmicTable
