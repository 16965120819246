import {useEffect, useState} from 'react'
import runsService from '../service/runs'
import NodeService from '../service/node'

const useAdvencedFilters = () => {
  const [runsDatas, setRunsDatas] = useState([])
  const [initialData, setInitialData] = useState([])
  const [tableIsLoading, setTableIsLoading] = useState(true)
  const [userNodes, setUserNodes] = useState([])

  const getNodes = async () => {
    const nodeData = await NodeService.getNodeList()
    var nodes = nodeData.map((node) => {
      return {
        nodeName: node.nodeName,
        isChecked: false,
      }
    })
    console.log(nodes)
    setUserNodes(nodes)
  }
  useEffect(() => {
    getNodes()
  }, [])
  const getData = async () => {
    setTableIsLoading(true)
    const getAllRuns = await runsService.getAllRuns()
    const result = []
    if (getAllRuns.status === 200) {
      getAllRuns?.data?.forEach((element) => {
        element.isSolved = element.isSolved === true ? 'Yes' : 'No'
        if (
          element.processType === 'som-test-tumor-rna' ||
          element.processType === 'Somatic Testing (DNA+RNA)'
        ) {
          element.processType = 'Somatic Testing (DNA+RNA)'
        } else if (
          element.processType === 'som-test-tumor' ||
          element.processType === 'Somatic Testing (DNA)'
        ) {
          element.processType = 'Somatic Testing (DNA)'
        } else if (
          element.processType === 'som-test-rna' ||
          element.processType === 'Somatic Testing (RNA)'
        ) {
          element.processType = 'Somatic Testing (RNA)'
        } else {
          element.processType = element.processType.toUpperCase()
        }
        result.push(element)
      })
      setRunsDatas(result)
      setInitialData(result)
      setTableIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const processTypes = [
    {
      id: 1,
      name: 'Wgs',
      isChecked: false,
    },
    {
      id: 2,
      name: 'Wes',
      isChecked: false,
    },
    {
      id: 3,
      name: 'Tps',
      isChecked: false,
    },
    {
      id: 4,
      name: 'Somatic Testing (DNA+RNA)',
      isChecked: false,
    },
    {
      id: 5,
      name: 'Somatic Testing (DNA)',
      isChecked: false,
    },
    {
      id: 6,
      name: 'Somatic Testing (RNA)',
      isChecked: false,
    },
  ]
  let xFilters = [
    {
      id: 4,
      name: '30xFilter',
      filterArea: 'thirdX',
    },
    {
      id: 5,
      name: '50xFilter',
      filterArea: 'fifthX',
    },
    {
      id: 6,
      name: 'meanCoverage',
      filterArea: 'MeanCovarege',
    },
  ]
  const defaultArragement = {min: '', max: ''}
  const [initialFilters, setInitialFilters] = useState({
    process: processTypes,
    node: userNodes,
    MeanCovarege: defaultArragement,
    oneX: defaultArragement,
    fiveX: defaultArragement,
    fiftX: defaultArragement,
    thirdX: defaultArragement,
    fifthX: defaultArragement,
    reads: defaultArragement,
    roh: defaultArragement,
    contamination: defaultArragement,
    gender: null,
    enrichment: '',
    genome: '',
  })
  useEffect(() => {
    const initialFilters = {
      process: processTypes,
      node: userNodes,
      MeanCovarege: defaultArragement,
      oneX: defaultArragement,
      fiveX: defaultArragement,
      fiftX: defaultArragement,
      thirdX: defaultArragement,
      fifthX: defaultArragement,
      reads: defaultArragement,
      roh: defaultArragement,
      contamination: defaultArragement,
      gender: null,
      enrichment: '',
      genome: '',
    }
    setInitialFilters(initialFilters)
    setFilters(initialFilters)
  }, [userNodes])

  const [filters, setFilters] = useState(initialFilters)

  const changeCheck = (id) => {
    console.log('asas')
    let temp = [...filters.process]
    const index = temp.findIndex((x) => x.id === id)
    if (index === -1) return
    temp[index].isChecked = !temp[index].isChecked
    setFilters({...filters, process: temp ?? []})
  }
  const changeCheckNode = (nodeName) => {
    let temp = [...filters.node]
    console.log(filters)
    const index = temp.findIndex((x) => x.nodeName === nodeName)
    if (index === -1) return
    temp[index].isChecked = !temp[index].isChecked
    console.log(temp)
    setFilters({...filters, node: temp ?? []})
  }

  const advancedFilterSubmitHandler = () => {
    var filteredList = initialData
    if (!!filters?.process && filters?.process?.some((process) => process?.isChecked)) {
      const filteredItems = filters?.process
        .filter((item) => {
          return item.isChecked
        })
        .map((item) => item.name.toLowerCase())
      filteredList = filteredList.filter((filterItem) =>
        filteredItems.includes(filterItem.onNode.toLowerCase())
      )
    }
    if (!!filters?.node && filters?.node?.some((node) => node?.isChecked)) {
      console.log(filters)
      const filteredItems = filters?.node
        .filter((item) => {
          return item.isChecked
        })
        .map((item) => item.nodeName.toLowerCase())
      filteredList = filteredList.filter((filterItem) =>
        filteredItems.includes(filterItem.onNode.toLowerCase())
      )
    }
    console.log(filters)
    if (!!filters?.gender) {
      filteredList = filteredList.filter(
        (filterItem) => filterItem?.qcInformation?.gender === filters.gender
      )
    }
    if (!!filters?.enrichment && filters?.enrichment !== null) {
      filteredList = filteredList.filter((filterItem) =>
        filterItem?.enrichmentKit?.toLowerCase()?.includes(filters?.enrichment)
      )
    }
    if (!!filters?.genome && filters?.genome !== null) {
      filteredList = filteredList.filter((filterItem) =>
        filterItem?.genomeAssemblyVersion?.toLowerCase()?.includes(filters?.genome)
      )
    }

    if (!!filters['reads']?.min && Number(filters['reads']?.min) > 0) {
      filteredList = filteredList.filter((item) => {
        return item?.qcInformation?.totalReadsCount >= filters['reads']?.min
      })
    }
    if (!!filters['reads']?.max && Number(filters['reads']?.max) > 0) {
      filteredList = filteredList.filter((item) => {
        return item?.qcInformation?.totalReadsCount <= filters['reads']?.max
      })
    }
    if (!!filters['RoH']?.min && Number(filters['RoH']?.min) > 0) {
      filteredList = filteredList.filter((item) => {
        return item?.qcInformation?.roh >= filters['RoH']?.min
      })
    }
    if (!!filters['RoH']?.max && Number(filters['RoH']?.max) > 0) {
      filteredList = filteredList.filter((item) => {
        return item?.qcInformation?.roh <= filters['RoH']?.max
      })
    }
    if (!!filters['contamination']?.min && Number(filters['contamination']?.min) > 0) {
      filteredList = filteredList.filter((item) => {
        return item?.qcInformation?.contamination >= filters['contamination']?.min
      })
    }
    if (!!filters['contamination']?.max && Number(filters['contamination']?.max) > 0) {
      filteredList = filteredList.filter((item) => {
        return item?.qcInformation?.contamination <= filters['contamination']?.max
      })
    }

    xFilters.forEach((xFilter) => {
      let field = xFilter.name.split('Filter')[0].toUpperCase()

      if (field[0] === 'M') field = 'meanCoverage'

      if (!!filters[xFilter.filterArea].min && Number(filters[xFilter.filterArea]?.min) > 0) {
        filteredList = filteredList.filter((item) => {
          return item?.qcInformation?.coverage[field] >= filters[xFilter.filterArea]?.min
        })
      }
      if (!!filters[xFilter.filterArea].max && Number(filters[xFilter.filterArea]?.max) > 0) {
        filteredList = filteredList.filter((item) => {
          return item?.qcInformation?.coverage[field] <= filters[xFilter.filterArea]?.max
        })
      }
    })

    setRunsDatas(filteredList ?? [])
  }

  const clearAdvancedFilter = () => {
    setRunsDatas(initialData)
    setFilters(initialFilters)
  }

  return {
    clearAdvancedFilter,
    advancedFilterSubmitHandler,
    changeCheck,
    runsDatas,
    setFilters,
    filters,
    processTypes,
    changeCheckNode,
    userNodes,
    xFilters,
    tableIsLoading,
    getData,
  }
}

export {useAdvencedFilters}
