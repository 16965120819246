import {useEffect, useState} from 'react'
import somaticService from '../../../service/somatic'
import {Loader} from '../../Loader'
import {IsppChart} from './IsppChart'

const IsppWidget = ({selectedRow}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorData, setErrorData] = useState(false)
  const [chartDatas, setChartDatas] = useState([])
  const getData = async () => {
    if (selectedRow.Chrom === undefined) {
      setIsLoading(false)
      setErrorData(true)
      return
    }
    setIsLoading(true)
    setErrorData(false)
    var reqData = {
      chr: selectedRow.Chrom,
      pos: selectedRow.Start,
      ref: selectedRow.Ref,
      alt: selectedRow.Alt,
      refver: 'hg38',
    }
    const req = await somaticService.getIsppData(reqData)

    if (req.status === 200) {
      setChartDatas(req.data.dbnsfpScoreGroups)
      setIsLoading(false)
      return
    } else {
      setIsLoading(false)
      setErrorData(true)
    }
  }

  useEffect(() => {
    getData()
  }, [selectedRow])

  if (isLoading) {
    return (
      <div className={`my-20  text-center mb-10 `}>
        <h5 className='m-4'> ISPP Charts</h5>
        <Loader />
      </div>
    )
  }
  if (errorData) {
    return (
      <div className={`my-20  text-center mb-10 `}>
        <h5 className='m-4'> ISPP Charts</h5>
        There is no data
      </div>
    )
  }
  return (
    <div className={`mt-20   `}>
      <h5 className='m-4'> ISPP Charts</h5>
      <div className='col-md-12 row'>
        {chartDatas?.map((item, index) => {
          return (
            <div className='col-md-3' key={index}>
              <IsppChart
                color={[
                  index === 0
                    ? '#e93645'
                    : index === 1
                    ? '#101f3d'
                    : index === 2
                    ? '#e59b39'
                    : '#3c8bc6',
                ]}
                chartData={item}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default IsppWidget
