import {FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import UserService from '../../../../service/user'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {Loader} from '../../../../components/Loader'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    localStorage.removeItem('user')
    saveAuth(undefined)
    setCurrentUser(undefined)
    // window.location.href="/auth";
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {logout, setCurrentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async() => {
      if (!localStorage.getItem('user')) {
        logout()
        setIsLoading(false);
      } else {
        await UserService.getUserProfile()
        setCurrentUser(JSON.parse(localStorage.getItem('user') || ''))
        setIsLoading(false);
      }
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <LayoutSplashScreen />
      {isLoading ? (
        <div
          className='d-flex items-center'
          style={{justifyContent: 'center', alignItems: 'center'}}
        >
          <Loader />
        </div>
      ) : (
        children
      )}
    </>
  )
}

export {AuthProvider, AuthInit, useAuth}
