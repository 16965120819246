import {useParams} from 'react-router'
import {PageTitle} from '../../_metronic/layout/core'

import {useEffect, useMemo, useState} from 'react'
import {Loader} from '../../components/Loader'
import RunsService from '../../service/runs'
import TableComponentTreeData from '../../components/TableComponent/TableComponentTreeData'
import {svHeader} from '../../static/somaticVariantHeaders'

const RunDetailTablePage = () => {
  const {id} = useParams()

  const [exonic, setExonic] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [runData, setRunData] = useState([])
  const [tableDataHeader, setTableDataHeader] = useState([])
  const [errorData, setErrorData] = useState(false)
  const [tableDataOrder, setTableDataOrder] = useState([])
  const [isAdvanced, setIsAdvanced] = useState(false)
  const [selectedRows, setSelectedRows] = useState({})
  const [gridOptions, setGridOptions] = useState({
    autoResize: {
      container: '#demo-container',
      rightPadding: 10,
    },
    enableAutoSizeColumns: true,
    enableAutoResize: true,
    enableFiltering: true,
    frozenColumn: 1,
    enableRowSelection: true,
    enableCheckboxSelector: true,
    enableTreeData: true,
    createPreHeaderPanel: true,
    showPreHeaderPanel: true,
    preHeaderPanelHeight: 53,
    checkboxSelector: {
      hideSelectAllCheckbox: true,
    },
    treeDataOptions: {
      columnId: 'reportSelect',
      parentPropName: 'parentId',
      levelPropName: 'treeLevel',
      indentMarginLeft: 15,
      initiallyCollapsed: true,
      initialSort: {
        columnId: 'Chrom',
        direction: 'ASC',
      },
    },
    enablePagination: false,
    multiColumnSort: false,
    showCustomFooter: true,
    headerRowHeight: 40,
    rowHeight: 30,
  })
  const [selectedWidgetItem, setSelectedWidgetItem] = useState(undefined)
  const requestId = id.split('&')
  const getMatchingData = (array1, array2) => {
    const matchingData = []
    var filtered = array1.filter((elm) => elm)
    array2.forEach((item) => {
      const matchedChildren = item.GeneName.filter((child) => filtered.includes(child))
      if (matchedChildren.length > 0) {
        matchingData.push({
          ...item
        })
      }
    })
    return matchingData
  }

  const getData = async () => {
    setIsLoading(true)
    const user = JSON.parse(localStorage.getItem('user'))
    const runData = await RunsService.getRunDetail(requestId[0])

    if (runData.status === 200) {
      setRunData(runData.data)
    }
    var genesLists = []
    if (requestId[2] !== undefined) {
      const geneReq = await RunsService.getGenesListSingle(requestId[2])
      if (geneReq.status === 200) {
        genesLists = geneReq.data.genesLists.map((item) => item.genesList).flat()
      }
    }

    const baseUrl = `https://${runData.data.onNode}.node.gennext.bio/api/v0`
    const reqPath = isAdvanced ? 'adv_variants' : 'variants'
    const reqData = {
      userId: user.id,
      runId: requestId[0],
      sampleId: runData.data.samples[0].sampleId,
      datatype: ['sv'],
      filter: {
        isExonic: exonic,
      },
    }
    var result = []
    const germlineData = await RunsService.getRunDetailTableData(baseUrl, reqData, reqPath)
    if (germlineData.status !== 200) {
      setIsLoading(false)
      setErrorData(true)
      return
    }
    if (germlineData.status === 200) {
      germlineData?.data.svData?.rows?.forEach((item) => {
        item.reportSelect = ''

        if (requestId[1] === 'clinical') {
          if (
            item.ACMGClass !== '3' &&
            item.ACMGClass !== '4' &&
            item.ACMGClass !== '5' &&
            item.ACMGClass !== 'full=3' &&
            item.ACMGClass !== 'full=4' &&
            item.ACMGClass !== 'full=5'
          ) {
            return
          }
        }
        result.push(item)
      })
      if (requestId[2] !== undefined) {
        const geneFilteredResult = await  getMatchingData(genesLists, result)
        result = geneFilteredResult
      }
    }

    const sortedHeader = germlineData?.data?.svData?.order.reduce((acc, {name, columnGroup}) => {
      if (germlineData?.data?.svData?.header.hasOwnProperty(name)) {
        acc[name] = {
          value: germlineData?.data?.svData?.header[name],
          columnGroup,
        }
      }
      return acc
    }, {})
    console.log(result)
    var reportSelect = {
      reportSelect: 'Child',
      columnGroup: null,
    }
    const finalHeader = {reportSelect, ...sortedHeader}

    setIsLoading(false)
    setTableData(result)
    setTableDataHeader(finalHeader)
    setTableDataOrder(germlineData?.data?.svData?.order)
  }
  useEffect(() => {
    getData()
  }, [exonic, isAdvanced])

  const setRowExonic = () => {
    setExonic(!exonic)
  }
  const memoizedDataTable = useMemo(
    () => (
      <TableComponentTreeData
        data={tableData}
        tableHeader={tableDataHeader}
        selectedRows={selectedRows}
        selectedWidgetItem={selectedWidgetItem}
        setSelectedWidgetItem={setSelectedWidgetItem}
        gridOptions={gridOptions}
        setGridOptions={setGridOptions}
        setSelectedRows={setSelectedRows}
        gridId={'germlineTable'}
      />
    ),
    [tableData, svHeader]
  )
  if (isLoading) {
    return (
      <>
        <div className='card'>
          <div className='card-body text-center'>
            <Loader />
          </div>
        </div>
      </>
    )
  }
  if (errorData) {
    return (
      <>
        <div className='card'>
          <div className='card-body text-center'>Something went wrong. Please reload the page.</div>
        </div>
      </>
    )
  }
  if (tableData.length < 1) {
    return (
      <>
        <div className='card'>
          <div className='card-body text-center'>There is no data.</div>
        </div>
      </>
    )
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <PageTitle breadcrumbs={[]}>{runData?.samples[0].sampleId} Variants</PageTitle>
        <div className='table-header d-flex justify-content-between'>
          <div className='exonic-switch-input form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <label className='font-weight-bold mx-3'> Exonic </label>
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={exonic}
              value={exonic}
              onChange={setRowExonic}
              name='exonicSwitch'
            />
          </div>
          {/* <span
            className={` mx-2 btn pull-right btn-${isAdvanced ? 'primary' : 'secondary'} `}
            // data-bs-target='#AdvancedDataModal'
            onClick={() => {
              setIsAdvanced(!isAdvanced)
            }}
          >
            {isAdvanced ? 'Hide' : '  Show'} Advanced Data
          </span> */}
        </div>
        <div style={{height: '85%'}}>{memoizedDataTable}</div>
      </div>
    </div>
  )
}

export {RunDetailTablePage}
