import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import axios from 'axios'
import {useDebounce} from '../../hooks'

const PhenotypeModal = ({
  phenoTypes,
  setPhenotypesKeyUpState,
  phenotypesKeyUpState,
  savePhenotypes,
  phenotypesState,
  setPhenotypesState,
}) => {
  const [searchHpo, setSearchHpo] = useState(false)

  const [hpoTerms, setHpoTerms] = useState('')
  const debouncedPhenoType = useDebounce(hpoTerms, 500)
  const [hpoList, setHpoList] = useState([])

  const handleTextareaChange = (e) => {
    setPhenotypesKeyUpState(e.target.value)
  }

  const hpoChange = (event) => {
    setHpoTerms(event.target.value)
  }
  const hideSearchHPO = () => {
    setHpoTerms('')
    setSearchHpo(false)
  }
  const handleSubmit = () => {
    var phenotypesArray = phenotypesKeyUpState.split('\n').filter((line) => line.trim() !== '') // Boş satırları filtrele
    if (phenotypesArray.length === 0) {
      phenotypesArray = []
    }
    setPhenotypesState(phenotypesArray)
    savePhenotypes(phenotypesArray)
  }
  const getData = () => {
    if (debouncedPhenoType === '') {
      return
    }
    axios
      .get(`https://ontology.jax.org/api/hp/search?q=${debouncedPhenoType}&max=-1`)
      .then((res) => {
        const termsResponse = res?.data?.terms
        console.log(termsResponse)
        if (!!termsResponse && Array.isArray(termsResponse) && termsResponse.length > 0) {
          setHpoList(termsResponse)
        } else {
          setHpoList([])
        }
      })
  }
  useEffect(getData, [debouncedPhenoType])
  const addHpoToPhenotypeList = (id) => {
    
    phenotypesKeyUpState = phenotypesKeyUpState + '\n' + id
    setPhenotypesKeyUpState(phenotypesKeyUpState)
  }
  return (
    <div className='modal fade bd-example-modal-lg ' tabIndex={-1} id='PhenotypeModal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Edit Phenotypes </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='searchGeneWrap position-relative'>
              <input
                type='text'
                className='form-control form-control-solid w-100'
                placeholder={'Search...'}
                value={hpoTerms}
                onChange={hpoChange}
                onFocus={() => {
                  setSearchHpo(true)
                  getData()
                }}
              />
              {!!searchHpo && (
                <i
                  className={`clearSearchGeneInput cursor-pointer fa fa-remove`}
                  onClick={() => hideSearchHPO()}
                ></i>
              )}

              {(searchHpo || hpoTerms !== '') && (
                <div
                  className='searchResultsWrap'
                  style={{
                    right: '0',
                  }}
                >
                  {hpoList.length > 0 && (
                    <ul className='list-group'>
                      {hpoList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className='list-group-item'
                            onClick={() => addHpoToPhenotypeList(item.id)}
                          >
                            {item.name} : <b style={{fontSize: 10}}>{item.id}</b>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )}
            </div>
            <textarea
              className='form-control form-control-lg my-3 form-control-solid'
              style={{minHeight: '350px'}}
              value={phenotypesKeyUpState} // Değişiklik burada
              onChange={handleTextareaChange}
            ></textarea>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              onClick={handleSubmit}
              data-bs-dismiss='modal'
              className='btn btn-primary'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhenotypeModal
