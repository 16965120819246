/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'

type Props = {
  className: string
}

const rows: Array<{description: string; url: string}> = [
  {description: 'Link 1', url: '#'},
  {description: 'Link 2', url: '#'},
  {description: 'Link 3', url: '#'},
]

const ListsWidget26 = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>External Links</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-5'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <a href={row.url} target='_blank' className='text-primary fw-semibold fs-6 me-2' rel="noreferrer">
              {row.description}
            </a>
            
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)
export {ListsWidget26}
