/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import UserRunListTable from '../../components/UserRunList/UserRunListTable'
const UserRunListPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.RUNSLIST'})}</PageTitle>
      <UserRunListTable/>
    </>
  )
}

export {UserRunListPage}
