import React, {useRef, useState, useEffect} from 'react'

const loadIgv = async () => {
  const igvModule = await import('igv/dist/igv.esm')
  return igvModule.default || igvModule
}

const igvStyle = {
  paddingTop: '10px',
  paddingBottom: '10px',
  width: '95%',
}

const IGVApp = ({selectedRow}) => {
  return <AppIgv selectedRow={selectedRow} />
}

const AppIgv = ({selectedRow}) => {
  const container = useRef(null)
  const [bamFile, setBamFile] = useState(null)
  const [baiFile, setBaiFile] = useState(null)
  const [browser, setBrowser] = useState(null)
  const [igv, setIgv] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchIgv = async () => {
      const igvInstance = await loadIgv()
      setIgv(igvInstance)
    }
    fetchIgv()
  }, [])

  useEffect(() => {
    if (igv && !browser) {
      createBrowser()
    }
  }, [igv])

  useEffect(() => {
    if (browser && selectedRow) {
      const value = `${selectedRow.Chrom}:${selectedRow.Start}-${selectedRow.End}`
      browser.search(value)
    }
  }, [selectedRow, browser])

  const createBrowser = async () => {
    if (!selectedRow || !igv || browser) return

    setLoading(true)
    const value = `${selectedRow.Chrom}:${selectedRow.Start}-${selectedRow.End}`
    const igvOptions = {genome: 'hg38', locus: value}
    const newBrowser = await igv.createBrowser(container.current, igvOptions)
    setBrowser(newBrowser)
    setLoading(false)
  }

  const handleBamFileChange = (event) => {
    const file = event.target.files[0]
    setBamFile(file)
  }

  const handleBaiFileChange = (event) => {
    const file = event.target.files[0]
    setBaiFile(file)
  }

  useEffect(() => {
    if (bamFile && baiFile && browser) {
      const trackConfig = {
        name: bamFile.name,
        type: 'alignment',
        format: 'bam',
        url: URL.createObjectURL(bamFile),
        indexURL: URL.createObjectURL(baiFile),
      }
      browser.loadTrack(trackConfig)
    }
  }, [bamFile, baiFile, browser])

  if (!selectedRow) return null

  return (
    <div>
      {loading && (
        <div className='loading-spinner'>
          <p>Loading IGV...</p>
        </div>
      )}
      <div className='igv-file-input mt-5 mx-5'>
        <div className='col-md-12 row'>
          <div className=' col-md-3 my-3'>
            <label htmlFor='bamFileWidget' className='font-weight-bold mb-2'>
              BAM File
            </label>
            <input
              type='file'
              className='form-control'
              accept='.bam'
              id='bamFileWidget'
              onChange={handleBamFileChange}
            />
          </div>
          <div className=' col-md-3 my-3'>
            <label htmlFor='baiFileWidget' className='font-weight-bold mb-2'>
              BAI File
            </label>
            <input
              type='file'
              className='form-control'
              accept='.bai'
              id='baiFileWidget'
              onChange={handleBaiFileChange}
            />
          </div>
        </div>
      </div>
      <div ref={container} style={igvStyle} className='mx-10'></div>
    </div>
  )
}

export default IGVApp
