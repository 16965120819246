import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import axios from 'axios'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}auth/login`,
            {
              email: values.email,
              password: values.password,
            },
            {
              headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
              },
            }
          )
          .then(async (response) => {
            if (response.data.status === 'success') {
              const userInfo = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/me', {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${response.data.token}`,
                  'Content-Type': 'application/json',
                },
              })
              const userData = {
                id: userInfo?.data?.id ?? '',
                username: values.email,
                token: response.data.token,
                first_name: userInfo?.data?.name ?? '',
                last_name: '',
                email: values.email,
                type: userInfo?.data?.type,
                institution: userInfo?.data?.institution,
                nodes: userInfo?.data?.nodes,
                canUpload: userInfo?.data?.canUpload,
                userProcessTypes: [
                  {
                    type: 'wgs',
                    field: 'Whole Genome Sequencing (WGS)',
                    isFastq: [
                      {
                        name: 'fastqr1',
                        type: 'file',
                        label: 'Upload FASTQ R1 File',
                      },
                      {
                        name: 'fastqr2',
                        type: 'file',
                        label: 'Upload FASTQ R2 File',
                      },
                    ],
                  },
                ],
              }
              setCurrentUser(userData) 
              localStorage.setItem('user', JSON.stringify(userData))
              window.location.href = '/'
            } else {
              setStatus('Invalid e-mail address or password')
              setSubmitting(false)
              setLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            setStatus('Invalid e-mail address or password')
            setSubmitting(false)
            setLoading(false)
          })
      } catch (error) {
        console.error(error)
        setStatus('Invalid e-mail address or password')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <a  href='/auth/registration' className='link-primary'>
          Sign up
        </a>
      </div>
    </form>
  )
}
