import {useEffect, useState} from 'react'
import DashboardService from '../../service/dashboard'
import {Loader} from '../Loader'

/* eslint-disable jsx-a11y/anchor-is-valid */
const Blog = ({className}) => {
  const [blogs, setBlogs] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getData = async () => {
    try {
      const getBlogs = await DashboardService.getAllBlogs()
      if (getBlogs.status === 200) {
        setBlogs(getBlogs.data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title fw-bold text-dark'>Newsletter</h3>
      </div>
      <div className='card-body pt-5 notifications-list'>
        {isLoading && <Loader />}
        {!isLoading &&
          blogs.map((item, index) => {
            var blogDate = new Date(item.date)
            var blogDateFormatted = blogDate.toLocaleString('en-EN', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })
            return (
              <div key={index} className='d-flex align-items-center border blog-item  p-5 '>
                <div className='flex-grow-1 me-2'>
                  <a
                    href={item.link}
                    target='_blank'
                    rel='noreferrer'
                    className='fw-bold text-gray-800 text-hover-primary fs-8'
                  >
                    {item.title.rendered}
                  </a>
                  <span className='text-muted fw-semibold d-block my-2 fs-8'>
                    {blogDateFormatted}
                  </span>
                  {/* <p className='fs-8'>
                    {item.excerpt.rendered
                      .replace(/&#8217;/g, '')
                      .replace(/&#8230;/g, '')
                      .replace(/(<([^>]+)>)/gi, '')} ...
                  </p> */}
                  <p className='fs-8' dangerouslySetInnerHTML={{__html: item.excerpt.rendered}} />

                  <a
                    href={item.link}
                    target='_blank'
                    rel='noreferrer'
                    className='fw-bold text-hover-primary fs-8'
                  >
                    Read More
                  </a>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export {Blog}
