import axios from "axios"

// import axios from './index'
const getAllBlogs = async () => {
  const res = await axios.get(`https://gennext.bio//wp-json/wp/v2/posts?categories=117`)
  return res
}

const DashboardService = {
  getAllBlogs,
}

export default DashboardService
