import React, {useMemo} from 'react'
import {KTIcon} from '../../../helpers'
import {kFormatter} from '../../../../utils'

const MixedWidget1 = ({runData, className}) => {
  let coverageItems = useMemo(
    () =>
      Object.keys(runData?.samples[0]?.sampleQuality[0])
        .filter(
          (key) =>
            !key.includes('meanCoverage') &&
            !key.includes('gender') &&
            !key.includes('contamination') &&
            !key.includes('medianCoverage') &&
            !key.includes('avgReadLength') &&
            !key.includes('roh') &&
            !key.includes('totalReads') &&
            !key.includes('dataSourceType')
        )
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: runData?.samples[0]?.sampleQuality[key],
          })
        }, {}),
    [runData]
  )
  const renderContent = useMemo(() => {
    // if (runData?.status === 'failed') {
    //   return (
    //     <div
    //       style={{marginTop: '-100px'}}
    //       className='shadow-xs card-rounded mx-9 row mb-9 px-6 py-9 position-relative z-index-1 bg-body'
    //     >
    //       Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut distinctio, deserunt nam neque
    //       dolore eius optio quos fuga nihil porro ducimus non veritatis et nobis in! Itaque
    //       veritatis ratione deleniti?
    //     </div>
    //   )
    // }

    if (!runData.samples) {
      return <div>Loading...</div>
    }
    var hasUp = false
    if (runData.processType === 'som-test-tumor-rna') {
      runData.processType = 'Somatic Testing (DNA+RNA)'
    } else if (runData.processType === 'som-test-tumor') {
      runData.processType = 'Somatic Testing (DNA)'
    } else if (runData.processType === 'som-test-rna') {
      runData.processType = 'Somatic Testing (RNA)'
    } else {
      hasUp = true
    }
    return (
      <div
        className='shadow-xs card-rounded mx-9 row mb-9 px-6 py-9 position-relative z-index-1 bg-body'
        style={{marginTop: '-100px'}}
      >
        <div className='col-md-6 d-flex align-items-center mb-6'>
          <div className='symbol symbol-45px w-40px me-5'>
            <span className='symbol-label bg-lighten'>
              <KTIcon iconName='document' className='fs-1' />
            </span>
          </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='text-gray-400 fw-semibold fs-7'>Sample ID</span>
              <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {runData.samples[0].sample_name ?? runData?.samples[0].sampleId}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
            </div>
          </div>
        </div>
        <div className='col-md-6 d-flex align-items-center mb-6'>
          <div className='symbol symbol-45px w-40px me-5'>
            <span className='symbol-label bg-lighten'>
              <KTIcon iconName='document' className='fs-1' />
            </span>
          </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='text-gray-400 fw-semibold fs-7'>Solve Status</span>
              <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {runData.isSolved ? (
                  <span className='fs-5 text-success'>Solved</span>
                ) : (
                  <span className='fs-5 text-warning'>Not Solved</span>
                )}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
            </div>
          </div>
        </div>
        <div className='col-md-6 d-flex align-items-center mb-6'>
          <div className='symbol symbol-45px w-40px me-5'>
            <span className='symbol-label bg-lighten'>
              <KTIcon iconName='document' className='fs-1' />
            </span>
          </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='text-gray-400 fw-semibold fs-7'>Project Title</span>
              <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {runData.projectTitle ?? 'Project Title'}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
            </div>
          </div>
        </div>
        <div className='col-md-6 d-flex align-items-center mb-6'>
          <div className='symbol symbol-45px w-40px me-5'>
            <span className='symbol-label bg-lighten'>
              <KTIcon iconName='document' className='fs-1' />
            </span>
          </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='text-gray-400 fw-semibold fs-7'>Proccess Type</span>
              <div
                className={`fs-5 text-gray-800 text-hover-primary fw-bold ${
                  hasUp ? 'text-uppercase' : ''
                }`}
              >
                {runData.processType}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
            </div>
          </div>
        </div>
        <div className='col-md-6 d-flex align-items-center mb-6'>
          <div className='symbol symbol-45px w-40px me-5'>
            <span className='symbol-label bg-lighten'>
              <KTIcon iconName='document' className='fs-1' />
            </span>
          </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='text-gray-400 fw-semibold fs-7'>Genome Assembly Version</span>
              <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {runData?.genomeBuild}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
            </div>
          </div>
        </div>
        {runData?.enrichmentKit && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Enrichment Kit</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData?.enrichmentKit}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}

        <div className='col-md-6 d-flex align-items-center mb-6'>
          <div className='symbol symbol-45px w-40px me-5'>
            <span className='symbol-label bg-lighten'>
              <KTIcon iconName='document' className='fs-1' />
            </span>
          </div>
          <div className='d-flex align-items-center flex-wrap w-100'>
            <div className='mb-1 pe-3 flex-grow-1'>
              <span className='text-gray-400 fw-semibold fs-7'>Total Read Count</span>
              <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                {kFormatter(runData?.samples[0]?.sampleQuality.totalReads ?? 0)}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
            </div>
          </div>
        </div>
        {runData.samples[0]?.sampleQuality.contamination && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Contamination</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.samples[0]?.sampleQuality.contamination}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}
        {runData.samples[0]?.sampleQuality.gender && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Gender</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.samples[0]?.sampleQuality.gender}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}
        {runData.samples[0]?.sampleQuality.roh && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>RoH</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.samples[0]?.sampleQuality.roh}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}
        {!!coverageItems &&
          Object.keys(coverageItems)?.map((keyName, i = 1) => { 
            return coverageItems[keyName] === null || coverageItems[keyName] === undefined ? (
              ''
            ) : (
              <div className='col-md-6 d-flex align-items-center mb-6' key={keyName}>
                <div className='symbol symbol-45px w-40px me-5'>
                  <span className='symbol-label bg-lighten'>
                    <KTIcon iconName='document' className='fs-1' />
                  </span>
                </div>
                <div className='d-flex align-items-center flex-wrap w-100'>
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='text-gray-400 fw-semibold fs-7'>{keyName}</span>
                    <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      {coverageItems[keyName]}
                    </div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
                  </div>
                </div>
              </div>
            )
          })}
        {runData.samples[0]?.sampleQuality.meanCoverage && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Mean Coverage</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.samples[0]?.sampleQuality.meanCoverage}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}
        {runData.samples[0]?.sampleQuality.medianCoverage && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Median Coverage</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.samples[0]?.sampleQuality.medianCoverage}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}
        {runData.samples[0]?.sampleQuality.avgReadLength && (
          <div className='col-md-6 d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Average Read Length</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.samples[0]?.sampleQuality.avgReadLength}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }, [runData])

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-warning`}>
          <div className='d-flex flex-stack'>
            <h2 className='m-0 text-white justify-content-center  fw-bold fs-1'>
              Sample Name : {runData?.samples[0].sampleName ?? runData?.samples[0].sampleId}
            </h2>
          </div>
          <div className='d-flex text-center flex-column align-items-center text-white pt-8'>
            <span className='fw-semibold fs-7 jy'>Status</span>
            <span
              className={`fw-bold fs-2 my-2 px-10 py-3  badge badge-light text-warning text-capitalize`}
            >
              {' '}
              <span className='indicator-progress fs-1 ms-n1' style={{display: 'inline-block'}}>
                <span className='spinner-border spinner-border-sm align-middle fs-1 ms-n1 mx-5'></span>
              </span>{' '}
              {runData?.status}
            </span>
          </div>
        </div>
        {renderContent}
      </div>
    </div>
  )
}

export {MixedWidget1}
