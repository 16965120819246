import React, {useState, useEffect} from 'react'
import TableComponent from '../../components/TableComponent/TableComponent'
import somaticService from '../../service/somatic'
import { useThemeMode } from '../../_metronic/partials'
import {svHeader} from '../../static/somaticVariantHeaders'

const TablePage = () => {
  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedWidgetItem, setSelectedWidgetItem] = useState({})
  const [theme, setTheme] = useState(useThemeMode())

  const getData = async () => {
    const getData = await somaticService.getSomaticVariants(
      '8d6d0aef-cdd2-49c9-bdc2-6cb557065cf9',
      'c8658626-9add-4c8c-803b-279a2922e543',
      ['sv'],
      false
    )
    const result = []
    getData.data.svData.forEach((item) => {
      item.reportSelect = ''
      result.push(item)
    })
    setData(getData.data.svData)
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <TableComponent  svHeader={svHeader} data={data} theme={theme} selectedRows={selectedRows} setSelectedRows={setSelectedRows} selectedWidgetItem={selectedWidgetItem} setSelectedWidgetItem={setSelectedWidgetItem} />
  )
}

export {TablePage}
