/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {MD5} from 'crypto-js'

import NodeService from '../../service/node'

const ActiveNodes = ({className, color}) => {
  const [nodesList, setNodesList] = useState([])
  const getNodes = async () => {
    const nodeData = await NodeService.getNodeList()
    setNodesList(nodeData)
    // .then((nodes) => nodes.sort((a, b) => b.isUp - a.isUp))
    // .then((r) => Object.fromEntries(r.map((rr) => [MD5(JSON.stringify(rr)).toString(), rr])))
    // .then((data) => {
    //   console.log(data)
    //   setNodesList(data)
    // })
  }
  useEffect(() => {
    getNodes()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 py-10 card-rounded  w-100 bg-${color}`}>
          <div className='d-flex text-center flex-column text-white '>
            <span className='fw-bold fs-2x pt-1'>Active Nodes</span>
            {nodesList?.map((item, index) => {
              return (
                <span key={index} className='fw-bold fs-7 pt-1'>
                  {item.nodeName} {item.isUp ? '✅' : '❌'}
                </span>
              )
            })}
            <div className='   text-center flex-column text-white mt-10 hidden-xxl'>
              <span className='fw-bold fs-2x pt-1'>Current Plan</span>
              <span className='fw-bold fs-2 pt-1'> Standart</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ActiveNodes}
