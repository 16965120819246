export const canverEvTooltips = {
  CBP1: 'Therapeutic: FDA approved or investigational with strong evidence',
  CBP2: 'Diagnostic: In Professional guideline or reported evidence with consensus',
  CBP3: 'Prognostic: In Professional guideline or reported evidence with consensus',
  CBP4: 'Mutation type: Activating, LOF (missense, nonsense, indel, splicing), CNVs, fusions',
  CBP5: 'Variant frequencies: Mostly mosaic',
  CBP6: 'Potential germline: Mostly nonmosaic',
  CBP7: 'Population databases: Absent or extremely low MAF',
  CBP8: 'Germline databases: may be present in HGMD/ClinVar',
  CBP9: 'Somatic databases: Most present in COSMIC, My Cancer Genome, TCGA',
  CBP10:
    'Predictive from: SIFT, PolyPhen2, MutationTaster, CADD, MetaSVM, MetaLR, FATHMM, GERP++_RS, and mostly as',
  CBP11: 'Pathway: involve in Disease-associated pathways or pathogenic pathways',
  CBP12: 'Publications: Convincing evidence from Functional study, population study, other',
  CBP13: 'Additional: user specified',
}
function convertToObjects(array) {
  return array.map((value) => {
    if (value === 'PASS') {
      return {value: value, class: 'text-success'}
    } else if (value === 'base_qual') {
      return {value: value, class: 'text-danger'}
    } else {
      return {value: value, class: 'text-warning'}
    }
  })
}

export var VariantIdFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
    <span class="${extraClass}">
          <a target='blank' href='${value}' rel='noreferrer' class='text-link font-italic gene-text'  >
          ${value}</a> 
    </span>`
  return html
}
export var GeneReferenceFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
    <span class="${extraClass}">
      ${value
        ?.map((item, index) => {
          // target özelliğinin her zaman dahil olmasını sağlayın
          return `
          <a target='blank' href='${
            item === '' || item === '.'
              ? '#'
              : `https://www.genecards.org/cgi-bin/carddisp.pl?gene=${item}`
          }'  rel='noreferrer' class='text-link font-italic gene-text'  >
          ${item}</a>`
        })
        .join(',')}  
    </span>`
  return html
}

export var cosmicFormatter = (row, cell, value, columnDef, dataContext) => {
  var splitCosmic = value.split(';')[0].split('=')
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
    <span class="${extraClass}">
     <a  href='${
       value === '' || value === '.'
         ? '#'
         : `https://cancer.sanger.ac.uk/cosmic/search?genome=38&q=${splitCosmic[1]}`
     }' target='_blank' rel='noreferrer' class='text-link '  >
       ${value} 
     </a>
    </span>`
  return html
}

export var omimFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
    <span class="${extraClass}">
     <a  href='${
       value === '' || value === '.' ? '#' : `https://www.omim.org/entry/${value}`
     }'  target='_blank' rel='noreferrer' class='text-link '  >
       ${value} 
     </a>
    </span>`
  return html
}
export const filterObjects = [
  {
    type: 'PASS',
    desc: 'multiple events are present on the same haplotype as the variant which is indicative of a false-positive call.',
  },
  {
    type: 'clustered_events',
    desc: 'multiple events are present on the same haplotype as the variant which is indicative of a false-positive call.',
  },
  {
    type: 'map_qual',
    desc: 'the median mapping quality of reads supporting the alternate allele is too low.',
  },
  {
    type: 'duplicate',
    desc: 'the alternate allele is overrepresented by apparent sequencing duplicates.',
  },
  {
    type: 'base_qual',
    desc: 'the median base quality of bases supporting the alternate allele is too low.',
  },
  {
    type: 'fragment',
    desc: 'a large difference is observed in the median fragment length for reads supporting the reference and alternate alleles.',
  },
  {type: 'contamination', desc: 'the alternate allele is present due to contamination.'},

  {type: 'multiallelic', desc: 'the mutation occurs at a multiallelic site.'},
  {type: 'weak_evidence', desc: 'the mutation does not have significant support above noise.'},
  {type: 'n_ratio', desc: "too many 'N' bases at the site."},
  {type: 'low_allele_frac', desc: 'the variant allele fraction is below the threshold.'},
  {
    type: 'orientation',
    desc: 'the variant is likely an artifact due to orientation bias.',
  },
  {type: 'normal_artifact', desc: 'the variant is likely an artifact in the normal sample.'},
  {type: 'position', desc: 'the allele is close to the ends of the reads.'},
  {type: 'panel_of_normals', desc: 'the site is present in the panel of normals.'},
  {
    type: 'slippage',
    desc: 'the variant is likely an artifact due to polymerase slippage.',
  },
  {
    type: 'strand_bias',
    desc: 'evidence for the alternate allele comes from only one read direction.',
  },
  {
    type: 'haplotype',
    desc: 'variant is on the same haplotype as other filtered variants.',
  },
  {type: 'germline', desc: 'there is evidence that the variant is germline.'},
  {
    type: 'strict_strand',

    desc: 'evidence for the alternate allele is not significant on both directions.',
  },
]
export var phenoTypeMIM = (row, cell, value, columnDef, dataContext) => {
  var splitMim = value.split(';')

  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
    <span class="${extraClass}">
    ${splitMim
      ?.map((item, index) => {
        return `
          <a href='${
            item === '' || item === '.' ? '#' : `https://www.omim.org/entry/${item}`
          }' target='_blank' rel='noreferrer' class='text-link font-italic'>
          ${item} 
          </a>
          `
      })
      .join('')}
    </span>`
  return html
}
export var orphaNumberFormatter = (row, cell, value, columnDef, dataContext) => {
  var splitOrpha = value.split(';')

  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
    <span class="${extraClass}">
    ${splitOrpha
      ?.map((item, index) => {
        return `
          <a href='${
            item === '' || item === '.' ? '#' : `https://www.orpha.net/en/disease/detail/${item}`
          }' target='_blank' rel='noreferrer' class='text-link font-italic'>
          ${item} 
          </a>
          `
      })
      .join('')}
    </span>`
  return html
}

export var avsnpFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
       <a  href='${
         value === '' || value === '.' ? '#' : `https://www.ncbi.nlm.nih.gov/snp/${value}`
       }'  target='_blank' rel='noreferrer' class='text-link '  >
         ${value} 
       </a>
      </span>`
  return html
}
export var detFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
       <span class='${value === 'Yes' ? 'text-success' : 'text-danger'}'  >
         ${value} 
       </span>
      </span>`
  return html
}
export var detsFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
       <span class='${value > 2.5 ? 'text-success' : 'text-danger'}'  >
         ${value === null ? '.' : value} 
       </span>
      </span>`
  return html
}
export var oncogenicFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
       <a   ${
         value === '' || value === '.'
           ? '#'
           : `https://www.oncokb.org/genomic-change/${dataContext.Chrom},${dataContext.Start},${dataContext.End},${dataContext.Ref},${dataContext.Alt}?refGenome=GRCh38`
       }'     target='_blank' rel='noreferrer' class='${
    value !== 'Unknown' ? 'font-weight-bold text-link' : ''
  }'  >
         ${value} 
       </a>
      </span>`
  return html
}
export var icgcIdFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
       <a   href='${
         value === '' || value === '.' ? '#' : `https://dcc.icgc.org/mutations/${value}`
       }'   target='_blank' rel='noreferrer' class='text-link'  >
         ${value} 
       </a>
      </span>`
  return html
}

export var filterFormatter = (row, cell, value, columnDef, dataContext) => {
  var convert = convertToObjects(value)
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
      ${convert
        ?.map((item, index) => {
          return `
            <span  class='${item.class}'>
            ${item.value}
            </span>
            `
        })
        .join('')}
      </span>`
  return html
}
export var cancerVarFormatter = (row, cell, value, columnDef, dataContext) => {
  let metin = value
  let arrayFromText
  if (metin?.includes(',')) {
    arrayFromText = metin.split(',').map((item) => item.trim())
  } else {
    arrayFromText = [metin?.trim()]
  }
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  const html = `
      <span class="${extraClass}">
      ${arrayFromText
        ?.map((item, index) => {
          return `
            <span data-tooltip-id='my-tooltip'  data-tooltip-content='${
              canverEvTooltips[item?.trim()]
            }'  class='mx-1'>
            ${item}
            </span>
            `
        })
        .join('')}
      </span>`

  return html
}

export var levelFormatter = (row, cell, value, columnDef, dataContext) => {
  const extraClass = dataContext.parentId !== null ? 'tree-data' : ''
  var color
  switch (columnDef.field) {
    case 'Level1':
      color = '#35a12c'
      break
    case 'Level2':
      color = '#1f78b6'
      break
    case 'Level3A':
      color = '#984ea3'
      break
    case 'Level3B':
      color = '#be98d0'
      break
    case 'Level4':
      color = '#424242'
      break
    case 'LevelR1':
      color = '#f03523'
      break
    case 'LevelR2':
      color = '#f99a92'
      break
    default:
      break
  }
  const html = `
      <span class="${extraClass}" style="color:${color}">
         ${value === null ? '.' : value}  
      </span>`
  return html
}
