import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import RunsService from '../../service/runs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {Loader} from '../../components/Loader'
import {AdvancedFilterTable} from '../../components/AdvancedFilterTable'
import {KTIcon} from '../../_metronic/helpers'
import {useAdvencedFilters} from '../../hooks'

const MorpheusPage = () => {
  const [runs, setRuns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {
    clearAdvancedFilter,
    advancedFilterSubmitHandler,
    changeCheck,
    setFilters,
    filters,
    processTypes,
    xFilters,
    getData,
    runsDatas,
  } = useAdvencedFilters()
  const getRuns = async () => {
    // const req = await RunsService.getAllRuns()
    var arr = []
    runsDatas.map((item) => {
      arr.push({
        value: item.id,
        label: item.samples[0].sampleId,
      })
      return ''
    })
    setIsLoading(false)
    setRuns(arr)
  }
  useEffect(() => {
    getRuns()
  }, [runsDatas])
  console.log(filters)
  const animatedComponents = makeAnimated()
  if (isLoading) {
    return <Loader />
  }
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <PageTitle> Morpheus Page </PageTitle>
        <div>
          <button
            type='button'
            className='btn btn-primary '
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
            Advanced Filter
          </button>
          <AdvancedFilterTable
            processType={processTypes}
            xFilters={xFilters}
            changeCheck={changeCheck}
            advancedFilterSubmitHandler={advancedFilterSubmitHandler}
            setFilters={setFilters}
            filters={filters}
            clearAdvancedFilter={clearAdvancedFilter}
          />
          <h5 className='mt-3'> Select a case</h5>
          <Select
            closeMenuOnSelect={false}
            label='Select Samples'
            components={animatedComponents}
            isMulti
            options={runs}
          />
        </div>

        <div className=' mt-2 mb-2'></div>
      </div>
    </div>
  )
}

export default MorpheusPage
