/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'

const BestPracticesPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'BESTPRACTICES'})}</PageTitle>
      <div className='row'>
        <div className='card card-custom'>
          <div className='card-body'>
            <h1>Gennext Variant Filtering Best Practices</h1>
            <h2>Germline</h2>
            <p>This guide provides best practices for variant filtering using the Gennext platform for targeted panel sequencing (TPS), whole exome sequencing (WES), and whole genome sequencing (WGS). The aim is to help users obtain a prioritized list of variants by utilizing the extensive filtering options available in Gennext.</p>
            
    <div className="toc">
        <h3>Table of Contents</h3>
        <ul>
            <li><a href="#introduction">Introduction</a></li>
            <li><a href="#quality-control-metrics">Quality Control Metrics</a></li>
            <li><a href="#filtering-strategies">Filtering Strategies</a></li>
            <li><a href="#family-based-analysis">Family-Based Analysis</a></li>
            <li><a href="#analysis-inheritance-mode">Analysis by Inheritance Mode</a></li>
            <li><a href="#variant-category-filters">Variant Category Filters</a></li>
            <li><a href="#filtering-by-annotations">Filtering by Annotations</a></li>
            <li><a href="#filtering-on-custom-annotations">Filtering on Custom Annotations</a></li>
            <li><a href="#summary">Summary</a></li>
        </ul>
    </div>

    <div id="introduction" className="section">
        <h3>1. Introduction</h3>
        <p>This guide outlines best practices for variant filtering to help researchers focus on the most relevant genetic variations. Gennext offers a variety of filtering options that can be customized to meet specific research needs, streamlining the process from raw sequencing data to meaningful, interpretable results.</p>
    </div>

    <div id="quality-control-metrics" className="section">
        <h3>2. Quality Control Metrics</h3>
        <p>Gennext provides a comprehensive set of quality control (QC) metrics to ensure the accuracy and reliability of the data. In Gennext, default QC thresholds are set, but they can be customized by the user when needed. Key QC metrics and thresholds include:</p>
        <ul>
            <li>Average autosomal coverage over genome: &ge; 30x</li>
            <li>Mapped reads: &ge; 90%</li>
            <li>Q30 bases: &ge; 80%</li>
            <li>Mismatched bases: &le; 1.25% (R1) and &le; 1.5% (R2)</li>
            <li>Insert length median: &ge; 100 bp</li>
            <li>Paired reads mapped to different chromosomes (MAPQ &ge; 10): &le; 10%</li>
            <li>Autosome callability: &ge; 90%</li>
            <li>AT/GC dropout: &le; 10%</li>
            <li>Sex ploidy validation: TRUE</li>
            <li>Estimated sample contamination: &le; 0.01%</li>
        </ul>
    </div>

    <div id="filtering-strategies" className="section">
        <h3>3. Filtering Strategies</h3>
        
        <h4>Targeted Panel Sequencing</h4>
        <ul>
            <li><strong>Coverage and Quality:</strong> Ensure sufficient coverage depth (&ge; 100x) for high-confidence variant calls. Filter out variants with low-quality scores (e.g., Q {'<'} 30).</li>
            <li><strong>Population Allele Frequency:</strong> Exclude common variants with high minor allele frequency (MAF {'>'} 1%) in population databases like gnomAD.</li>
            <li><strong>Annotation:</strong> Focus on variants predicted to affect protein function (e.g., missense, nonsense, indels).</li>
        </ul>
        
        <h4>Whole Exome Sequencing</h4>
        <ul>
            <li><strong>Coverage and Quality:</strong> Maintain a minimum coverage of 20x across the exons. Apply stringent quality thresholds (e.g., Q &ge; 30).</li>
            <li><strong>Population Allele Frequency:</strong> Filter out variants with MAF {'>'} 1% in population databases.</li>
            <li><strong>Functional Impact:</strong> Prioritize variants predicted to have high functional impacts (e.g., loss of function or damaging missense).</li>
        </ul>
        
        <h4>Whole Genome Sequencing</h4>
        <ul>
            <li><strong>Comprehensive Coverage and Quality:</strong> Ensure genome-wide coverage of &ge; 30x.</li>
            <li><strong>Population Allele Frequency:</strong> Exclude common variants (MAF {'>'} 1%) using large-scale population databases.</li>
            <li><strong>Broad Annotation:</strong> Include various annotations (e.g., coding and non-coding regions, regulatory elements).</li>
        </ul>
    </div>

    <div id="family-based-analysis" className="section">
        <h3>4. Family-Based Analysis</h3>
        <p>Family-based analysis increases genotype sensitivity and specificity by incorporating variant information from related individuals. Gennext allows users to perform joint genotyping and filter variants based on inheritance patterns.</p>
    </div>

    <div id="analysis-inheritance-mode" className="section">
        <h3>5. Analysis by Inheritance Mode</h3>
        <h4>Dominant</h4>
        <p>Identify variants in both the affected proband and at least one affected parent.</p>
        
        <h4>De Novo Heterozygous</h4>
        <ul>
            <li>Identify variants present in the proband but absent in both parents.</li>
            <li>Use additional filters for affected and unaffected siblings, if available.</li>
        </ul>
        
        <h4>Recessive</h4>
        <ul>
            <li>Capture bi-allelic (homozygous, compound heterozygous, and hemizygous) variants.</li>
            <li>Exclude variants present in unaffected individuals to assume complete penetrance.</li>
            <li>Exclude homozygous variants and those present in unaffected individuals.</li>
        </ul>
    </div>

    <div id="variant-category-filters" className="section">
        <h3>6. Variant Category Filters</h3>
        <p>Gennext supports filtering across multiple variant categories:</p>
        <ul>
            <li>Small variants: Single nucleotide variants (SNVs), Multi-nucleotide variants (MNVs), and Insertion-Deletions (indels) &le; 50 bp.</li>
            <li>Structural variants (SVs): Indels &ge; 50 bp, duplications {'<'} 10 kb, and translocations.</li>
            <li>Copy number variants (CNVs): Regions with abnormal copy numbers.</li>
            <li>Runs of homozygosity (ROH): Long stretches of homozygosity indicating consanguinity.</li>
            <li>Short tandem repeats (STRs): Repeated regions associated with disorders.</li>
            <li>Mitochondrial variants: Variants in mitochondrial DNA.</li>
            <li>Paralogs: Duplicated gene copies with potential functional divergence.</li>
        </ul>
    </div>

    <div id="filtering-by-annotations" className="section">
        <h3>7. Filtering by Annotations</h3>
        <h4>Default Annotations (Clinical and Research)</h4>
        <ul>
            <li><strong>OMIM:</strong> Filter genes based on known phenotype-genotype associations.</li>
            <li><strong>gnomAD:</strong> Exclude variants in low-complexity regions or those with high allele frequency.</li>
            <li><strong>UK Biobank:</strong> Exclude variants in low-complexity regions or those with high allele frequency.</li>
            <li><strong>Turkish Variome:</strong> Exclude population-specific variants in low-complexity regions or those with high allele frequency.</li>
            <li><strong>ClinVar:</strong> Filter based on clinical significance and review status.</li>
            <li><strong>Splice AI:</strong> Filter based on splice-altering probabilities.</li>
            <li><strong>CADD score:</strong> Quantifies the deleteriousness of genetic variants by integrating multiple annotations into a single metric, with higher scores indicating a greater predicted impact on biological function and disease potential.</li>
            <li><strong>Intervar:</strong> Classifies the clinical significance of genetic variants by integrating multiple annotation sources and guidelines, providing a standardized interpretation of their potential pathogenicity.</li>
        </ul>
    </div>

    <div id="filtering-on-custom-annotations" className="section">
        <h3>8. Filtering on Custom Annotations</h3>
        <p>Custom annotations provide flexibility for users to filter based on specific data sets or research needs. These annotations can be uploaded and used within the Gennext platform to enhance filtering. Gennext provides more than 150 annotation options that fit a variety of phenotypes (Annotator list).</p>
    </div>

    <div id="summary" className="section">
        <h3>9. Summary</h3>
        <p>Gennext offers a powerful and flexible interface for filtering variants from NGS data. By utilizing the best practices outlined in this guide, researchers can efficiently narrow down millions of variants to a manageable list of candidates for further analysis.</p>
        <p>Please refer to the Gennext User Guide (link) for more detailed information.</p>
    </div>

    <h2>Somatic</h2>
    <p>Optimizing somatic variant filtration in NGS panels ensures high sensitivity and specificity. The following practices are designed to filter tumor-only variants effectively, eliminating germline variants and artifacts to provide accurate somatic variant calls.</p>

    <div className="toc">
        <h3>Table of Contents</h3>
        <ul>
            <li><a href="#sample-preparation-and-sequencing">Sample Preparation and Sequencing</a></li>
            <li><a href="#quality-control-and-initial-filtration">Quality Control and Initial Filtration</a></li>
            <li><a href="#variant-filtration-algorithm">Variant Filtration Algorithm</a></li>
            <li><a href="#database-utilization">Database Utilization</a></li>
            <li><a href="#filtration-order-and-optimization">Filtration Order and Optimization</a></li>
            <li><a href="#allele-specific-lod">Allele-Specific Limit of Detection (LoD)</a></li>
            <li><a href="#filtering-strategies">Filtering Strategies</a></li>
            <li><a href="#structural-variant-filtering">Structural Variant Filtering</a></li>
            <li><a href="#fusion-transcript-filtering">Fusion Transcript Filtering from RNA Data</a></li>
            <li><a href="#additional-best-practices">Additional Best Practices</a></li>
        </ul>
    </div>

    <div id="sample-preparation-and-sequencing" className="section">
        <h3>1. Sample Preparation and Sequencing</h3>
        <p><strong>Sample Types:</strong> Use DNA from formalin-fixed paraffin-embedded (FFPE) tumor specimens or peripheral blood lymphocytes (PBLs).</p>
        <p><strong>Tumor Cellularity:</strong> Ensure tumor regions have at least 20% cellularity.</p>
        <p><strong>Library Preparation:</strong> Use 250 ng of DNA for library preparation. For targeted panels, such as the 48-gene TruSeq Amplicon Cancer Panel (TSACP) or the 555-gene custom UHN Hi5 panel, follow standardized protocols for library construction and sequencing.</p>
    </div>

    <div id="quality-control-and-initial-filtration" className="section">
        <h3>2. Quality Control and Initial Filtration</h3>
        <p><strong>Read Depth:</strong> Require a minimum read depth of 250x for somatic variants and 50x for germline variants.</p>
        <p><strong>Variant Allele Fraction (VAF):</strong> Include variants with a VAF {'>'} 5%, retaining those with lower VAF if clinically relevant.</p>
        <p><strong>Quality Filters:</strong> Retain only high-quality variants without strand bias.</p>
    </div>

    <div id="variant-filtration-algorithm" className="section">
        <h3>3. Variant Filtration Algorithm</h3>
        <h4>Phase I: Quality and Relevance Filtering</h4>
        <ul>
            <li>Retain nonsynonymous variants in exons or canonical splice sites.</li>
            <li>Use a Clinically Relevant Managed Variant List (CR-MVL) to keep actionable variants below 5% VAF.</li>
        </ul>
        <h4>Phase II: Germline Variant Exclusion</h4>
        <ul>
            <li>Exclude variants present in any of the following population variant databases (PVDs): 1000 Genomes Phase 3, ESP6500, ExAC, with a minor allele frequency (MAF) threshold of 0.2%.</li>
        </ul>
    </div>

    <div id="database-utilization" className="section">
        <h3>4. Database Utilization</h3>
        <p><strong>ClinVar:</strong> Use as an exclusionary filter for benign or likely benign germline variants.</p>
        <p><strong>COSMIC and HGMD:</strong> Avoid using them as primary filters due to potential contamination with germline variants.</p>
        <p><strong>Internal Databases:</strong> Utilize a laboratory-specific artifact MVL to exclude technical artifacts and an internal clinically relevant variant list to retain important somatic variants.</p>
    </div>

    <div id="filtration-order-and-optimization" className="section">
        <h3>5. Filtration Order and Optimization</h3>
        <p><strong>Optimal Order:</strong> Apply filters in a sequence to maximize sensitivity and specificity. The most effective sequence includes using the internal CR-MVL as an inclusionary filter, followed by exclusionary filters from ClinVar and the PVDs.</p>
        <p><strong>Artifact Removal:</strong> Add steps to filter out known technical artifacts for larger panels.</p>
    </div>

    <div id="allele-specific-lod" className="section">
        <h3>6. Allele-Specific Limit of Detection (LoD)</h3>
        <p><strong>LoD Cut-off:</strong> Gennext uses a LoD cut-off of 2.5. Any variant detected below this threshold should be considered noise or a potential artifact and excluded from further analysis.</p>
        <p><strong>Validation:</strong> Ensure that the detection threshold is validated using control samples to confirm the pipeline's sensitivity and specificity at this cut-off.</p>
    </div>

    <div id="filtering-strategies" className="section">
        <h3>7. Filtering Strategies</h3>
        <ul>
            <li><strong>Initial Filtration:</strong> To exclude low-confidence calls, use a combination of variant quality metrics (e.g., read depth, and base quality).</li>
            <li><strong>Population Variant Databases (PVDs):</strong> Exclude variants with a minor allele frequency (MAF) above 0.2% in ExAC, 1000 Genomes, and ESP6500 databases. This step helps remove common germline variants.</li>
            <li><strong>ClinVar:</strong> Exclude variants classified as benign or likely benign in ClinVar.</li>
            <li><strong>Panel-Specific Artifacts:</strong> Identify and exclude recurrent artifacts specific to the sequencing panel used in Gennext.</li>
        </ul>
    </div>

    <div id="structural-variant-filtering" className="section">
        <h3>8. Structural Variant Filtering</h3>
        <ul>
            <li><strong>Read Depth and Split Reads:</strong> Significant read depth and split reads indicating breakpoints should support structural variants (SVs).</li>
            <li><strong>Paired-End Reads:</strong> Ensure proper mapping of paired-end reads that support structural rearrangements.</li>
            <li><strong>Size Thresholds:</strong> Apply size thresholds to distinguish between small indels and larger structural variants, typically filtering out SVs smaller than 50 base pairs unless otherwise validated.</li>
        </ul>
    </div>

    <div id="fusion-transcript-filtering" className="section">
        <h3>9. Fusion Transcript Filtering from RNA Data</h3>
        <ul>
            <li><strong>Read Support:</strong> Filter fusion transcripts based on the number of supporting reads. A minimum of 10 unique supporting reads is often used as a threshold.</li>
            <li><strong>Gene Annotation:</strong> Cross-reference detected fusions with gene annotations to confirm that the breakpoints occur in exonic regions and are likely to produce functional fusion proteins.</li>
            <li><strong>Database Verification:</strong> Verify fusions against known fusion databases like COSMIC to exclude artifacts and recurrent non-pathogenic fusions.</li>
        </ul>
    </div>

    <div id="additional-best-practices" className="section">
        <h3>10. Additional Best Practices</h3>
        <ul>
            <li><strong>Use of Laboratory-Specific MVLs:</strong> Employ Managed Variant Lists (MVLs) specific to your laboratory that include known actionable somatic variants and exclude known artifacts.</li>
            <li><strong>Order of Filtering Steps:</strong> Prioritize filtering steps to minimize false positive and false negative rates. Begin with stringent quality filters followed by population and clinical database filters.</li>
            <li><strong>Manual Review:</strong> Include a step for manually reviewing variants that pass all filters but may still represent edge cases or known artifacts.</li>
        </ul>
    </div>
        </div>
          </div>
        </div> 
    </>
  )
}

export {BestPracticesPage}
