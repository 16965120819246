/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {EngageWidget10} from '../../../_metronic/partials/widgets'
import {Notifications} from '../../../components/Dashboard.jsx/Notifications.jsx'
import {DashboardChart} from '../../../components/Dashboard.jsx/DashboardChart.jsx'
import {ActiveNodes} from '../../../components/Dashboard.jsx/ActiveNodes.jsx'
import {CurrentPlan} from '../../../components/Dashboard.jsx/CurrentPlan.jsx'
import {Blog} from '../../../components/Dashboard.jsx/Blog'

const DashboardPage  = () => (
  <>
    {/* <div className='d-flex justify-content-end'>
      <a href='#' className='btn btn-primary mx-1 mb-2  btn-sm'>
        Best Practice Filtering
      </a>
      <a href='#' className='btn btn-primary mx-1 mb-2 btn-sm'>
        Version History
      </a>
    </div> */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
        <DashboardChart className='card-xl-stretch mb-xl-8 h-auto' />
        <EngageWidget10 className='card-xl-stretch mb-xl-8 h-auto' />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <ActiveNodes className='card-xl-stretch mb-xl-8 h-auto' color='brand' />
        <Blog className='card-xl-stretch mb-xl-8 h-auto' />
      </div> 
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 notifications-wrap'>
        <CurrentPlan className='card-xl-stretch mb-xl-8 h-auto' color='brand' />
        <Notifications className='card-xl-stretch mb-xl-8 h-auto ' />
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
