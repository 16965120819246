import axios from './index'

const getNodeList = async () => {
  const res = await axios.get(
    'https://api.' + process.env.REACT_APP_NODE_URL + '/api/v0/user/nodes'
  )
  return res.data
}
const getIceServers = async () => {
  const res = await axios.get(
    'https://api.' + process.env.REACT_APP_NODE_URL + '/api/v0/auth/iceservers'
  )

  return res.data
}

const NodeService = {getNodeList, getIceServers}

export default NodeService
