import React, {useMemo} from 'react'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
// import {Loader} from './Loader'
// import {StatisticsWidget6} from '../_metronic/partials/widgets'
import Swal from 'sweetalert2'
import {Loader} from '../Loader'
import {toAbsoluteUrl} from '../../_metronic/helpers' 
const UserRunListTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const tableIsLoading = false
  const userRunList = [
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      name: 'TST-27',
      status: 'Completed',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      name: 'TST-27',
      status: 'Failed',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      name: 'TST-27',
      status: 'Running',
    },
  ]

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
        name: 'Status',
        selector: (row) => row.status,
        sortable: true,
        filterable: true,
        cell: (row) => {
          const statusClass =
            row.status === 'Completed'
              ? 'badge   badge-light-success'
              : row.status === 'Error' || row.status === 'Canceled'
              ? 'badge   badge-light-danger'
              : 'badge   badge-light-warning'
  
          return <span className={`${statusClass}`}>{row.status.toString().toUpperCase()}</span>
        },
      },
    {
      name: 'Actions',
      selector: (row) => row.mail,
      sortable: true,
      filterable: true,
      cell: (row) => {
        return (
          <div>
            {' '}
           
            <span
              onClick={() => {
                Swal.fire({
                  html: 'Are you sure to delete <strong>' + row?.name + '</strong> ?',
                  icon: 'warning',
                  confirmButtonText: 'Yes',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    Swal.fire(row.name + ' Removed successfully!', '', 'success')
                  }
                })
              }}
              className='btn  btn-secondary '
            >
              <i className='fa fa-close mx-2 '></i>
            </span>
          </div>
        )
      },
    },
  ]
  return (
    <div className='card'>
      <div className='card-body p-4'>
        {tableIsLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <DataTable
              className='table-striped custom-filtered-table'
              pagination
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              onRowClicked={(row) => {}}
              columns={columns}
              data={userRunList}
            />
          </>
        )}
      </div>
  
    </div>
  )
}

export default UserRunListTable
