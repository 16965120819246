import {Link} from 'react-router-dom'
import {PageTitle} from '../../_metronic/layout/core'

const ChangeSomaticReportPage = () => {
  return (
    <div className='card '>
      <div className='card-body'>
        <PageTitle breadcrumbs={[]}>Reports</PageTitle>
        <div className='mt-4'>
          Supported file types: DOCX, XLSX, PPTX, ODT, ODS, ODP, ODG, XHTML, IDML, HTML or an XML
          file
        </div>
        <input
          accept='DOCX, XLSX, PPTX, ODT, ODS, ODP, ODG, XHTML, IDML, HTML,XML'
          type='file'
          className='form-control mt-4'
        />
        <button className='btn btn-primary mt-2'>Upload Template</button>
      </div>
    </div>
  )
}

export {ChangeSomaticReportPage}
