import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {KTIcon, KTSVG} from '../../_metronic/helpers'
const UserRunListModal = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const userRunList = [
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      name: 'TST-27',
      status: 'Completed',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      name: 'TST-27',
      status: 'Failed',
    },
    {
      id: '6f988de5-275a-49b5-936f-6253455420fb',
      name: 'TST-27',
      status: 'Running',
    },
  ]

  return (
    <div className='modal fade' tabIndex={-1} id='userRunListModal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Runs</h5>
          </div>
          <div className='modal-body'>
            <div className=' pt-2'>
              {userRunList?.map((run, key) => (
                <div className='modal-map-item' key={key}>
                  <div className='d-flex align-items-center mb-8'>
                    <span className={`bullet bullet-vertical h-40px bg-${run.status==='Completed' ? 'success' : run.status==='Failed' ? 'danger' : 'warning'}`}></span>
                    <div className='flex-grow-1 mx-3'>
                      <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                        {run.name}
                      </a>
                      <span className='text-muted fw-semibold d-block'>26/09/2023</span>
                    </div>
                    <span className={`badge badge-light-${run.status==='Completed' ? 'success' : run.status==='Failed' ? 'danger' : 'warning'} fs-8 fw-bold`}>{run.status}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserRunListModal
