import axios from 'axios'
import {KTSVG} from '../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {marked} from 'marked'
import {Loader} from '../Loader'

const CustomAnnotationModal = ({currentNode}) => {
  const [tags, setTags] = useState([])
  const [selectedSubTag, setSelectedSubTag] = useState(null)
  const [selectedSubTags, setSelectedSubTags] = useState([])
  const [openTags, setOpenTags] = useState({})
  const [filterText, setFilterText] = useState('')
  const [subDataIsLoading, setSubDataIsLoading] = useState(false)
  const getData = async () => {
    try {
      const [remoteRequest, annotatorsRequest] = await Promise.all([
        axios.get(`https://${currentNode}.node.gennext.bio/store/remote`),
        axios.get('https://gennext-edge.farplane.dev/submit/annotators'),
      ])

      const annotators = annotatorsRequest.data
      const result = {tags: []}
      const tagMap = {}

      Object.keys(remoteRequest.data.data).forEach((key) => {
        const item = remoteRequest.data.data[key]
        if (item.type === 'annotator') {
          if (!item.tags) return
          item.tags.forEach((tag) => {
            if (!tagMap[tag]) {
              tagMap[tag] = {
                label: tag,
                subTags: [],
              }
              result.tags.push(tagMap[tag])
            }
            if (annotators[key]) {
              tagMap[tag].subTags.push({
                label: key,
                description: item.description,
                latest_version: item.latest_version,
                developer: item.developer,
              })
            }
          })
        }
      })
      setTags(result.tags)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilterChange = (event) => {
    setFilterText(event.target.value)
  }
  const filterSubTags = () => {
    if (filterText.length < 3) return []

    const uniqueSubTags = new Set()
    const filtered = []
    tags.forEach((tag) => {
      tag.subTags.forEach((subTag) => {
        if (
          subTag.label.toLowerCase().includes(filterText.toLowerCase()) &&
          !uniqueSubTags.has(subTag.label)
        ) {
          uniqueSubTags.add(subTag.label)
          filtered.push(subTag)
        }
      })
    })
    return filtered
  }

  const getLogo = async (subTag) => {
    try {
      const tagLogo = await axios.get(
        `https://${currentNode}.node.gennext.bio/store/locallogo?module=${subTag}`,
        {responseType: 'blob'}
      )
      if (tagLogo.status === 200) {
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const addPrefixToImageUrls = (markdownText, prefix) => {
    return markdownText.replace(/!\[([^\]]*)\]\(([^)]+)\)/g, (match, alt, url) => {
      if (!url.startsWith('http')) {
        url = prefix + url
      }
      return `![${alt}](${url})`
    })
  }

  const MarkdownRenderer = ({markdownText, prefix}) => {
    const updatedMarkdown = addPrefixToImageUrls(markdownText, prefix)
    const createMarkup = () => {
      return {__html: marked(updatedMarkdown)}
    }

    useEffect(() => {
      const images = document.querySelectorAll('.markdown-content img')
      images.forEach((img) => {
        img.style.maxWidth = '100%'
      })
    }, [markdownText])

    return <div className='markdown-content' dangerouslySetInnerHTML={createMarkup()} />
  }

  const handleTagClick = (tag) => {
    setOpenTags((prevOpenTags) => ({
      ...prevOpenTags,
      [tag.label]: !prevOpenTags[tag.label],
    }))
  }

  const handleSubTagClick = async (subTag) => {
    setSubDataIsLoading(true)
    const tagDesc = await axios.get(
      `https://${currentNode}.node.gennext.bio/store/modules/${subTag.label}/latest/readme`
    )
    var hasLogo = await getLogo(subTag.label)
    setSelectedSubTag({
      title: subTag.label,
      desc: tagDesc.data,
      short_desc: subTag.description,
      latest_version: subTag.latest_version,
      developer: subTag.developer,
      hasLogo: hasLogo,
    })
    setSubDataIsLoading(false)
  }
  const handleCheckboxChange = (subTagLabel) => {
    setSelectedSubTags((prevSelectedSubTags) =>
      prevSelectedSubTags.includes(subTagLabel)
        ? prevSelectedSubTags.filter((label) => label !== subTagLabel)
        : [...prevSelectedSubTags, subTagLabel]
    )
  }
  return (
    <div className='modal fade bd-example-modal-lg ' tabIndex={-1} id='CustomAnnotationModal'>
      <div
        className='modal-dialog'
        style={{
          width: 1400,
          maxHeight: '600px',
          // overflowY: 'auto',
          // overflowX: 'hidden',
          maxWidth: 'none',
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Custom Annotation </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div
            className='modal-body row'
            style={{
              maxHeight: '600px',
              overflowY: 'auto',
            }}
          >
            <div className='col-md-4'>
              <div className='d-flex flex-wrap'>
                <input
                  type='text'
                  className='form-control p-2 mx-4 my-3'
                  value={filterText}
                  onChange={handleFilterChange}
                  placeholder=' Search...'
                />

                <div className='filtered-subtags d-flex flex-wrap mx-5'>
                  {filterSubTags().map((subTag, index) => (
                    <div
                      key={index}
                      className='  d-flex align-items-center my-2'
                      style={{
                        width: '44%',
                      }}
                    >
                      <input
                        type='checkbox'
                        checked={selectedSubTags.includes(subTag.label)}
                        onChange={() => handleCheckboxChange(subTag.label)}
                        className=''
                      />
                      <span
                        onClick={() => handleSubTagClick(subTag)}
                        className={
                          'cursor-pointer mx-2 fs-9 ' +
                          (selectedSubTag?.title === subTag.label ? 'selected' : '')
                        }
                      >
                        {subTag.label}
                      </span>
                    </div>
                  ))}
                </div>

                {tags.map((tag, index) => (
                  <div key={index} className='w-100'>
                    <div
                      onClick={() => handleTagClick(tag)}
                      className={`custom-annot-tag badge badge-primary-outline mt-2 mx-2 p-2 fs-9 cursor-pointer w-100 ${
                        openTags[tag.label] ? 'active' : ''
                      }`}
                    >
                      {tag.label}
                    </div>
                    {openTags[tag.label] && (
                      <div className='mx-5 d-flex flex-wrap'>
                        {tag.subTags.map((subTag, subIndex) => (
                          <div
                            key={subIndex}
                            className='  d-flex align-items-center my-2'
                            style={{
                              width: '44%',
                            }}
                          >
                            <input
                              type='checkbox'
                              checked={selectedSubTags.includes(subTag.label)}
                              onChange={() => handleCheckboxChange(subTag.label)}
                              className=''
                            />
                            <span
                              onClick={() => handleSubTagClick(subTag)}
                              className={
                                'cursor-pointer mx-2 fs-9 ' +
                                (selectedSubTag?.title === subTag.label ? 'selected' : '')
                              }
                            >
                              {subTag.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className='col-md-8 row'>
              {subDataIsLoading && <Loader />}
              {selectedSubTag && !subDataIsLoading && (
                <>
                  <div className='col-md-12'>
                    {selectedSubTag.hasLogo && (
                      <img
                        src={`https://acurare-01.node.gennext.bio/store/locallogo?module=${selectedSubTag.title}`}
                        style={{width: '125px ', marginBottom: '20px'}}
                        alt={selectedSubTag.title}
                      />
                    )}
                  </div>
                  <div className='col-md-9'>
                    <div className='tag-desc-content'>
                      <MarkdownRenderer
                        markdownText={selectedSubTag.desc}
                        prefix={`https://${currentNode}.node.gennext.bio/modules/annotators/${selectedSubTag.title}/`}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <p>{selectedSubTag.short_desc}</p>
                    <p>
                      <b>Module version</b>: {selectedSubTag.latest_version}
                    </p>
                    <p>
                      <b>E-mail</b>: {selectedSubTag.developer.email}
                    </p>
                    <p>
                      <b>Citation</b>: {selectedSubTag.developer.citation}
                    </p>
                    <p>
                      <b>Organization</b>: {selectedSubTag.developer.organization}
                    </p>
                    <p className='fs-9'>
                      <a
                        href={selectedSubTag.developer.website}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {selectedSubTag.developer.website}
                      </a>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
            <button type='button' data-bs-dismiss='modal' className='btn btn-primary'>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomAnnotationModal
