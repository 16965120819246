import React, {useRef, useState, useEffect} from 'react'
import io from 'socket.io-client'
import Peer from 'simple-peer'
import {v4 as uuidv4} from 'uuid'
import {MD5} from 'crypto-js'
import NodeService from '../../service/node'
import {TagsInput} from 'react-tag-input-component'
import RunsService from '../../service/runs'
 
// import k8s from "@kubernetes/client-node";
// import request from "request";
const Transmit = ({setIsTransferCompleted}) => {
  const [connectionEstablished, setConnection] = useState(false)
  const [connectionInProgress, setConnectionProgress] = useState(false)

  const [transferInProgress, setTransferProgress] = useState(false)
  const [transferBytesCurrent, setTransferBytesCurrent] = useState(0)
  const [transferBytesMax, setTransferBytesMax] = useState(0)

  const socketRef = useRef() 
  const peerRef = useRef()
  const transmitIdRef = useRef('')

  const [nodesList, setNodesList] = useState({})
  const [selectedNode, setSelectedNode] = useState({})
  useEffect(() => {
    NodeService.getNodeList()
      .then((nodes) => nodes.sort((a, b) => b.isUp - a.isUp))
      .then((r) => Object.fromEntries(r.map((rr) => [MD5(JSON.stringify(rr)).toString(), rr])))
      .then((data) => {
        setNodesList(data)
        setSelectedNode(data[Object.keys(data)[0]])
      })
  }, [])

  function Parent() {
    function Child({nodesList, selectedNode, setSelectedNode}) {
      // Problem:
      // This will error if `items` is null/undefined
      return (
        <>
          {
            <select
              className='form-select'
              value={MD5(JSON.stringify(selectedNode))}
              onChange={(e) => {
                setSelectedNode(nodesList[e.target.value])
              }}
            >
              {Object.keys(nodesList).map((r) => (
                <option key={r} value={r}>
                  {nodesList[r].nodeName} - Status: {nodesList[r].isUp ? '✅' : '❎'}
                </option>
              ))}
            </select>
          }
        </>
      )
    }
    // Uninitialized state will cause Child to error out
    // Data does't start loading
    // until *after* Parent is mounted
    // Solution:
    // don't render Child until `items` is ready!
    return (
      <div>
        {nodesList && (
          <Child
            nodesList={nodesList}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
      </div>
    )
  }

  function FileInput({label, onchange, required, accept = null}) {
    return (
      <label className='text'>
        <h3>{label} File:</h3>
        <input
          className='form-control'
          type='file'
          onChange={onchange}
          accept={accept}
          required={required}
        />
      </label>
    )
  }

  function Switch({label, switchRef, disabled, onchange}) {
    return (
      <div className='d-flex align-items-center gap-2 mt-4 mb-4'>
        <h3 className='mb-0'>
          {' '}
          <label htmlFor='swww'>{label}:</label>{' '}
        </h3>
        <input
          id='swww'
          type='checkbox'
          role='switch'
          value={switchRef}
          checked={switchRef}
          onChange={onchange}
          disabled={disabled}
        />
      </div>
    )
  }

  function OptionsSelect({label, options, selectRef, onchange}) {
    if (Array.isArray(options)) {
      return (
        <label className='w-100'>
          <h3>{label}: </h3>
          <select className='form-select' onChange={onchange} value={selectRef}>
            {options.map((r) => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
          </select>
        </label>
      )
    } else {
      return (
        <label className='w-100'>
          {label}:{' '}
          <select className='form-select' onChange={onchange} value={selectRef}>
            {Object.keys(options).map((r) => (
              <option key={r} value={r}>
                {options[r]}
              </option>
            ))}
          </select>
        </label>
      )
    }
  }

  function UploadComponent(props) {
    const processTypes = {
      wes: 'WES (Whole Exome Sequencing)',
      wgs: 'WGS (Whole Genome Sequencing)',
      ces: 'CES (Clinical Exome Sequencing)',
      targetted: 'Targeted Sequencing',
      rna: 'RNA (RNA Sequencing)',
    }
    // const genomeBuilds = {
    //   hg38: 'GRCh38 / hg38',
    //   hg19: 'GRCh37 / hg19',
    // }

    const [selectedProcessType, setSelectedProcessType] = useState(Object.keys(processTypes)[0])
    // const [selectedGenomeBuild, setSelectedGenomeBuild] = useState(Object.keys(genomeBuilds)[0])

    const refVersions = {
      hg38: 'GRCh38 / hg38',
      hg19: 'GRCh37 / hg19',
    }
    const defaultRefVersion = Object.keys(refVersions)[0];
    const [selectedRefVersion, setSelectedRefVersion] = useState(defaultRefVersion)
    const requiresRegionsFile = ['ces', 'targetted']
    const [isBam, setIsBam] = useState(false)

    const [files, setFiles] = useState({})
    const [sampleName, setSampleName] = useState('')
    const [sampleId, setSampleId] = useState('')
    const [errorData, setErrorData] = useState('')

    const [analysts, setAnalysts] = useState()
    const [physicians, setPhysicians] = useState()
    const [physiciansString, setPhysiciansString] = useState()
    const [analystsString, setAnalystsString] = useState()

    const [enrichmentKit, setEnrichmentKit] = useState('')

    function inputValid() {
      // if (!sampleNameValidity) {
      //   setErrorData('Please enter a valid Sample Name.')
      //   return false
      // }
      // if (!sampleIdValidity) {
      //   setErrorData('Please enter a valid Sample ID.')
      //   return false
      // }
      if (isBam) {
        if (!files?.bam?.name) {
          setErrorData('Please select a BAM File.')
          return false
        }
      } else {
        if (!files?.fqR1?.name) {
          setErrorData('Please select FASTQ R1 File.')
          return false
        }
        if (!files?.fqR2?.name) {
          setErrorData('Please select FASTQ R2 File.')
          return false
        }
      }
      if (requiresRegionsFile.includes(selectedProcessType)) {
        if (!files?.regionsFile?.name) {
          setErrorData('Please select a Regions File.')
          return false
        }
      }
      setErrorData('')
      return true
    }
    function fileToObject(file) {
      return {
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
      }
    }
    function createTranferInfo(createdRunId) {
      if (inputValid()) {
        let transferInfo = {}
        transferInfo.sampleName = sampleName
        transferInfo.sampleId = sampleId
        transferInfo.files = Object.keys(files).reduce((obj, key, idx) => {
          obj[key] = fileToObject(files[key])
          return obj
        }, {})
        transferInfo.processType = selectedProcessType
        transferInfo.isBam = isBam
        transferInfo.refVersion = isBam ? selectedRefVersion : defaultRefVersion
        transferInfo.genomeBuild = isBam ? selectedRefVersion : defaultRefVersion
        transferInfo.jobId = createdRunId
        transferInfo.userId = getUserInfo().userId 
        transferInfo.userHash = getUserInfo().userId
        transferInfo.transferStartTime = new Date() 
        return transferInfo
      } else {
        return null
      }
    }

    function sendFiles(transferInfo, files, newRunId) {
      setTransferBytesMax(files.map((f) => f.size).reduce((a, b) => a + b))
      const peer = peerRef?.current
      peer?.write(JSON.stringify(transferInfo))
      let filesQueue = Array.from(files)
      let reader = null
      console.log(filesQueue)
      setTransferProgress(true)
      let stream = filesQueue?.shift()?.stream()
      reader = stream?.getReader()
  
      async function uploadFile() {
        let ok = true
  
        do {
          var {done, value} = await reader.read()
  
          if (done) {
            if (filesQueue.length !== 0) {
              console.log(filesQueue)
              stream = filesQueue.shift().stream()
              reader = stream.getReader()
              await uploadFile()
            } else {
              await RunsService.startRun(newRunId)
              setIsTransferCompleted(true)
            }
            break
          } else {
            if (!!value) {
              for (let i = 0; i < value?.length ?? 0; i = i + 65536) {
                ok = await peer?.write(value.slice(i, i + 65536))
              }
              /*eslint no-loop-func: "off"*/
              setTransferBytesCurrent(
                (transferBytesCurrent) => transferBytesCurrent + value?.length ?? 0
              )
            }
          }
        } while (!done && ok)
        if (!done) {
          peer?.once('drain', uploadFile)
        }
      }

      try {
        uploadFile()
      } catch (error) {
        console.log('err', error)
      }
    } 
    
    return (
      <>
        <div className=''>
          <div className='w-100'>
            {!!errorData && errorData.length > 0 && (
              <div className='alert alert-danger d-flex align-items-center p-5'>
                <div className='d-flex flex-column'>
                  <span>{errorData}</span>
                </div>
              </div>
            )}

            <input
              className='form-control mb-4'
              placeholder='Sample ID'
              onChange={(e) => {
                setSampleId(e.target.value)
              }}
              // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
              required
            />
            <small>
              Only letters, numbers, hyphens, underscores, and periods. Must start with a letter and
              be between 4 and 30 characters long.{' '}
            </small>
            <input
              className='form-control mb-4'
              placeholder='Sample Name (Optional)'
              onChange={(e) => {
                setSampleName(e.target.value)
              }}
              pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
            />

            <div className='d-flex gap-4'>
              <input
                className='form-control mb-4 '
                placeholder='Enrichment Kit'
                value={enrichmentKit}
                onChange={(e) => {
                  setEnrichmentKit(e.target.value)
                }}
              />
              {/* {isBam ? (
                <div className='w-50 mb-5'>
                  <OptionsSelect
                    className='form-control mb-4 ml-2'
                    label='Genome Build * '
                    options={genomeBuilds}
                    selectRef={selectedProcessType}
                    onchange={(e) => {
                      setSelectedGenomeBuild(e.target.value)
                    }}
                  />
                </div>
              ) : (
                // <input
                //   placeholder='Genome Build '
                //   required
                //   style={{marginLeft: 5}}
                //   value={genomeBuild}
                //   onChange={(e) => {
                //     setGenomeBuild(e.target.value)
                //   }}
                // />
                ''
              )} */}
            </div>

            <div className='d-flex gap-4 new-run-rti'>
              <TagsInput
                name='refferingPhys'
                value={physicians}
                onChange={setPhysicians}
                placeHolder='Enter Physicians'
                onKeyUp={(e) => {
                  if (!physicians) {
                    setPhysiciansString([e.target.value])
                  }
                }}
              />
              <TagsInput
                classNames={'mb-4'}
                name='refferingAnaylsts'
                value={analysts}
                onChange={setAnalysts}
                placeHolder='Enter Analysts'
                onKeyUp={(e) => {
                  if (!analysts) {
                    setAnalystsString([e.target.value])
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className='grid mt-2'>
          <OptionsSelect
            label='Process Type'
            options={processTypes}
            selectRef={selectedProcessType}
            onchange={(e) => {
              setSelectedProcessType(e.target.value)
              setFiles({})
              setIsBam(false)
            }}
          />
          <div className='pl-2'>
            <Switch
              label='Is Bam'
              switchRef={isBam}
              onchange={() => setIsBam(!isBam)}
              disabled={selectedProcessType === 'rna'}
            />
          </div>
        </div>
        <div className='d-flex flex-column gap-4 mt-4'>
          {isBam ? (
            <>

                {/* <OptionsSelect
                    className='form-control mb-4 ml-2'
                    label='Genome Build * '
                    options={genomeBuilds}
                    selectRef={selectedGenomeBuild}
                    onchange={(e) => {
                      setSelectedGenomeBuild(e.target.value)
                    }}
                  /> */}
              <OptionsSelect
                label='Reference Genome'
                options={refVersions}
                selectRef={selectedRefVersion}
                onchange={(e) => setSelectedRefVersion(e.target.value)}
              />
              <FileInput
                label='BAM'
                onchange={(e) => setFiles({...files, bam: e.target.files[0]})}
                accept='.bam'
              />
              
            </>
          ) : (
            <>
              <FileInput
                label='FASTQ R1'
                onchange={(e) => setFiles({...files, fqR1: e.target.files[0]})}
                accept='.fq.gz,.fastq.gz,.gz'
                required={true}
              />
              <FileInput
                label='FASTQ R2'
                onchange={(e) => setFiles({...files, fqR2: e.target.files[0]})}
                accept='.fq.gz,.fastq.gz,.gz'
                required={true}
              />
            </>
          )}
          {requiresRegionsFile.includes(selectedProcessType) ? (
            <FileInput
              label='Regions'
              onchange={(e) => setFiles({...files, regionsFile: e.target.files[0]})}
              accept='.bed'
            />
          ) : (
            <></>
          )}
        </div>
        <button
          // disabled={!genomeBuild}
          className='btn btn-primary mt-2'
          onClick={async () => {
            const createdRun = await RunsService.createRun({
              physicians: physicians ?? physiciansString,
              analysts: analysts ?? analystsString,
              processType: selectedProcessType,
              sampleName,
              onNode: selectedNode?.nodeName,
              sampleId: sampleId,
              enrichmentKit,
              genomeBuild:selectedRefVersion,
            })

            if (createdRun?.response?.status===406) {
              setErrorData(createdRun.response.data.message)
              return
            }
            
            const ti = createTranferInfo()
            if (ti) {
              sendFiles(createTranferInfo(createdRun?.data?.runId), Object.values(files), createdRun?.data?.runId)
            }
          }}
        >
          {' '}
          Send Files
        </button>
      </>
    )
  }
 
  function getUserInfo() {
    // TODO
    // Retrieve from current user session.
    let currentUser = JSON.parse(localStorage.getItem('user'))

    let dummyData = {userId: currentUser.id, userName: currentUser.username, edgeNode: selectedNode}
    return dummyData
  }

  function createTransmit() {
    transmitIdRef.current = uuidv4()
    const userInfo = getUserInfo()
    setConnectionProgress(true)
    const transmitId = transmitIdRef.current
    // const userInfo = getUserInfo()
    socketRef.current = io.connect(process.env.REACT_APP_EDGE_URL)
    socketRef.current.emit('join transmit', {
      transmitId: transmitId,
      userName: userInfo.userName,
      edgeNode: userInfo.edgeNode,
    })
    socketRef.current.on('all users', (users) => {
      // console.log(users)
      peerRef.current = createPeer(users[0], socketRef.current.id)
    })

    socketRef.current.on('user joined', (payload) => {
      // console.log("edge conn established")
      peerRef.current = addPeer(payload.signal, payload.callerId)
    })

    socketRef.current.on('receiving returned signal', (payload) => {
      peerRef.current.signal(payload.signal)
      // console.log(payload.signal)
      setConnection(true)
      setConnectionProgress(false)
    })

    socketRef.current.on('transmit in progress', () => {
      alert('Transmit is in progress.')
    })
  }
  function createPeer(userToSignal, callerId) {
    const peer = new Peer({
      initiator: true,
      config: {
        iceServers: [
          {urls: 'stun:stun.l.google.com:19302'},
          // {
          //     urls: "stun:openrelay.metered.ca:80",
          // },
          {
            urls: 'turn:openrelay.metered.ca:80',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
          // {
          //     urls: "turn:openrelay.metered.ca:443",
          //     username: "openrelayproject",
          //     credential: "openrelayproject",
          // },
          // {
          //     urls: "turn:openrelay.metered.ca:443?transport=tcp",
          //     username: "openrelayproject",
          //     credential: "openrelayproject",
          // },
        ],
      },
      trickle: false,
    })

    peer.on('signal', (signal) => {
      // console.log('callerId: ', callerId)
      // console.log('User to signal: ', userToSignal)
      socketRef.current.emit('sending signal', {userToSignal, callerId, signal})
    })

    // peer.on("data", handleReceivingData);

    return peer
  }

  function addPeer(incomingSignal, callerId) {
    const peer = new Peer({
      initiator: false,
      config: {
        iceServers: [
          {urls: 'stun:stun.l.google.com:19302'},
          // {
          //     urls: "stun:openrelay.metered.ca:80",
          // },
          {
            urls: 'turn:openrelay.metered.ca:80',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
          // {
          //     urls: "turn:openrelay.metered.ca:443",
          //     username: "openrelayproject",
          //     credential: "openrelayproject",
          // },
          // {
          //     urls: "turn:openrelay.metered.ca:443?transport=tcp",
          //     username: "openrelayproject",
          //     credential: "openrelayproject",
          // },
        ],
      },
      trickle: false,
    })
    peer.on('signal', (signal) => {
      // console.log('signal from ', callerId)
      socketRef.current.emit('returning signal', {signal, callerId})
    })

    // peer.on("data", handleReceivingData);

    peer.signal(incomingSignal)
    setConnection(true)
    return peer
  }

  function humanFileSize(size) {
    var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  let body
  if (!connectionEstablished & !connectionInProgress) {
    body = (
      <div style={{display: 'block'}}>
        <div style={{display: 'block'}}>
          <Parent />
        </div>
        <div style={{display: 'block'}}>
          <button className='btn btn-primary mt-2' onClick={createTransmit}>
            Create transmit
          </button>
        </div>
      </div>
    )
  } else if (!connectionEstablished & connectionInProgress) {
    body = (
      <h3>
        Waiting for edge connection.{' '}
        <span className='indicator-progress' style={{display: 'inline-block'}}>
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>{' '}
      </h3>
    )
  } else if (connectionEstablished & !transferInProgress) {
    body = (
      <>
        <UploadComponent />
      </>
    )
  } else if (transferInProgress) {
    body = (
      <div>
        <progress
          className='progress-bar w-100 progress'
          id='transferProgress'
          value={transferBytesCurrent}
          max={transferBytesMax}
        />
        {humanFileSize(transferBytesCurrent)} / {humanFileSize(transferBytesMax)}
      </div>
    )
  } else {
    body = (
      <div>
        <button onClick={createTransmit}>Create transmit</button>
        <Parent />
      </div>
    )
  }

  return (
    <div>
      <main className='container'>
        <div>{body}</div>
      </main>
    </div>
  )
}

export default Transmit
