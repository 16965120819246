import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../_metronic/helpers'
const CohortModal = ({cohorts}) => {
  return (
    <div className='modal fade bd-example-modal-lg ' tabIndex={-1} id='CohortModal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Edit Cohorts </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <textarea
              className='form-control form-control-lg  my-3 form-control-solid'
              style={{minHeight: '350px'}}
              value={cohorts.join('\n')}
            ></textarea>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
            <button type='button' className='btn btn-primary'>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CohortModal
