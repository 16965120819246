/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon} from '../../_metronic/helpers'
const Notifications = ({className}) => {
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title fw-bold text-dark'>Notifications</h3>
      </div>
      <div className='card-body pt-5 notifications-list'>
        <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
          <span className=' text-warning me-5'>
            <KTIcon iconName='abstract-26' className='text-warning fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              Group lunch celebration
            </a>
            <span className='text-muted fw-semibold d-block'>Due in 2 Days</span>
          </div>
        </div>
        <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
          <span className=' text-success me-5'>
            <KTIcon iconName='abstract-26' className='text-success fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              Navigation optimization
            </a>
            <span className='text-muted fw-semibold d-block'>Due in 2 Days</span>
          </div>
        </div>
        <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
          <span className=' text-danger me-5'>
            <KTIcon iconName='abstract-26' className='text-danger fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              Rebrand strategy planning
            </a>
            <span className='text-muted fw-semibold d-block'>Due in 5 Days</span>
          </div>
        </div>
        <div className='d-flex align-items-center bg-light-info rounded p-5'>
          <span className=' text-info me-5'>
            <KTIcon iconName='abstract-26' className='text-info fs-1 me-5' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              Product goals strategy
            </a>
            <span className='text-muted fw-semibold d-block'>Due in 7 Days</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Notifications}
