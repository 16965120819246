/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import FileDownloadModal from './FileDownloadModal'

const RunInformation = ({
  processType,
  enrichmenKit,
  pipelineVersion,
  genomeBuild,
  currentRunId,
  currentNode,
  runStatus,
}) => {
  const intl = useIntl()

  var hasUp = false
  const [modalOpen, setModalOpen] = useState(false)
  if (processType === 'som-test-tumor-rna') {
    processType = 'Somatic Testing (DNA+RNA)'
  } else if (processType === 'som-test-tumor') {
    processType = 'Somatic Testing (DNA)'
  } else if (processType === 'som-test-rna') {
    processType = 'Somatic Testing (RNA)'
  } else {
    hasUp = true
  }
  return (
    // Sample Information Card Start
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>
            {intl.formatMessage({id: 'RUNINFARMATION'})}
          </span>
        </h3>
      </div>
      <div className='card-body py-0 px-2 '>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Process Type :
            </div>
            <div
              className={`fw-mormal timeline-content text-muted ps-3 ${
                hasUp ? 'text-uppercase' : ''
              }`}
            >
              {processType}{' '}
            </div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Enrichment Kit :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{enrichmenKit}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Pipeline Version :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{pipelineVersion}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Genome Build :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{genomeBuild}</div>
          </div>
        </div>

        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Run Status :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span className='badge badge-light-success text-capitalize'> {runStatus}</span>
            </div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>Node :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span> {currentNode}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end px-0'>
        <span
          className='btn btn-primary'
          data-bs-toggle='modal'
          data-bs-target='#FileDownloadModal'
          onClick={() => {
            setModalOpen(true)
          }}
        >
          File Download <i className='fa fa-arrow-down'></i>
        </span>
      </div>

      <FileDownloadModal
        modalOpen={modalOpen}
        currentNode={currentNode}
        currentRunId={currentRunId}
      />
    </div>
    // Sample Information Card End
  )
}

export {RunInformation}
