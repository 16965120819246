// import k8s from "@kubernetes/client-node";
// import request from "request";
import {useState} from 'react'
import {TagsInput} from 'react-tag-input-component'
import {MultipleFileUpload} from '../MultipleFileUpload'

const WithDataTransmit = () => {
  const userData = {
    userProcessTypes: [
      {
        type: 'wgs',
        label: 'Whole Genome Sequencing (WGS)',
      },
      {
        type: 'wes',
        label: 'Whole Exome Sequencing (WES)',
      },
      {
        type: 'tps',
        label: 'Targetted Panel Sequencing (TPS)',
      },
      {
        type: 'rnaseq',
        label: 'Bulk RNA Sequencing (RNAseq)',
      },
      {
        type: 'somaticTesting',
        label: 'Somatic Testing',
      },
      {
        type: 'prs',
        label: 'Polygenic Risk Score (PRS)',
      },
    ],
  }
  const specimenTypes = [
    {
      label: 'Formalin-Fixed',
      value: 'Formalin-Fixed',
    },
    {
      label: 'Fresh Frozen Tissue',
      value: 'Fresh Frozen Tissue',
    },
    {
      label: 'Fine Needle Aspiration (FNA)',
      value: 'Fine Needle Aspiration (FNA)',
    },
    {
      label: 'Core Biopsy Samples',
      value: 'Core Biopsy Samples',
    },
    {
      label: 'Circulating Tumor DNA (ctDNA) from Blood',
      value: 'Circulating Tumor DNA (ctDNA) from Blood',
    },
    {
      label: 'Pleural Effusions or Ascites',
      value: 'Pleural Effusions or Ascites',
    },
    {
      label: 'Peripheral blood',
      value: 'Peripheral blood',
    },
    {
      label: 'Bone Marrow Aspirate',
      value: 'Bone Marrow Aspirate',
    },
    {
      label: 'Cell-Free DNA (cfDNA) from Body Fluids',
      value: 'Cell-Free DNA (cfDNA) from Body Fluids',
    },
    {
      label: 'Cell Pellets from Cultured Tumor Cells',
      value: 'Cell Pellets from Cultured Tumor Cells',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ]
  const specimenSites = [
    {
      label: 'Breast',
    },
    {
      label: 'Lung',
    },
    {
      label: 'Gastrointestinal Tract',
    },
    {
      label: 'Genitourinary System',
    },
    {
      label: 'Head and Neck',
    },
    {
      label: 'Central Nervous System',
    },
    {
      label: 'Hematologic',
    },
    {
      label: 'Skin',
    },
    {
      label: 'Musculoskeletal System',
    },
    {
      label: 'Endocrine System',
    },
    {
      label: 'Pleural and Peritoneal Cavities',
    },
    {
      label: 'Heart and Great Vessels',
    },
    {
      label: 'Others',
    },
  ]
  const prsTests = [
    {
      label: 'DermaGene',
    },
    {
      label: 'FitGene',
    },
    {
      label: 'NutriGene',
    },
    {
      label: 'DNAware',
    },
    {
      label: 'TalentGene',
    },
  ]
  const superPopulation = [
    {
      label: 'European',
    },
    {
      label: 'African',
    },
    {
      label: 'American',
    },
    {
      label: 'East asian',
    },
    {
      label: 'South asian',
    },
  ]
  const fileTypes = [
    {
      label: 'Fastq',
      type: 'fastq',
    },
    {
      label: 'Bam',
      type: 'bam',
    },
    {
      label: 'Vcf',
      type: 'vcf',
    },
  ]
  function FileInput({label, onchange, multiple, required = true, accept = null}) {
    return (
      <div className='d-flex align-items-center my-4 '>
        <div className='col-md-2'>
          <label className='text'>
            <h5>{label} </h5>
          </label>
        </div>
        <div className='col-md-10'>
          <input
            className='form-control'
            type='file'
            multiple={multiple}
            onChange={onchange}
            accept={accept}
            required={required}
          />
        </div>
      </div>
    )
  }
  function CustomInputFields() {
    if (
      selectedProcessType === 'wgs' ||
      selectedProcessType === 'wes' ||
      selectedProcessType === 'tps'
    ) {
      return (
        <>
          <div className='col-md-12 row'>
            <div className='  col-md-6'>
              <TagsInput
                name='refferingPhys'
                value={physicians}
                onChange={setPhysicians}
                placeHolder='Enter Physicians'
                onKeyUp={(e) => {
                  if (!physicians) {
                    setPhysiciansString([e.target.value])
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <TagsInput
                classNames={'mb-4'}
                name='refferingAnaylsts'
                value={analysts}
                onChange={setAnalysts}
                placeHolder='Enter Analysts'
                onKeyUp={(e) => {
                  if (!analysts) {
                    setAnalystsString([e.target.value])
                  }
                }}
              />
            </div>
          </div>

          <div className='col-md-12 row my-2'>
            <div className='col-md-6 d-flex'>
              <div className='col-md-6'>
                <OptionsSelect
                  className='ontrol mb-4  '
                  label='File Type '
                  options={fileTypes}
                  onchange={(e) => {
                    setFileType(e.target.value)
                  }}
                  // selectRef={selectedProcessType}
                />
                <div className='checkboxes mx-3 '>
                  {/* <Radio
                    label='Is Fastq'
                    name={`fileType`}
                    switchRef={'fastq'}
                    onchange={() => setFileType('fastq')}
                  />
                  <Radio
                    label='Is Bam'
                    name={`fileType`}
                    switchRef={'bam'}
                    onchange={() => setFileType('bam')}
                  />
                  <Radio
                    label='Is Vcf'
                    name={`fileType`}
                    switchRef={'vcf'}
                    onchange={() => setFileType('vcf')}
                  /> */}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='checkboxes mx-3'>
                  <Switch
                    label='Is Batch'
                    switchRef={isBatch}
                    onchange={() => setIsBatch(!isBatch)}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <OptionsSelect
                className='ontrol mb-4  '
                label='Reference Version  '
                options={userData.userProcessTypes}
                // selectRef={selectedProcessType}
              />

              <OptionsSelect
                className='ontrol mb-4  '
                label={`Enrichment Kit ${selectedProcessType === 'wes' ? '(Optional)' : ' '}`}
                options={userData.userProcessTypes}
                // selectRef={selectedProcessType}
              />
            </div>
          </div>
        </>
      )
    }
    if (selectedProcessType === 'rnaseq') {
      return (
        <>
          <div className='col-md-12 row'>
            <div className='  col-md-6'>
              <TagsInput
                name='refferingPhys'
                value={physicians}
                onChange={setPhysicians}
                placeHolder='Enter Physicians'
                onKeyUp={(e) => {
                  if (!physicians) {
                    setPhysiciansString([e.target.value])
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <TagsInput
                classNames={'mb-4'}
                name='refferingAnaylsts'
                value={analysts}
                onChange={setAnalysts}
                placeHolder='Enter Analysts'
                onKeyUp={(e) => {
                  if (!analysts) {
                    setAnalystsString([e.target.value])
                  }
                }}
              />
            </div>
          </div>

          <div className='col-md-12 row my-2'>
            <div className='col-md-6 d-flex'>
              <div className='col-md-6'>
                <div className='checkboxes mx-3'>
                  <Switch
                    label='Is Single End'
                    name={`fileType`}
                    switchRef={isSingleEnd}
                    onchange={() => setFileType(!isSingleEnd)}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='checkboxes mx-3'>
                  <Switch
                    label='Is Batch'
                    switchRef={isBatch}
                    onchange={() => setIsBatch(!isBatch)}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <OptionsSelect
                className=' mb-4 mt-2  '
                label='Reference Version  '
                options={userData.userProcessTypes}
                // selectRef={selectedProcessType}
              />
            </div>
          </div>
        </>
      )
    }
    if (selectedProcessType === 'somaticTesting') {
      return (
        <>
          <div className='col-md-12 row'>
            <div className='  col-md-6'>
              <TagsInput
                name='refferingPhys'
                value={physicians}
                onChange={setPhysicians}
                placeHolder='Enter Physicians'
                onKeyUp={(e) => {
                  if (!physicians) {
                    setPhysiciansString([e.target.value])
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <TagsInput
                classNames={'mb-4'}
                name='refferingAnaylsts'
                value={analysts}
                onChange={setAnalysts}
                placeHolder='Enter Analysts'
                onKeyUp={(e) => {
                  if (!analysts) {
                    setAnalystsString([e.target.value])
                  }
                }}
              />
            </div>
          </div>

          <div className='col-md-12 row my-2'>
            <div className='col-md-6'>
              <OptionsSelect
                className='form-control  my-2 '
                label='Clinical Diagnosis * '
                options={userData.userProcessTypes}
              />
              <div className='d-flex'>
                <div className='col-md-6'>
                  <div className='checkboxes mx-3'>
                    <OptionsSelect
                      className='form-control  my-2 '
                      label='Specimen Site * '
                      options={specimenSites}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='checkboxes mx-3'>
                    <OptionsSelect
                      className='form-control my-2 mx-2'
                      label='Specimen Type  * '
                      options={specimenTypes}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <OptionsSelect
                className='form-control  my-2 '
                label='Reference Version * '
                options={userData.userProcessTypes}
              />
              <OptionsSelect
                className='form-control  my-2 '
                label='Sureselect Cancer CGP Assay * '
                options={userData.userProcessTypes}
              />
            </div>
          </div>
        </>
      )
    }
    if (selectedProcessType === 'prs') {
      return (
        <>
          <div className='col-md-12 row'>
            <div className='  col-md-6'>
              <TagsInput
                name='institute'
                value={physicians}
                onChange={setInstitute}
                placeHolder='Enter Institute'
                onKeyUp={(e) => {
                  if (!physicians) {
                    setInstituteString([e.target.value])
                  }
                }}
              />
              <div className='my-4'>
                <TagsInput
                  name='refferingPhys'
                  value={physicians}
                  onChange={setPhysicians}
                  placeHolder='Enter Physicians'
                  onKeyUp={(e) => {
                    if (!physicians) {
                      setPhysiciansString([e.target.value])
                    }
                  }}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <TagsInput
                classNames={'mb-4'}
                name='refferingAnaylsts'
                value={analysts}
                onChange={setAnalysts}
                placeHolder='Enter Analysts'
                onKeyUp={(e) => {
                  if (!analysts) {
                    setAnalystsString([e.target.value])
                  }
                }}
              />
              <Switch
                label='Is Batch'
                className={' mt-8  '}
                switchRef={isBatch}
                onchange={() => setIsBatch(!isBatch)}
              />
            </div>
          </div>

          <div className='col-md-12 row my-2'>
            <div className='col-md-6'>
              <OptionsSelect
                className='form-control  my-2 '
                label='Test Type * '
                options={prsTests}
              />

              <div className='d-flex my-2'>
                <div className='checkboxes mx-3 w-100'>
                  <input
                    className='form-control mb-4'
                    placeholder='P-Value Cutoff (Exp: "1.0 x10 -5")'
                    // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
                    required
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <OptionsSelect
                className='form-control  my-2 '
                label='Reference Version * '
                options={userData.userProcessTypes}
              />
              <OptionsSelect
                className='form-control  my-2 '
                label='Super Population * '
                options={superPopulation}
              />
            </div>
            <div className='col-md-4'>
              <label className='my-2'> Linkage-disequilibrium Clumping</label>
              <Switch
                label={'Sample-Wide Clumping'}
                className={'mx-5'}
                labelClass='linkage-label'
              />
              <Switch label={'Individual Clumping'} className={'mx-5'} labelClass='linkage-label' />
            </div>
            <div className='col-md-4'>
              <label className='my-2'> MAF Threshold </label>
              <input
                className='form-control mb-4 w-50'
                type='number'
                onChange={(e) => {
                  setSampleId(e.target.value)
                }}
                value={0}
                // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
                required
              />
            </div>
            <div className='col-md-4'>
              <label className='my-2'> Imputation Threshold </label>
              <input
                className='form-control mb-4 w-50'
                type='number'
                value={0}
                onChange={(e) => {
                  setSampleId(e.target.value)
                }}
                // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
                required
              />
            </div>
          </div>
        </>
      )
    }
  }
  function FileUploadTypes() {
    if (selectedProcessType === 'wgs') {
      if (fileType === 'fastq' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload FASTQ R1 File'} />
            <FileInput label={'Upload FASTQ R2 File'} />
          </>
        )
      } else if (fileType === 'bam' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload BAM File'} />
          </>
        )
      } else if (fileType === 'vcf' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload VCF File'} />
          </>
        )
      } else if (fileType === 'fastq' || fileType === 'bam' || (fileType === 'vcf' && isBatch)) {
        return (
          <>
            <MultipleFileUpload />
          </>
        )
      }
    } else if (selectedProcessType === 'wes') {
      if (fileType === 'fastq' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload FASTQ R1 File'} />
            <FileInput label={'Upload FASTQ R2 File'} />
          </>
        )
      } else if (fileType === 'bam' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload BAM File'} />
          </>
        )
      } else if (fileType === 'vcf' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload VCF File'} />
          </>
        )
      } else if (fileType === 'fastq' || fileType === 'bam' || (fileType === 'vcf' && isBatch)) {
        return (
          <>
            <MultipleFileUpload />
          </>
        )
      }
    } else if (selectedProcessType === 'tps') {
      if (fileType === 'fastq' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload FASTQ R1 File'} />
            <FileInput label={'Upload FASTQ R2 File'} />
            <FileInput label={'Upload Regions File (Optional)'} required={false} />
          </>
        )
      } else if (fileType === 'bam' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload BAM File'} />
            <FileInput label={'Upload Regions File (Optional)'} required={false} />
          </>
        )
      } else if (fileType === 'vcf' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload VCF File'} />
            <FileInput label={'Upload Regions File (Optional)'} required={false} />
          </>
        )
      } else if (fileType === 'fastq' || fileType === 'bam' || (fileType === 'vcf' && isBatch)) {
        return (
          <>
            <MultipleFileUpload />
            <FileInput label={'Upload Regions File (Optional)'} required={false} />
          </>
        )
      }
    } else if (selectedProcessType === 'rnaseq') {
      if (fileType !== 'isSingleEnd' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload FASTQ R1 File'} />
            <FileInput label={'Upload FASTQ R2 File'} />
          </>
        )
      } else if (fileType !== 'isSingleEnd' && isBatch) {
        return (
          <>
            <MultipleFileUpload />
          </>
        )
      } else if (fileType === 'isSingleEnd' && !isBatch) {
        return (
          <>
            <FileInput label={'Upload FASTQ R1 File'} />
          </>
        )
      } else if (fileType === 'isSingleEnd' && isBatch) {
        return (
          <>
            <MultipleFileUpload />
          </>
        )
      }
    } else if (selectedProcessType === 'somaticTesting') {
      return (
        <>
          <FileInput label={'Upload FASTQ R1 File (DNA)'} />
          <FileInput label={'Upload FASTQ R2 File (DNA)'} />
          <FileInput label={'Upload FASTQ R1 File (RNA)'} />
          <FileInput label={'Upload FASTQ R2 File (RNA)'} />
        </>
      )
    } else if (selectedProcessType === 'prs') {
      if (!isBatch) {
        return (
          <>
            <FileInput label={'Upload  Genotype File'} />
          </>
        )
      } else if (isBatch) {
        return (
          <>
            <FileInput label={'Upload Multisample Genotype File'} />
          </>
        )
      }
    }

    return (
      <div>
        <h3>
          {fileType}
          {isBatch ? 'batch var' : ' batch yok'}
        </h3>
      </div>
    )
  }
  function Switch({label, switchRef, disabled, onchange, className, labelClass}) {
    return (
      <div className={`d-flex align-items-center gap-2 mt-4 mb-4 ${className}`}>
        <input
          id={'zff'}
          type='checkbox'
          role='switch'
          value={switchRef}
          checked={switchRef}
          onChange={onchange}
          disabled={disabled}
        />
        <h5 className='mb-0'>
          {' '}
          <label className={labelClass} htmlFor={'zff'}>
            {label}
          </label>{' '}
        </h5>
      </div>
    )
  }
  function OptionsSelect({label, options, selectRef, className, onchange}) {
    if (Array.isArray(options)) {
      return (
        <label className='w-100'>
          <select className={`form-select ${className} `} onChange={onchange} value={selectRef}>
            {label && (
              <option disabled selected>
                {' '}
                {label}
              </option>
            )}
            {options.map((item, index) => (
              <option key={index} value={item.type}>
                {item.label}
              </option>
            ))}
          </select>
        </label>
      )
    }
  }
  const [analysts, setAnalysts] = useState()
  const [selectedProcessType, setSelectedProcessType] = useState(userData.userProcessTypes[0].type)
  const [physicians, setPhysicians] = useState()
  const [physiciansString, setPhysiciansString] = useState()
  const [institute, setInstitute] = useState()
  const [institutesString, setInstituteString] = useState()
  const [analystsString, setAnalystsString] = useState()
  const [sampleId, setSampleId] = useState()

  const [isBatch, setIsBatch] = useState(false)

  const [fileType, setFileType] = useState('fastq')
  const [isSingleEnd, setIsSingleEnd] = useState(false)

  return (
    <>
      <div className=' gap-4 new-run-rti'>
        <div className='col-md-12 row'>
          <div className='new-run-item col-md-3'>
            <input
              className='form-control mb-4'
              placeholder='Sample ID'
              onChange={(e) => {
                setSampleId(e.target.value)
              }}
              // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
              required
            />
          </div>
          <div className='new-run-item col-md-3'>
            <input
              className='form-control mb-4'
              placeholder='Sample Name (Optional) '
              // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
              required
            />
          </div>
          <div className='new-run-item col-md-6'>
            <OptionsSelect
              className='ontrol mb-4 '
              options={userData.userProcessTypes}
              selectRef={selectedProcessType}
              onchange={(e) => {
                setSelectedProcessType(e.target.value)
              }}
            />
          </div>
        </div>
        <CustomInputFields />
        <div className='col-md-12 row my-2'>
          <div className='file-upload-area'>
            <FileUploadTypes />
          </div>
        </div>
      </div>
    </>
  )
}

export default WithDataTransmit
