import axios from './index'

const getUserProfile = async () => {
  const res = await axios.get(`/user/me`)
  return res
}

const UserService = {
  getUserProfile,
}

export default UserService
