import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import axios from 'axios'
import {afbHeader, afbVariantHeader} from '../../static/somaticVariantHeaders'
import {useDebounce} from '../../hooks'
import somaticService from '../../service/somatic'
import AlleleFreqTableComponent from '../../components/TableComponent/AlleleFreqTableComponent'
import {Loader} from '../../components/Loader'
import {useThemeMode} from '../../_metronic/partials'

const AlleleFreqPage = () => {
  function detectVariantType(input) {
    if (input.startsWith('rs')) {
      return 'rsid'
    } else if (/^(chr\d+:|^\d+:)/.test(input)) {
      if (input.includes('/')) {
        // return 'Variant (pos)'
        return 'variant'
      } else {
        return 'region'
      }
    } else {
      return 'gene'
    }
  }
  var theme = useThemeMode()
  const [searchHpo, setSearchHpo] = useState(false)
  const [queryResponse, setQueryResponse] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [directlyVariant, setDirectlyVariant] = useState(false)
  const [variantResponse, setVariantResponse] = useState([])
  const [variantHeaderResponse, setVariantHeaderResponse] = useState({})
  const hideSearchHPO = () => {
    setHpoTerms('')
    setSearchHpo(false)
  }
  const [hpoTerms, setHpoTerms] = useState('')
  const [step, setStep] = useState(1)
  const debouncedPhenoType = useDebounce(hpoTerms, 500)
  const [hpoList, setHpoList] = useState([])
  const [selectedDropdown, setSelectedDropdown] = useState('Gene')
  const [selectedType, setSelectedType] = useState(1)
  const [queryStrategy, setQueryStrategy] = useState('wide')
  const [gridOptions, setGridOptions] = useState(undefined)

  const [phenoTypeFilterIsActive, setPhenoTypeFilterIsActive] = useState(false)
  const [phenoTypeFilters, setPhenoTypeFilters] = useState([])

  const getData = () => {
    if (debouncedPhenoType === '') {
      setHpoList([])
      return
    }
    axios
      .get(`https://ontology.jax.org/api/hp/search?q=${debouncedPhenoType}&max=-1`)
      .then((res) => {
        const termsResponse = res?.data?.terms
        if (!!termsResponse && Array.isArray(termsResponse) && termsResponse.length > 0) {
          setHpoList(termsResponse)
        } else {
          setHpoList([])
        }
      })
  }
  const hpoChange = (event) => {
    setHpoTerms(event.target.value)
    getData()
  }
  useEffect(getData, [debouncedPhenoType])

  const addHpoToPhenotypeList = (id, name) => {
    setPhenoTypeFilters((prevFilters) => [...prevFilters, {id: id, name: name}])
  }
  const handleSubmitAFB = async (inputText, selectedDropdown) => {
    setIsLoading(true)
    var type = detectVariantType(inputText)
    if (type === 'variant') {
      handleSubmitVariant(inputText)
      setDirectlyVariant(true)
      return
    }
    var phenotypes = phenoTypeFilters.map((item) => {
      return item.id
    })
    var reqData = {
      queryType: type, // [region, gene, rsid] (rsid disabled)
      queryTerm: inputText, // search field text
      queryStrategy: queryStrategy, // [wide, narrow]
      phenotypes: phenotypes,
      variantType: selectedType === 1 ? 'germline' : 'somatic', // [
    }
    var req = await somaticService.searchAFB(reqData)
    if (req.status === 200) {
      var data = req.data.map((item, index) => {
        return {
          id: index,
          ...item,
        }
      })
      setQueryResponse(data)
      setStep(2)
      setIsLoading(false)
    }
  }
  const handleSubmitVariant = async (variant) => {
    setIsLoading(true)
    var phenotypes = phenoTypeFilters.map((item) => {
      return item.id
    })
    var reqData = {
      queryType: 'variant', // [region, gene, rsid] (rsid disabled)
      queryTerm: variant, // search field text
      queryStrategy: queryStrategy, // [wide, narrow]
      phenotypes: phenotypes, // list of phenotypes
      variantType: selectedType === 1 ? 'germline' : 'somatic', // [
    }
    var req = await somaticService.searchAFB(reqData, true)
    if (req.status === 200) {
      var data = req.data.phenotypeAf.map((item, index) => {
        return {
          id: index,
          ...item,
        }
      })
      setVariantHeaderResponse({
        variantId: req.data.variantId,
        annots: req.data.annots,
      })
      setVariantResponse(data)
      setStep(3)
      setIsLoading(false)
    }
  }
  const [inputText, setInputText] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  if (isLoading) {
    return (
      <div className='text-center'>
        <Loader />
      </div>
    )
  }
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <PageTitle> Allele Frequency Browser </PageTitle>
        <img
          src={`./media/logos/alleleDBlogo${theme.mode === 'light' ? '' : 'white'}.png`}
          width={250}
          alt='search'
        />
        {step === 2 && (
          <div className='d-flex  my-5'>
            <button
              className='btn btn-primary'
              onClick={() => {
                setQueryResponse([])
                setVariantHeaderResponse({})
                setVariantResponse([])
                setStep(1)
              }}
            >
              Back to Search
            </button>
          </div>
        )}
        {step === 3 && (
          <div className='d-flex  my-5'>
            <button
              className='btn btn-primary'
              onClick={() => {
                if (queryResponse.length > 0) {
                  setStep(2)
                } else {
                  setQueryResponse([])
                  setVariantHeaderResponse({})
                  setVariantResponse([])
                  setStep(1)
                }
              }}
            >
              Back
            </button>
          </div>
        )}

        {step === 1 && (
          <div className='search-form-area col-md-6' style={{margin: '0 auto'}}>
            <div className='search-inputs row mt-5'>
              {/* <div className='dropdown col-md-2' style={{padding: 0}}>
        <select
          className='form-select   geneslist-select-list  form-select-solid  w-100 rounded-0'
          onChange={(e) => setSelectedDropdown(e.target.value)}
        >
          <option>Gene</option>
          <option>Variant</option>
          <option>Region</option>
        </select>
      </div> */}
              <div className='dropdown col-md-10' style={{padding: 0}}>
                <input
                  type='text'
                  className='form-control form-control-solid rounded-0'
                  placeholder={`Search by gene, region, or variant`}
                  onChange={(e) => setInputText(e.target.value)}
                />
              </div>
              <div className='dropdown col-md-2' style={{padding: 0}}>
                <button
                  className='btn btn-primary rounded-0'
                  onClick={() => handleSubmitAFB(inputText, selectedDropdown)}
                >
                  Search
                </button>
              </div>
              <label className='mt-2 cursor-pointer p-0' onClick={() => setShowFilter(!showFilter)}>
                Filter <i className='fa mx-2  fa-chevron-down cursor-pointer'></i>
              </label>
              <div
                className={`filter-afb ${showFilter ? 'visible' : 'invisible'}`}
                style={{
                  maxHeight: showFilter ? '100%' : '0',
                }}
              >
                <div className='type-selector mt-5 '>
                  {/* <button
            className={`btn btn-white ${
              selectedType === 0 ? 'border border-success' : ''
            }  rounded-0`}
            onClick={() => setSelectedType(0)}
          >
            Somatic
          </button> */}
                  <button
                    className={`btn btn-white ${
                      selectedType === 1 ? 'border border-success' : ''
                    }  rounded-0`}
                    onClick={() => setSelectedType(1)}
                  >
                    Germline
                  </button>
                  <div className=' d-flex justify-content-start mt-5 px-0'>
                    <label className='form-label fs-3 fw-bold mx-3 my-0'> Wide</label>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='runIsSolved'
                        onChange={(e) => {
                          setQueryStrategy(e.target.checked ? 'narrow' : 'wide')
                        }}
                        name='notifications'
                      />
                    </div>
                    <label className='form-label fs-3 fw-bold mx-3 my-0'> Narrow</label>
                  </div>
                </div>
                <div className='type-selector mt-5'>
                  <div className='phenotype-filter-input d-flex align-items-center'>
                    <label
                      className='cursor-pointer col-md-3'
                      onClick={() => setPhenoTypeFilterIsActive(!phenoTypeFilterIsActive)}
                    >
                      Search Phenotype
                    </label>
                    <div className='filter-phenotype-input col-md-9 position-relative'>
                      <input
                        type='text'
                        className='form-control form-control-solid w-100'
                        placeholder={'Search Hpo Terms '}
                        value={hpoTerms}
                        onChange={hpoChange}
                        onFocus={() => {
                          setSearchHpo(true)
                          getData()
                        }}
                      />
                      <div className='searchGeneWrap '>
                        {!!searchHpo && (
                          <i
                            className={`clearSearchGeneInput cursor-pointer fa fa-remove`}
                            onClick={() => hideSearchHPO()}
                          ></i>
                        )}

                        {(searchHpo || hpoTerms !== '') && (
                          <div
                            className='searchResultsWrap'
                            style={{
                              right: '0',
                              width: '100%',
                            }}
                          >
                            {hpoList.length > 0 && (
                              <ul className='list-group'>
                                {hpoList.map((item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className='list-group-item'
                                      style={{
                                        cursor: 'unset',
                                      }}
                                    >
                                      {item.name} : <b style={{fontSize: 10}}>{item.id}</b>
                                      <span
                                        style={{
                                          float: 'right',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          hideSearchHPO()
                                          addHpoToPhenotypeList(item.id, item.name)
                                        }}
                                      >
                                        <i
                                          className='fa fa-plus'
                                          style={{
                                            color: '#e93645',
                                          }}
                                        ></i>{' '}
                                      </span>
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className='selected-phenotype mt-3 d-flex flex-column text-white'
                    style={{
                      maxHeight: '250px',
                      overflowY: 'auto',
                    }}
                  >
                    <label>Selected Phenotypes</label> <br />
                    {phenoTypeFilters.map((item) => (
                      <span key={item.id} className='alert bg-success'>
                        <span
                          onClick={() => {
                            setPhenoTypeFilters((prevFilters) => {
                              return prevFilters.filter((filter) => filter.id !== item.id)
                            })
                          }}
                        >
                          <i
                            className='fa fa-trash mx-3'
                            style={{
                              color: 'white',
                              cursor: 'pointer',
                            }}
                          ></i>
                        </span>
                        <a
                          href={`https://hpo.jax.org/browse/term/${item.id}`}
                          target='_BLANK'
                          rel='noreferrer'
                          className='text-white'
                        >
                          {item.name}
                        </a>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className='example-search mt-5'>
                <label>
                  <b> Examples</b>
                </label>
                <ul>
                  <li>Gene: BRD2</li>
                  <li>Transcript: ENST00000374825</li>
                  <li>Variant: 1-55051215-G-GA</li>
                  <li>Structural variant region: 19-11078371-11144910</li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <AlleleFreqTableComponent
            gridId={'afbTable'}
            tableHeader={afbHeader}
            data={queryResponse}
            gridOptions={gridOptions}
            handleSubmitVariant={handleSubmitVariant}
            setGridOptions={setGridOptions}
          />
        )}
        {step === 3 && (
          <>
            <div className='variant-header'>
              <h3>
                Variant <br />
                <br /> {variantHeaderResponse.variantId}
              </h3>
              <ul className='list-group list-group-flush mt-3'>
                <li className='list-group-item'> RS Id : {variantHeaderResponse.annots.rsid} </li>
                <li className='list-group-item'>
                  Vep Annotation :
                  {variantHeaderResponse.annots.vepAnnotation.map((item) => item + ', ')}
                </li>
                <li className='list-group-item'>
                  Vep Clinical Significance :
                  {variantHeaderResponse.annots.vepClinicalSignificance.map((item) => item + ', ')}
                </li>
                <li className='list-group-item'>
                  Genes : {variantHeaderResponse.annots.geneName.map((item) => item + ', ')}
                </li>
              </ul>
            </div>
            <AlleleFreqTableComponent
              gridId={'afbTableVariant'}
              tableHeader={afbVariantHeader}
              data={variantResponse}
              hasFilter={false}
              gridOptions={gridOptions}
              handleSubmitVariant={handleSubmitVariant}
              setGridOptions={setGridOptions}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default AlleleFreqPage
