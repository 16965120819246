/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import Transmit from '../../components/NewRun/Transmit'

import {useState} from 'react'
import WithDataTransmitNew from '../../components/NewRun/WithDataTransmitNew'

const WithDataTransmitPageNew = () => {
  const intl = useIntl()

  const [isTransferCompleted, setIsTransferCompleted] = useState(false)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.NEWRUN'})}</PageTitle>
      <div className='row'>
        <div className='card card-custom'>
          <div className='card-body'>
              <WithDataTransmitNew setIsTransferCompleted={setIsTransferCompleted} />
          </div>
        </div>
      </div>
    </>
  )
}

export {WithDataTransmitPageNew}
