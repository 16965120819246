/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {MixedWidget1} from '../../_metronic/partials/widgets' 
import { Link } from 'react-router-dom';
type Props = {
  runData: any 
}
const NotCompleted: React.FC<Props> = ({runData}) => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'RUN.DETAIL'})}</PageTitle>
      <div className='row'>
            <div className='col-xl-12'>
              <Link className='btn btn-primary mb-2' to='/runs'>Back</Link>
              {!!runData && <MixedWidget1  runData={runData}  className='card-xl-stretch mb-xl-8'  />}
 
            </div>
      </div>
    </>
  )
}

export {NotCompleted}
