import React, {useEffect} from 'react'
import FileInput from '../FileInput'

const RnaseqForm = ({sampleData, setSampleData, selectedProcessType}) => {
  useEffect(() => {
    if (sampleData.length === 0) {
      setSampleData([
        {
          id: '',
          name: '',
          fqR1: '',
          fqR2: '',
          regionFile: null,
          files: {},
        },
      ])
    }
  }, [sampleData])

  return (
    <div>
      <button
        className='btn btn-primary mb-1'
        disabled={true}

        onClick={() =>
          setSampleData((prev) => [
            ...prev,
            {
              id: '',
              name: '',
              fqR1: '',
              fqR2: '',
              regionFile: null,
              files: {},
            },
          ])
        }
      >
        +
      </button>

      {sampleData.map((sample, index) => (
        <div className='border mt-2 p-2'>
          {index > 0 && (
            <button
              className='btn btn-primary mb-1 pull-right'
              onClick={() => {
                const cloned = [...sampleData]
                cloned.splice(index, 1)
                setSampleData(cloned)
              }}
            >
              X
            </button>
          )}
          <input
            className='form-control mb-4'
            placeholder='Sample ID'
            value={sample.id}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].id = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <input
            className='form-control mb-4'
            placeholder='Sample Name (Optional) '
            value={sample.name}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].name = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <FileInput
            label={'Upload FASTQ R1 File'}
            onchange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].files.fqR1 = e.target.files[0]
              setSampleData(clonedSampleData)
            }}
          />
          <FileInput
            label={'Upload FASTQ R2 File'}
            onchange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].files.fqR2 = e.target.files[0]
              setSampleData(clonedSampleData)
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default RnaseqForm
