/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {TagsInput} from 'react-tag-input-component'
import runsService from '../../service/runs'
import PhenotypeModal from './PhenotypeModal'
import CohortModal from './CohortModal'

const SampleInformation = ({
  id,
  sampleId,
  sampleName,
  project,
  refferingPhysicians,
  analysts,
  submissionDate,
  phenoTypes,
  cohorts,
  runId,
  isSolved,
}) => {
  const intl = useIntl()
  const [runIsSolved, setRunIsSolved] = React.useState(isSolved)
  const [refferingPhysState, setRefferingPhysState] = useState(refferingPhysicians)
  const [analystsState, setAnalystsState] = useState(analysts)
  const [analystsKeyUpState, setAnalystsKeyUpState] = useState('')
  const [refferingKeyUpState, setRefferingKeyUpState] = useState('')
  const [phenotypesKeyUpState, setPhenotypesKeyUpState] = useState('')
  const [phenotypesState, setPhenotypesState] = useState(phenoTypes)

  const [isRefferingEditing, setIsRefferingEditing] = useState(false)
  const [isAnalystEditing, setIsAnalystEditing] = useState(false)

  const changeCheck = () => {
    runsService.updateRunIsSolved(runIsSolved, runId)
    setRunIsSolved(!runIsSolved)
  }

  const handleRefferingClick = () => {
    setIsRefferingEditing(!isRefferingEditing)
  }
  useEffect(() => {
    setPhenotypesKeyUpState(phenoTypes.join('\n'))
  }, [])
  useEffect(() => {
    setPhenotypesKeyUpState(phenotypesState.join('\n'))
  }, [phenotypesState])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveRefferingEdit = () => {
    // !!refferingKeyUpState && setRefferingPhysState((prev) => [...prev, refferingKeyUpState])
    if (!!refferingKeyUpState) {
      refferingPhysState.push(refferingKeyUpState)
      let uniqueValues = [...new Set(refferingPhysState)]
      setRefferingPhysState(uniqueValues)
      // setRefferingPhysState((prev) => [...prev, refferingKeyUpState])
    }
    setRefferingKeyUpState('')
    isRefferingEditing && runsService.updateRunPhysician(refferingPhysState, runId, id)
    setIsRefferingEditing(false)
  }

  const handleAnalystClick = () => {
    setIsAnalystEditing(!isAnalystEditing)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAnalystEdit = () => {
    if (!!analystsKeyUpState) {
      analystsState.push(analystsKeyUpState)
      let uniqueValues = [...new Set(analystsState)]
      setAnalystsState(uniqueValues)
    }
    setAnalystsKeyUpState('')
    isAnalystEditing && runsService.updateRunAnalyst(analystsState, runId, id)
    setIsAnalystEditing(false)
  }
  const savePhenotypes = (phenotypes) => {
    setPhenotypesState(phenotypes)
    runsService.updateRunPhenotypes(phenotypes, runId, id)
  }

  const physicialRef = useRef(null)
  const analystsRef = useRef(null)

  // useClickOutside(physicialRef, () => saveRefferingEdit())
  // useClickOutside(analystsRef, () => saveAnalystEdit())
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (physicialRef.current && !physicialRef.current.contains(event.target)) {
        saveRefferingEdit()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isRefferingEditing, refferingPhysState, refferingKeyUpState])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (analystsRef.current && !analystsRef.current.contains(event.target)) {
        saveAnalystEdit()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isAnalystEditing, analystsState, analystsKeyUpState])

  const formatDate = new Date(submissionDate)

  const formattedDate = formatDate.toLocaleString('en-EN', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  return (
    // Sample Information Card Start
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>
            {intl.formatMessage({id: 'SAMPLEINFORMATION'})}
          </span>
        </h3>
      </div>
      <div className='card-body pt-0 px-2'>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>Sample ID :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{sampleId}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Sample Name :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{sampleName}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>Project :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{project}</div>
          </div>
        </div>
        <div ref={physicialRef} className='timeline-label my-4'>
          <div className='timeline-item '>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Referring Physician(s) :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span>
                {[...new Set(refferingPhysState?.slice(0, 50))].join(',')}{' '}
                <img
                  src='/media/icons/edit-icon.svg'
                  width={15}
                  onClick={handleRefferingClick}
                  className='edit-icon cursor-pointer'
                  height={15}
                  alt='Edit Referring'
                />
              </span>
            </div>
          </div>
          {isRefferingEditing && (
            <div>
              <TagsInput
                name='refferingPhys'
                onKeyUp={(e) => {
                  setRefferingKeyUpState(e?.target?.value)
                }}
                value={refferingPhysState}
                onChange={setRefferingPhysState}
                placeHolder='Enter Physician'
              />

              <button className='btn btn-primary my-2' onClick={saveRefferingEdit}>
                Save
              </button>
            </div>
          )}
        </div>
        <div ref={analystsRef} className='timeline-label my-4'>
          <div className='timeline-item '>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Analyst(s) :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span>{[...new Set(analystsState?.slice(0, 50))].join(',')} </span>

              <img
                src='/media/icons/edit-icon.svg'
                width={15}
                onClick={handleAnalystClick}
                className='edit-icon cursor-pointer'
                height={15}
                alt='Edit Analysts'
              />
            </div>
          </div>
          {isAnalystEditing && (
            <div>
              <TagsInput
                name='analysts'
                value={analystsState}
                onChange={setAnalystsState}
                onKeyUp={(e) => {
                  setAnalystsKeyUpState(e?.target?.value)
                }}
                placeHolder='Enter Analyst'
              />
              <button className='btn btn-primary my-2' onClick={saveAnalystEdit}>
                Save
              </button>
            </div>
          )}
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Submission Date :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{formattedDate}</div>
          </div>
        </div>
        <div
          className='timeline-label my-4 cursor-pointer'
          data-bs-toggle='modal'
          data-bs-target='#PhenotypeModal'
        >
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Phenotypes : {phenotypesState.length < 1 && <i className='fa fa-edit'></i>}
            </div>

            {phenotypesState.length > 0 && (
              <div className='fw-mormal timeline-content text-muted  w-100 '>
                <ul className='phenotypes-preview '>
                  {phenotypesState.map((item, index) => {
                    return <li key={index}> {item} </li>
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
        {/* <div
          className='timeline-label my-4 cursor-pointer'
          data-bs-toggle='modal'
          data-bs-target='#CohortsModal'
        >
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Cohorts : {cohorts.length < 1 && <i className='fa fa-edit'></i>}
            </div>

            {cohorts.length > 0 && (
              <div className='fw-mormal timeline-content text-muted  w-100 '>
                <ul className='phenotypes-preview '>
                  {cohorts.map((item, index) => {
                    return <li key={index}> {item} </li>
                  })}
                </ul>
              </div>
            )}
          </div>
        </div> */}
      </div>
      <div className='card-footer d-flex justify-content-end px-0'>
        <label className='form-label fs-3 fw-bold mx-3 my-0'>
          {runIsSolved ? ' ' : 'Not '} Solved
        </label>

        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            value='runIsSolved'
            name='notifications'
            defaultChecked={runIsSolved}
            onChange={() => {
              changeCheck()
            }}
          />
        </div>
      </div>
      <PhenotypeModal
        phenotypesKeyUpState={phenotypesKeyUpState}
        setPhenotypesKeyUpState={setPhenotypesKeyUpState}
        savePhenotypes={savePhenotypes}
        phenoTypes={phenoTypes}
        phenotypesState={phenotypesState}
        setPhenotypesState={setPhenotypesState}
      />
      <CohortModal cohorts={cohorts} />
    </div>
    // Sample Information Card End
  )
}

export {SampleInformation}
