/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import UsersListTable from '../../components/UsersList/UsersListTable'

const UserListPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.USERSLIST'})}</PageTitle>
      <UsersListTable/>
    </>
  )
}

export {UserListPage}
