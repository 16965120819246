import React, {useEffect} from 'react'
import FileInput from '../FileInput'
import OptionsSelect from '../OptionsSelect'

const SomTestTumorRnaForm = ({sampleData, setSampleData, selectedProcessType, fileType}) => {
  useEffect(() => {
    if (sampleData.length === 0) {
      setSampleData([
        {
          id: '',
          name: '',
          fqR1: '',
          fqR2: '',
          regionFile: null,
          sampleMeta: {},
          files: {},
        },
      ])
    }
  }, [sampleData])

  var clinicalDiagnosisArray = [
    'Undefined',
    'B-Lymphoblastic Leukemia/Lymphoma',
    'Chronic Myelogenous Leukemia',
    'Anaplastic Large-Cell Lymphoma ALK Positive',
    'Inflammatory Myofibroblastic Tumor',
    'Non-Small Cell Lung Cancer',
    'Prostate Cancer, NOS, Prostate Cancer',
    'Erdheim-Chester Disease',
    'Melanoma',
    'All Solid Tumors (excluding Colorectal Cancer)',
    'Anaplastic Thyroid Cancer',
    'Biliary Tract Cancer, NOS',
    'Colorectal Cancer',
    'Ovarian Cancer, Ovary/Fallopian Tube, Peritoneal Serous Carcinoma',
    'Breast Cancer',
    'Esophagogastric Cancer',
    'Follicular Lymphoma',
    'Myeloid/Lymphoid Neoplasms with Eosinophilia and Rearrangement of PDGFRA/PDGFRB or FGFR1 or with PCM1-JAK2',
    'Bladder Cancer',
    'Cholangiocarcinoma',
    'Acute Myeloid Leukemia',
    'Intrahepatic Cholangiocarcinoma, Cholangiocarcinoma',
    'Mastocytosis',
    'Gastrointestinal Stromal Tumor',
    'Neurofibroma',
    'All Solid Tumors',
    'Endometrial Cancer',
    'Dermatofibrosarcoma Protuberans',
    'Chronic Eosinophilic Leukemia, NOS',
    'Myelodysplastic/Myeloproliferative Neoplasms',
    'APL with PML-RARA',
    'All Solid Tumors (excluding Thyroid Cancer, Non-Small Cell Lung Cancer)',
    'Thyroid Cancer',
    'Medullary Thyroid Cancer',
    'Epithelioid Sarcoma',
    'Encapsulated Glioma',
    'Chronic Lymphocytic Leukemia/Small Lymphocytic Lymphoma',
    'Langerhans Cell Histiocytosis',
    'Rosai-Dorfman Disease',
    'Pilocytic Astrocytoma',
    'Diffuse Glioma',
    'Hairy Cell Leukemia',
    'Pleomorphic Xanthoastrocytoma, Pilocytic Astrocytoma, Ganglioglioma',
    'Pancreatic Adenocarcinoma, Acinar Cell Carcinoma of the Pancreas',
    'Uterine Sarcoma',
    'Uterine Serous Carcinoma/Uterine Papillary Serous Carcinoma',
    'Chondrosarcoma',
    'Oligodendroglioma',
    'Pancreatic Adenocarcinoma',
    'Ovarian Cancer, Endometrial Cancer',
    'Histiocytosis',
    'Ovarian Cancer',
    'Lung Squamous Cell Carcinoma',
    'Bladder Urothelial Carcinoma',
    'Head and Neck Squamous Cell Carcinoma',
    'Glioma (excluding Oligodendroglioma)',
    'Oligodendroglioma, Astrocytoma',
    'Hepatobiliary Cancer, Tubular Adenoma of the Colon, Esophagogastric Cancer, Anal Cancer, Small Bowel Cancer, Gastrointestinal Neuroendocrine Tumor',
    'Melanoma, Non-Small Cell Lung Cancer, Low-Grade Serous Ovarian Cancer',
    'Renal Cell Carcinoma',
    'Medulloblastoma',
    'Perivascular Epithelioid Cell Tumor, Uterine Perivascular Epithelioid Cell Tumor',
    'Dedifferentiated Liposarcoma, Well-Differentiated Liposarcoma',
    'Glioma',
    'All Solid Tumors (excluding Cholangiocarcinoma)',
    'Ewing Sarcoma, Ewing Sarcoma of Soft Tissue',
    'Chronic Myelomonocytic Leukemia',
    'Myelodysplastic Syndromes',
    'All Liquid Tumors',
    'AML with BCR-ABL1',
    'B-Lymphoblastic Leukemia/Lymphoma with t(9;22)(q34.1;q11.2);BCR-ABL1',
    'Chronic Myeloid Leukemia, BCR-ABL1+',
    'Mixed Phenotype Acute Leukemia with t(9;22)(q34.1;q11.2); BCR-ABL1',
    'B-Lymphoblastic Leukemia/Lymphoma, BCR-ABL1 Like',
    'ALK Positive Large B-Cell Lymphoma',
    'AML with inv(16)(p13.1q22) or t(16;16)(p13.1;q22);CBFB-MYH11',
    'AML with Biallelic Mutations of CEBPA',
    'AML with t(6;9)(p23;q34.1);DEK-NUP214',
    'Myeloid/Lymphoid Neoplasms with FGFR1 Rearrangement',
    'B-Lymphoblastic Leukemia/Lymphoma with t(5;14)(q31.1;q32.3) IL3-IGH',
    'Large B-Cell Lymphoma with IRF4 Rearrangement',
    'Myeloid/Lymphoid Neoplasms with PCM1-JAK2',
    'B-Lymphoblastic Leukemia/Lymphoma with t(v;11q23.3);KMT2A Rearranged',
    'Mixed Phenotype Acute Leukemia with t(v;11q23.3); KMT2A Rearranged',
    'AML with t(9;11)(p21.3;q23.3);MLLT3-KMT2A',
    'High-Grade B-Cell Lymphoma, with MYC and BCL2 and/or BCL6 Rearrangements',
    'AML with Mutated NPM1',
    'Myeloid/Lymphoid Neoplasms with PDGFRA Rearrangement',
    'Myeloid/Lymphoid Neoplasms with PDGFRB Rearrangement',
    'AML (megakaryoblastic) with t(1;22)(p13.3;q13.3);RBM15-MKL1',
    'B-Lymphoblastic Leukemia/Lymphoma with t(12;21)(p13.2;q22.1); ETV6-RUNX1',
    'AML with Mutated RUNX1',
    'AML with t(8;21)(q22;q22.1);RUNX1-RUNX1T1',
    'B-Lymphoblastic Leukemia/Lymphoma with t(1;19)(q23;p13.3);TCF3-PBX1',
    'Essential Thrombocythemia',
    'MDS/MPN with Ring Sideroblasts and Thrombocytosis',
    'Myeloproliferative Neoplasms',
    'Primary Myelofibrosis',
    'Juvenile Myelomonocytic Leukemia',
    'Mantle Cell Lymphoma',
    'T-Lymphoblastic Leukemia/Lymphoma',
    'Chronic Neutrophilic Leukemia',
    'Atypical Chronic Myeloid Leukemia, BCR-ABL1-',
    'Myeloid Proliferations Related to Down Syndrome',
    'Polycythemia Vera',
    'Systemic Mastocytosis',
    'Myeloid Sarcoma',
    'Burkitt Lymphoma',
    'Lymphoplasmacytic Lymphoma',
    'MDS with Ring Sideroblasts',
    'T-Cell Prolymphocytic Leukemia',
    'AML with Myelodysplasia-Related Changes',
    'Extranodal Marginal Zone Lymphoma of Mucosa-Associated Lymphoid Tissue (MALT lymphoma)',
    'Diffuse Large B-Cell Lymphoma, NOS',
    'Early T-Cell Precursor Lymphoblastic Leukemia',
    'Classical Hodgkin Lymphoma',
    'Primary Mediastinal (Thymic) Large B-Cell Lymphoma',
    'Angioimmunoblastic T-Cell Lymphoma',
    'Therapy-Related Myeloid Neoplasms',
    'Anaplastic Large-Cell Lymphoma ALK Negative',
    'Primary Cutaneous DLBCL, Leg Type',
    'Splenic Marginal Zone Lymphoma',
    'Primary DLBCL of the central nervous system',
    'T-Cell Large Granular Lymphocytic Leukemia',
    'Acute Leukemias of Ambiguous Lineage',
    'Plasma Cell Myeloma',
  ]
  var specimenTypeArray = [
    'Undefined',
    'Formalin-Fixed, Paraffin-Embedded (FFPE)',
    'Fresh Frozen Tissue',
    'Fine Needle Aspiration (FNA)',
    'Core Biopsy Samples',
    'Circulating Tumor DNA (ctDNA) from Blood',
    'Pleural Effusions or Ascites',
    'Peripheral blood',
    'Bone Marrow Aspirate',
    'Cell-Free DNA (cfDNA) from Body Fluids',
    'Cell Pellets from Cultured Tumor Cells',
    'Other',
  ]
  var specimenSiteArray = [
    'Undefined',
    'Breast',
    'Lung',
    'Gastrointestinal Tract',
    'Genitourinary System',
    'Head and Neck',
    'Central Nervous System',
    'Hematologic',
    'Skin',
    'Musculoskeletal System',
    'Endocrine System',
    'Pleural and Peritoneal Cavities',
    'Heart and Great Vessels',
    'Others',
  ]
  return (
    <div>
      <button
        className='btn btn-primary mb-1'
        disabled={true}
        onClick={() =>
          setSampleData((prev) => [
            ...prev,
            {
              id: '',
              name: '',
              fqR1: '',
              fqR2: '',
              regionFile: null,
              sampleMeta: {},
              files: {},
            },
          ])
        }
      >
        +
      </button>

      {sampleData.map((sample, index) => (
        <div className='card  mt-5 p-2'>
          {index > 0 && (
            <button
              className='btn btn-primary mb-1 pull-right'
              onClick={() => {
                const cloned = [...sampleData]
                cloned.splice(index, 1)
                setSampleData(cloned)
              }}
            >
              X
            </button>
          )}
          <input
            className='form-control mb-4'
            placeholder='Sample ID'
            value={sample.id}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].id = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <input
            className='form-control mb-4'
            placeholder='Sample Name (Optional) '
            value={sample.name}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].name = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <label className='input-label '> Clinical Diagnosis</label>
          <select
            className='form-select   w-100 mb-4'
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].sampleMeta.clinicalDiagnosis = e.target.value
              setSampleData(clonedSampleData)
            }}
          >
            <option disabled selected>
              {' '}
            </option>

            {clinicalDiagnosisArray
              .sort((a, b) => a.localeCompare(b))
              .map((item) => {
                return <option key={item}>{item}</option>
              })}
          </select>
          <div className='row'>
            <div className='col-md-6'>
              <label className='input-label '> Specimen Type</label>
              <select
                className='form-select my-2   w-100'
                onChange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].sampleMeta.specimenType = e.target.value
                  setSampleData(clonedSampleData)
                }}
              >
                <option disabled selected>
                  {' '}
                </option>

                {specimenTypeArray
                  .sort((a, b) => a.localeCompare(b))
                  .map((item) => {
                    return <option key={item}>{item}</option>
                  })}
              </select>
            </div>
            <div className='col-md-6'>
              <label className='input-label '> Specimen Site</label>
              <select
                className='form-select  my-2  '
                onChange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].sampleMeta.specimenSite = e.target.value
                  setSampleData(clonedSampleData)
                }}
              >
                <option disabled selected>
                  {' '}
                </option>
                {specimenSiteArray
                  .sort((a, b) => a.localeCompare(b))
                  .map((item) => {
                    return <option key={item}>{item}</option>
                  })}
              </select>
            </div>
          </div>
          {fileType === 'fastq' && (
            <>
              <FileInput
                label={'Upload FASTQ R1 File (DNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR1 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File (DNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R1 File (RNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR1RNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File (RNA) '}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2RNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (DNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (RNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFileRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}

          {fileType === 'bam' && (
            <>
              <FileInput
                label={'Upload BAM File (DNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bam = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload BAM File (RNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bamRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (DNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File (RNA)'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFileRNA = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default SomTestTumorRnaForm
