/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {Link, useParams} from 'react-router-dom'
import {RunInformation} from '../../components/RunDetail/RunInformation'
import {Comments} from '../../components/RunDetail/Comments'
import {GeneList} from '../../components/RunDetail/GeneList'
import {kFormatter} from '../../utils'
import {OverView} from '../../components/NewRunDetail/OverView'
import {NewQcSummary} from '../../components/NewRunDetail/NewQcSummary'
import {NewSampleInformation} from '../../components/NewRunDetail/NewSampleInformation'
import {NewVaritanFiltering} from '../../components/NewRunDetail/NewVariantFiltering'
import runsService from '../../service/runs'
import {useEffect, useState} from 'react'
import {Loader} from '../../components/Loader'
// import somaticService from '../../service/somatic'
import {NotCompleted} from '../../components/RunDetail/NotCompleted'

const SomaticRunDetailPage = () => {
  const intl = useIntl()
  const {id} = useParams()
  const [runData, setRunData] = useState({})
  const [somaticData, setSomaticData] = useState({})
  const [familyFilterGeneList, setFamilyFilterGeneList] = useState()
  const [previewStr, setPreviewStr] = useState('')
  const [previewList, setPreviewList] = useState([])
  const [clickedHPOItems, setClickedHPOItems] = useState([
    {
      id: '1',
      name: ' Add more genes',
      list: [],
      listStr: '',
    },
  ])
  const [selectedHPOItem, setSelectedHPOItem] = useState({})
  const [geneSymbolListStr, setGeneSymbolListStr] = useState('')
  const [selectedGeneList, setSelectedGeneList] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const getData = async () => {
    const getRunDetail = await runsService.getRunDetail(id)
    if (getRunDetail.status === 200) {
      setRunData(getRunDetail.data)
      setSomaticData(getRunDetail.data.samples[0].somaticInfo)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const checkData = (data) => {
    if (data) {
      return data
    } else if (data === 0) {
      return 0.0
    } else {
      return 'N/A'
    }
  }
  if (isLoading) {
    return (
      <div className='text-center'>
        <Loader />
      </div>
    )
  }
  if (somaticData?.msiTotalNumberOfSites > 230) {
    var msiLevel = somaticData?.msiTotalNumberOfSites + ', ' + somaticData?.msiValue
  } else {
    var msiLevel = 'Undeterminable'
  }
  if (somaticData?.tmbValue > 200) {
    // var tmbLevel = somaticData?.tmbValue.toFixed(2) + ' Muts/MB High'
    var tmbLevel = somaticData?.tmbValue.toFixed(2) + ' Muts/MB '
  } else {
    var tmbLevel = somaticData?.tmbValue.toFixed(2) + ' Muts/MB '
  }

  if (runData?.status !== 'Completed') {
    return (
      <>
        <NotCompleted runData={runData} />
      </>
    )
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'RUN.DETAIL'})}</PageTitle>
      <div className='row'>
        <div className='col-xl-12'>
          <Link className='btn btn-primary mb-2' to='/runs'>
            Back
          </Link>

          <div className='my-2 runDetailHeader'>
            <NewSampleInformation
              runId={runData.id}
              id={runData?.samples[0].id}
              sampleId={runData?.samples[0].sampleId}
              sampleName={runData?.samples[0].sampleName}
              clinicalDiagnosis={runData?.samples[0].clinicalDiagnosis}
              specimenType={runData?.samples[0].specimenType}
              specimenSite={runData?.samples[0].specimenSite}
              isSolved={runData?.isSolved}
              refferingPhysicians={
                runData?.samples[0].physicians ? runData?.samples[0].physicians : []
              }
              analysts={runData?.samples[0].analysts ? runData?.samples[0].analysts : []}
              phenoTypes={runData?.samples[0].phenotypes ? runData?.samples[0].phenotypes : []}
              cohorts={runData?.samples[0].cohorts ? runData?.samples[0].cohorts : []}
              submissionDate={runData?.submissionDate}
            />
            <RunInformation
              processType={runData?.processType}
              enrichmenKit={checkData(runData?.enrichmentKit)}
              currentNode={runData?.onNode}
              currentRunId={id}
              pipelineVersion={'2.1.1'}
              genomeBuild={runData?.genomeBuild}
              runStatus={runData?.status}
            />
            <NewQcSummary
              roh={checkData(runData?.samples[0]?.sampleQuality.roh)}
              totalReads={checkData(kFormatter(runData?.samples[0]?.sampleQuality.totalReads))}
              meanCoverage={checkData(runData?.samples[0]?.sampleQuality.meanCoverage)}
              medianCoverage={checkData(runData?.samples[0]?.sampleQuality.medianCoverage)}
              gender={checkData(runData?.samples[0]?.sampleQuality.gender)}
              sampleId={runData?.samples[0].sampleId}
              currentNode={runData?.onNode}
              processType={runData?.processType}
              runId={id}
              contamination={checkData(runData?.samples[0]?.sampleQuality.contamination)}
              coverage={
                runData?.samples[0]?.sampleQuality.filter(
                  (item) => item.dataSourceType === 'dna'
                )[0]
              }
              rnaCoverage={
                runData?.samples[0]?.sampleQuality.filter(
                  (item) => item.dataSourceType === 'rna'
                )[0]
              }
              readLenght={checkData(runData?.samples[0]?.sampleQuality.avgReadLength)}
            />
            <OverView
              // tmbLevel={'25.22 Muts/MB High'}
              // msiLevel={'Stable (MSS)'}
              processType={runData.processType}
              tmbLevel={tmbLevel}
              msiLevel={msiLevel}
              loh={somaticData?.lohpercantage ? somaticData?.lohpercantage : ' N/A '}
            />
          </div>
          <div className='my-4 runDetailBody'>
            <GeneList
              currentRunId={runData?.id}
              clickedHPOItems={clickedHPOItems}
              setClickedHPOItems={setClickedHPOItems}
              selectedHPOItem={selectedHPOItem}
              setSelectedHPOItem={setSelectedHPOItem}
              geneSymbolListStr={geneSymbolListStr}
              previewStr={previewStr}
              setPreviewStr={setPreviewStr}
              previewList={previewList}
              setPreviewList={setPreviewList}
              setGeneSymbolListStr={setGeneSymbolListStr}
              setFamilyFilterGeneList={setFamilyFilterGeneList}
              selectedGeneList={selectedGeneList}
              setSelectedGeneList={setSelectedGeneList}
            />
            <Comments runId={runData.id} comment={runData.comments} />
          </div>
          <div className='my-4 runDetailFooter'>
            <NewVaritanFiltering
              processType={runData.processType}
              currentRunId={runData?.id}
              sampleId={runData?.samples[0].id}
              clickedHPOItems={clickedHPOItems}
              selectedHPOItem={selectedHPOItem}
              previewStr={previewStr}
              geneSymbolListStr={geneSymbolListStr}
              selectedGeneList={selectedGeneList}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export {SomaticRunDetailPage}
