/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useAuth} from '../../app/modules/auth'
import { KTIcon } from '../../_metronic/helpers'
import { Card4 } from '../../_metronic/partials/content/cards/Card4'

const QcReport = () => {
  return (
    <>
    <div className='card'>
       <iframe src={`http://localhost:3000/dashboard`} width="100%" title='Qc Report' height="600px"/>
    </div>
 
  </>
  )
}

export {QcReport}
