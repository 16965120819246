export async function alter_entries(sv_array) {
  var result_array = []
  var entry = null
  var sampleID = sv_array[0].Samples_ID
  for (const l of sv_array) {
    ;[l.Hom, l.Callers] = l[sampleID].split(':')
    l.SV_chrom = 'chr' + l.SV_chrom
    l.SV_start = parseInt(l.SV_start)
    l.SV_end = parseInt(l.SV_end)
    l.SV_length = parseInt(l.SV_length)
    l.Gene_name = l.Gene_name.split(';')
    l.P_loss_phen = l.P_loss_phen.split(';')
    l.Callers = l.Callers.split(',')
    l.B_gain_AFmax = parseFloat(l.B_gain_AFmax)
    l.B_loss_AFmax = parseFloat(l.B_loss_AFmax)
    l.B_ins_AFmax = parseFloat(l.B_ins_AFmax)
    l.B_inv_AFmax = parseFloat(l.B_inv_AFmax)
    delete l[sampleID]
    entry = l
    result_array.push(entry)
  }
  return result_array
}

export function kFormatter(num) {
  return Intl.NumberFormat('en', {notation: 'compact'}).format(num)
}

export function downloadTSV(data, filename) {
  var blob = new Blob([data], {type: 'text/tab-separated-values'})
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var a = document.createElement('a')
    var url = URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}
export function convertJSONtoTSV(jsonData) {
  var tsvContent = Object.keys(jsonData[0]).join('\t') + '\n' // Başlık satırı
  jsonData.forEach(function (item) {
    var values = Object.values(item).map((value) => (value !== null ? String(value) : '')) // Null olan değerleri boş bırak
    tsvContent += values.join('\t') + '\n'
  })
  return tsvContent
}
