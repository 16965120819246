import {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'

const AleleChart = ({selectedRow, className, DP, AD, AF, allelicWidgetDatas, setSelectedRow}) => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()
  var colorArray = [
    '#E93645',
    '#71C6FE',
    '#6FF6C8',
    '#AD98F7',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ]
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, allelicWidgetDatas])
  const handleClick = (item) => {
  }
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, DP, allelicWidgetDatas, colorArray)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  var total = 0
  return (
    <div className={`m-10 card mx-10 h-auto`}>
      <h5 className='m-4'> Allelic Fraction</h5>
      <div className='col-md-12 row mb-5'>
        <div className='col-md-5'>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '125px'}} />
        </div>
        <div className='col-md-7'>
          {allelicWidgetDatas?.map((item, index) => {
            total += parseInt(item.AD)
            return (
              <div
                className='align-items-center fs-9'
                key={index}
                onClick={() => handleClick(item)}
              >
                <div className='d-flex align-items-center'>
                  <span className='circle mx-2' style={{background: colorArray[index]}}></span>
                  <span className='drawer-variant-summary'>
                    {item.Chrom}:{item.Start}_{item.Ref}{' '}
                    <label dangerouslySetInnerHTML={{__html: '>'}} /> {item.Alt}
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <span
                    className='circle mx-2'
                    style={{background: colorArray[index], visibility: 'hidden'}}
                  ></span>
                  <label className='fs-9'>
                    {' '}
                    Allele Fraction<br></br> {item.AF}{' '}
                  </label>{' '}
                  <label className='fs-9 mx-1'>
                    {' '}
                    Allele Count<br></br> {item.AD}{' '}
                  </label>
                </div>
              </div>
            )
          })}
          <div className='d-flex align-items-center fs-9 mt-3'>
            <span className='circle mx-2' style={{background: '#bcbec2'}}></span>
            Reference : {parseInt(DP) - parseInt(total)}
          </div>
          <div className='d-flex align-items-center fs-9'>
            <span className='circle mx-2 invisible' style={{background: '#bcbec2'}}></span>
            Read Depth : {DP}
          </div>
        </div>
      </div>
    </div>
  )
}

export {AleleChart}

function getChartOptions(height, DP, allelicWidgetDatas, colorArray) {
  var total = 0
  const labels = []
  const colors = []
  const chartSeries = allelicWidgetDatas.map((item, index) => {
    total += parseInt(item.AD)
    colors.push(colorArray[index])
    labels.push(``)
    return parseInt(item.AD)
  })
  labels.push('')
  colors.push('#bcbec2')
  chartSeries.push(parseInt(DP) - parseInt(total))

  return {
    series: chartSeries,
    labels: labels,

    chart: {
      fontFamily: 'inherit',
      type: 'pie',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
        },
      },
    },
    legend: {
      show: false,
      position: 'bottom',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    tooltip: {
      y: {
        formatter: (val, {series, seriesIndex, dataPointIndex, w}) => {
          if (dataPointIndex === allelicWidgetDatas.length) {
            return `Reference: ${val}`
          } else {
            const item = allelicWidgetDatas[dataPointIndex]
            return `<span style="font-style: italic;width: 50px;font-size:8px;" >${
              item.Chrom + '-' + item.Start
            } </span> <br> Allelic Fraction : ${item.AF}<br> Allele Count  : ${item.AD}`
          }
        },
      },
    },
    colors: colors,
  }
}
