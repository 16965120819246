import React from 'react'

const Loader = () => {
  return (
    <span className='indicator-progress' style={{display: 'block'}}>
      <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
    </span>
  )
}

export {Loader}
