import React, {useEffect} from 'react'
import FileInput from '../FileInput'

const TpsForm = ({sampleData, setSampleData, selectedProcessType, fileType}) => {
  useEffect(() => {
    if (sampleData.length === 0) {
      setSampleData([
        {
          id: '',
          name: '',
          fqR1: '',
          fqR2: '',
          regionFile: null,
          files: {},
        },
      ])
    }
  }, [sampleData])

  return (
    <div>
      <button
        className='btn btn-primary mb-1'
        disabled={true}
        onClick={() =>
          setSampleData((prev) => [
            ...prev,
            {
              id: '',
              name: '',
              fqR1: '',
              fqR2: '',
              regionFile: null,
              files: {},
            },
          ])
        }
      >
        +
      </button>

      {sampleData.map((sample, index) => (
        <div className='border mt-2 p-2'>
          {index > 0 && (
            <button
              className='btn btn-primary mb-1 pull-right'
              onClick={() => {
                const cloned = [...sampleData]
                cloned.splice(index, 1)
                setSampleData(cloned)
              }}
            >
              X
            </button>
          )}
          <input
            className='form-control mb-4'
            placeholder='Sample ID'
            value={sample.id}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].id = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <input
            className='form-control mb-4'
            placeholder='Sample Name (Optional) '
            value={sample.name}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].name = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          {fileType === 'fastq' && (
            <>
              <FileInput
                label={'Upload FASTQ R1 File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR1 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload FASTQ R2 File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.fqR2 = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {fileType === 'bam' && (
            <>
              <FileInput
                label={'Upload BAM File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.bam = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
          {fileType === 'vcf' && (
            <>
              <FileInput
                label={'Upload VCF'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.vcf = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
              <FileInput
                label={'Upload Regions File'}
                onchange={(e) => {
                  const clonedSampleData = [...sampleData]
                  clonedSampleData[index].files.regionsFile = e.target.files[0]
                  setSampleData(clonedSampleData)
                }}
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default TpsForm
