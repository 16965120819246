import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import NodeService from '../../service/node'
import {v4 as uuidv4} from 'uuid'
import Peer from 'simple-peer'
import {showSaveFilePicker} from 'native-file-system-adapter'

import FileManager from './FileManager'
import {io} from 'socket.io-client'
const FileDownloadModal = ({modalOpen, currentNode, currentRunId}) => {
  const transmitIdRef = useRef('')
  const [isConnection, setIsConnection] = useState(true)
  const [connectionIsFailed, setConnectionIsFailed] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [runFiles, setRunFiles] = useState([])
  const [selectedFile, setSelectedFile] = useState({
    name: '',
    size: '',
    path: '',
  })
  const [isProgress, setIsProgress] = useState(false)
  const [progressCount, setProgressCount] = useState(0)
  const [fileDownloaded, setFileDownloaded] = useState(false)

  const setSelectedFileHandler = (obj) => {
    if (!obj) {
      return
    }
    setSelectedFile(obj)
  }
  function humanFileSize(size) {
    var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }
  const [connectionMessage, setConnectionMessage] = useState(' Waiting for edge connection. ')
  const getData = async () => {
    const nodeList = await NodeService.getNodeList()
    const iceServers = await NodeService.getIceServers()
    if (nodeList.length > 0) {
      createTransmit(nodeList, iceServers)
    }
  }
  function clearUploadEvent() {
    setSelectedFile({
      name: '',
      size: '',
      path: '',
    })
    setIsProgress(false)
    setFileDownloaded(false)
    setProgressCount(0)
    setIsActive(true)
  }
  const socketRef = useRef()
  const peerRef = useRef()
  function createPeer(userToSignal, callerId, iceServers) {
    console.log(iceServers)
    const peer = new Peer({
      initiator: true,
      config: {
        //   iceServers: [
        //     {urls: 'stun:stun.l.google.com:19302'},
        //     // {
        //     //     urls: "stun:openrelay.metered.ca:80",
        //     // },
        //     {
        //       urls: 'turn:openrelay.metered.ca:80',
        //       username: 'openrelayproject',
        //       credential: 'openrelayproject',
        //     },
        //     // {
        //     //     urls: "turn:openrelay.metered.ca:443",
        //     //     username: "openrelayproject",
        //     //     credential: "openrelayproject",
        //     // },
        //     // {
        //     //     urls: "turn:openrelay.metered.ca:443?transport=tcp",
        //     //     username: "openrelayproject",
        //     //     credential: "openrelayproject",
        //     // },
        //   ],
        // },
        iceServers: iceServers,
      },
      trickle: false,
    })

    peer.on('signal', (signal) => {
      // console.log('callerId: ', callerId)
      // console.log('User to signal: ', userToSignal)
      socketRef.current.emit('sending signal', {userToSignal, callerId, signal})
    })
    peer.on('error', () => {
      setConnectionIsFailed(true)
    })
    // peer.on("data", handleReceivingData);

    return peer
  }

  function addPeer(incomingSignal, callerId, iceServers) {
    console.log(iceServers)
    const peer = new Peer({
      initiator: false,
      config: {
        // iceServers: [
        //   {urls: 'stun:stun.l.google.com:19302'},
        //   // {
        //   //     urls: "stun:openrelay.metered.ca:80",
        //   // },
        //   {
        //     urls: 'turn:openrelay.metered.ca:80',
        //     username: 'openrelayproject',
        //     credential: 'openrelayproject',
        //   },
        //   // {
        //   //     urls: "turn:openrelay.metered.ca:443",
        //   //     username: "openrelayproject",
        //   //     credential: "openrelayproject",
        //   // },
        //   // {
        //   //     urls: "turn:openrelay.metered.ca:443?transport=tcp",
        //   //     username: "openrelayproject",
        //   //     credential: "openrelayproject",
        //   // },
        // ],
        iceServers: iceServers,
      },
      trickle: false,
    })
    peer.on('signal', (signal) => {
      // console.log('signal from ', callerId)
      socketRef.current.emit('returning signal', {signal, callerId})
    })

    peer.on('connect', () => {
      const reqData = {
        runId: currentRunId,
        userId: JSON.parse(localStorage.getItem('user')).id,
        type: 'clientFilesListReq',
      }
      peer.send(JSON.stringify(reqData))
    })

    console.log(peer)
    peer.on('data', async (data) => {
      try {
        // else {
        // if (JSON.parse(data).length > 0) {
        setRunFiles(JSON.parse(data))
        // }
        setIsActive(true)
        setIsConnection(false)
        // }
        // }
        peer.removeAllListeners('data')
      } catch (error) {
        // Not a JSON message, assume it's file data
        console.log(error)
        // await writeableStream.write(data)
      }
    })
    peer.on('error', () => {
      setConnectionIsFailed(true)
    })
    // peer.on("data", handleReceivingData);
    peer.signal(incomingSignal)
    return peer
  }

  function getUserInfo(nodeList) {
    // TODO
    // Retrieve from current user session.
    let currentUser = JSON.parse(localStorage.getItem('user'))
    let currentArray = nodeList.filter((item) => {
      return item.nodeName === currentNode
      // return item.nodeName === 'gennext.bio'
    })
    let dummyData = {
      userId: currentUser.id,
      userName: currentUser.username,
      edgeNode: currentArray[0],
    }
    return dummyData
  }
  function createTransmit(nodeList, iceServers) {
    transmitIdRef.current = uuidv4()
    const userInfo = getUserInfo(nodeList)
    const transmitId = transmitIdRef.current
    // const userInfo = getUserInfo()
    socketRef.current = io.connect(process.env.REACT_APP_EDGE_URL)
    socketRef.current.emit('join transmit', {
      transmitId: transmitId,
      userName: userInfo.userName,
      edgeNode: userInfo.edgeNode,
    })

    socketRef.current.on('all users', (users) => {
      console.log(users, socketRef)
      peerRef.current = createPeer(users[0], socketRef.current.id, iceServers)
    })

    socketRef.current.on('user joined', (payload) => {
      // console.log("edge conn established")
      peerRef.current = addPeer(payload.signal, payload.callerId, iceServers)
    })

    socketRef.current.on('receiving returned signal', (payload) => {
      peerRef.current.signal(payload.signal)
    })

    socketRef.current.on('transmit in progress', () => {
      alert('Transmit is in progress.')
    })
    socketRef.current.on('error', () => {
      setConnectionIsFailed(true)
    })
  }
  async function handleIncomingFile() {
    // Prompt the user to choose a file to save the incoming data
    // const fileHandle = await showSaveFilePicker({
    const fileHandle = await showSaveFilePicker({
      // _preferPolyfill: false,
      suggestedName: selectedFile.name,
      // types: [{
      //     description: 'Text Files',
      //     accept: { 'text/plain': ['.txt'] },
      // }],
    })
    // .then((item) => {
    //   setWriteableStream(item.createWritable())
    // })
    // await showSaveFilePicker({
    //   // _preferPolyfill: false,
    //   suggestedName: 'TST-0_SV_annot_oncokb_combined.tsv',
    //   // types: [{
    //   //     description: 'Text Files',
    //   //     accept: { 'text/plain': ['.txt'] },
    //   // }],
    // }).then((item) => {
    //   setWriteableStream(item.createWritable())
    // })
    // .then((item) => {
    //   setWriteableStream(item.createWritable())
    // })
    let writableStream = await fileHandle.createWritable()
    let peer = peerRef.current
    var count = 0
    peer.on('data', async (data) => {
      try {
        // console.log('got data 2')
        // console.log(data)
        const message = JSON.parse(data.toString())
        if (message.endOfFile) {
          await writableStream.close()
          setFileDownloaded(true)
          console.log('File transfer complete and file saved.2')
        } else {
          console.log(data)
          console.log(message)
        }
      } catch (error) {
        // console.log(data.length)
        count = count + data.length
        setProgressCount(count)
        setIsProgress(true)
        // Not a JSON message, assume it's file data
        await writableStream.write(data)
      }
    })
    peer.on('error', () => {
      setConnectionIsFailed(true)
    })
    const userId = JSON.parse(localStorage.getItem('user')).id
    const fileReq = {
      path: selectedFile.path,
      name: selectedFile.name,
      runId: currentRunId,
      userId: userId,
    }
    peer.send(JSON.stringify(fileReq))
  }
  useEffect(() => {
    if (modalOpen) {
      getData()
    }
  }, [modalOpen])
  // console.log(runFiles)
  // console.log(selectedFile)

  return (
    <div className='modal fade ' tabIndex={-1} id='FileDownloadModal'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>File Download</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body file-download-modal-body'>
            {connectionIsFailed ? (
              <h4 className='text-center'>
                Connection has Failed. Please Reload Page.<br></br>
                <button
                  className='btn btn-primary my-3'
                  onClick={
                    () => window.location.reload() // Sayfayı yenile
                  }
                >
                  Reload
                </button>
              </h4>
            ) : (
              <>
                {isConnection && (
                  <h4 className='text-center'>
                    Waiting for edge connection.{' '}
                    <span className='indicator-progress' style={{display: 'inline-block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>{' '}
                  </h4>
                )}

                {isProgress ? (
                  <div>
                    <progress
                      className='progress-bar w-100 progress'
                      id='transferProgress'
                      value={progressCount}
                      max={selectedFile.size}
                    />
                    {humanFileSize(progressCount)} / {humanFileSize(selectedFile.size)}
                    {fileDownloaded && (
                      <span className='d-block alert alert-success p-5 col-md-12 mt-5 text-center'>
                        File Successfully Downloaded
                      </span>
                    )}
                  </div>
                ) : (
                  <FileManager
                    runFiles={runFiles}
                    selectedFile={selectedFile}
                    isActive={isActive}
                    setSelectedFileHandler={setSelectedFileHandler}
                  />
                )}
              </>
            )}
          </div>
          {!connectionIsFailed && (
            <div className='modal-footer'>
              {runFiles.length > 0 && (
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  disabled={isProgress ? (fileDownloaded ? false : true) : false}
                  onClick={() => clearUploadEvent()}
                >
                  Close
                </button>
              )}

              {selectedFile.name !== '' && (
                <button
                  type='button'
                  disabled={isProgress ? true : false}
                  onClick={() => handleIncomingFile()}
                  className='btn btn-primary'
                >
                  Download File
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileDownloadModal
