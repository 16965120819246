/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import {useAuth} from '../../app/modules/auth'
import Swal from 'sweetalert2'
import CustomAnnotationModal from './CustomAnnotationModal'

const VaritanFiltering = ({
  currentRunId,
  setFamilyFilterDatas,
  familyFilterDatas,
  currentNode,
  applyFilterHandler,
  lockButtons,
  previewStr,
  clickedHPOItems,
  dataType,
  selectedHPOItem,
  selectedGeneList,
  geneSymbolListStr,
}) => {
  // const {currentUser} = useAuth()
  const generateUrlWithUserCredentianls = (type) => {
    if (selectedHPOItem.id !== undefined) {
      return '#'
    }
    if (clickedHPOItems.length === 1 && previewStr.length === 0) {
      return `/run-detail-table/${currentRunId}&${type}`
    }
    if (
      clickedHPOItems.length > 0 ||
      geneSymbolListStr.length > 0 ||
      selectedHPOItem.id !== undefined
    ) {
      if (selectedGeneList.length === undefined) {
        return '#'
      } else {
        return `/run-detail-table/${currentRunId}&${type}&${selectedGeneList[0].genesListId}`
      }
    } else {
      console.log(clickedHPOItems.length, geneSymbolListStr.length, selectedGeneList.length)
      return `/run-detail-table/${currentRunId}&${type}`
    }
  }
  const handleClick = (event, id) => {
    event.preventDefault()
    const url = generateUrlWithUserCredentianls(id)
    if (url === '#') {
      Swal.fire('Warning!', 'You must save if you want to apply your genelist.', 'warning')
    } else {
      window.open(url, '_blank')
    }
  }
  return (
    // Sample Information Card Start
    <div className={`card px-6`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Variants</span>
        </h3>
      </div>
      <div className='variantFilteringBody card-body d-flex flex-wrap py-10 px-2 w-100'>
        <div className='col-md-4 my-5 text-center'>
          <a
            // href={`#`}
            // target='_blank'
            onClick={() => {
              familyFilterDatas.filterSetting = 'clinical'
              setFamilyFilterDatas(familyFilterDatas)
              applyFilterHandler()
            }}
            className={`btn btn-dark btn-lg  ${lockButtons ? ' disabled ' : ' '} `}
            rel='noreferrer'
          >
            {lockButtons ? (
              <>
                Listing Variants, Please Wait
                <span className='indicator-progress' style={{display: 'inline-block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </>
            ) : (
              'Clinical SNVs/Short INDELs'
            )}
          </a>
        </div>
        <div className='col-md-4 my-5 text-center'>
          <a
            // href={`#`}
            // target='_blank'
            onClick={() => {
              familyFilterDatas.filterSetting = 'research'
              setFamilyFilterDatas(familyFilterDatas)
              applyFilterHandler()
            }}
            className={`btn btn-dark btn-lg  ${lockButtons ? ' disabled ' : ' '} `}
            rel='noreferrer'
          >
            {lockButtons ? (
              <>
                Listing Variants, Please Wait
                <span className='indicator-progress' style={{display: 'inline-block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </>
            ) : (
              'Research SNVs/Short INDELs'
            )}
          </a>
        </div>
        <div className='col-md-4 my-5 text-center'>
          <a
            // href={`https://${currentNode}.node.${process.env.REACT_APP_NODE_URL}/submit/nocache/index.html`}
            target='_blank'
            // data-bs-toggle='modal'
            // data-bs-target='#CustomAnnotationModal'
            className={`btn btn-dark btn-lg disabled   `}
            rel='noreferrer'
          >
            {' '}
            Custom SNVs/Short INDELs
          </a>
        </div>
        <div className='col-md-4 my-5 text-center'>
          <a
            // href='#'
            onClick={(event) => {
              if (dataType === 'vcf') {
                return 'javascript:void(0)'
              }
              handleClick(event, 'clinical')
            }}
            className={`btn btn-dark btn-lg ${dataType === 'vcf' ? 'disabled' : ''}`}
            rel='noreferrer'
          >
            {' '}
            Clinical Structural Variants
          </a>
        </div>
        <div className='col-md-4 my-5 text-center'>
          <a
            // href='#'
            onClick={(event) => {
              if (dataType === 'vcf') {
                return 'javascript:void(0)'
              }
              handleClick(event, 'clinical')
            }}
            className={`btn btn-dark btn-lg ${dataType === 'vcf' ? 'disabled' : ''}`}
            rel='noreferrer'
          >
            {' '}
            Research Structural Variants
          </a>
        </div>
        <div className='col-md-4 my-5 text-center'>
          <a
            // href={'#'}
            // target='_blank'
            className={`btn btn-dark btn-lg disabled`}
            rel='noreferrer'
            disabled={true}
          >
            {' '}
            B-allele frequency (Only WGS)
          </a>
        </div>
      </div>
      <CustomAnnotationModal currentNode={currentNode} />
    </div>
    // Sample Information Card End
  )
}

export {VaritanFiltering}
