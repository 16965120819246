/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {TagsInput} from 'react-tag-input-component'
import runsService from '../../service/runs'
import {useClickOutside} from '../../hooks'
import RunsService from '../../service/runs'
import PhenotypeModal from '../RunDetail/PhenotypeModal'

const NewSampleInformation = ({
  id,
  sampleId,
  sampleName,
  refferingPhysicians,
  analysts,
  clinicalDiagnosis,
  specimenType,
  phenoTypes,
  specimenSite,
  submissionDate,
  runId,
}) => {
  const intl = useIntl()
  // const [runIsSolved, setRunIsSolved] = React.useState(isSolved)
  const [refferingPhysState, setRefferingPhysState] = useState(refferingPhysicians)
  const [analystsState, setAnalystsState] = useState(analysts)
  const [analystsKeyUpState, setAnalystsKeyUpState] = useState('')
  const [refferingKeyUpState, setRefferingKeyUpState] = useState('')
  const [phenotypesKeyUpState, setPhenotypesKeyUpState] = useState('')
  const [phenotypesState, setPhenotypesState] = useState(phenoTypes)

  const [isRefferingEditing, setIsRefferingEditing] = useState(false)
  const [isAnalystEditing, setIsAnalystEditing] = useState(false)
  useEffect(() => {
    setPhenotypesKeyUpState(phenoTypes.join('\n'))
  }, [])
  useEffect(() => {
    setPhenotypesKeyUpState(phenotypesState.join('\n'))
  }, [phenotypesState])
  // const changeCheck = () => {
  //   runsService.updateRunIsSolved(runIsSolved, runId)
  //   setRunIsSolved(!runIsSolved)
  // }

  const handleRefferingClick = () => {
    setIsRefferingEditing(!isRefferingEditing)
    console.log(isRefferingEditing)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveRefferingEdit = () => {
    // !!refferingKeyUpState && setRefferingPhysState((prev) => [...prev, refferingKeyUpState])
    if (!!refferingKeyUpState) {
      refferingPhysState.push(refferingKeyUpState)
      let uniqueValues = [...new Set(refferingPhysState)]
      setRefferingPhysState(uniqueValues)
      // setRefferingPhysState((prev) => [...prev, refferingKeyUpState])
    }
    setRefferingKeyUpState('')
    isRefferingEditing && runsService.updateRunPhysician(refferingPhysState, runId, id)
    setIsRefferingEditing(false)
  }

  const handleAnalystClick = () => {
    setIsAnalystEditing(!isAnalystEditing)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAnalystEdit = () => {
    if (!!analystsKeyUpState) {
      analystsState.push(analystsKeyUpState)
      let uniqueValues = [...new Set(analystsState)]
      setAnalystsState(uniqueValues)
    }
    setAnalystsKeyUpState('')
    console.log(analystsState)
    isAnalystEditing && runsService.updateRunAnalyst(analystsState, runId, id)
    setIsAnalystEditing(false)
  }

  const physicialRef = useRef(null)
  const analystsRef = useRef(null)
  const savePhenotypes = (phenotypes) => {
    setPhenotypesState(phenotypes)
    runsService.updateRunPhenotypes(phenotypes, runId, id)
  }
  // useClickOutside(physicialRef, () => saveRefferingEdit())
  // useClickOutside(analystsRef, () => saveAnalystEdit())
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (physicialRef.current && !physicialRef.current.contains(event.target)) {
        saveRefferingEdit()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isRefferingEditing, refferingPhysState, refferingKeyUpState])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (analystsRef.current && !analystsRef.current.contains(event.target)) {
        saveAnalystEdit()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isAnalystEditing, analystsState, analystsKeyUpState])

  const formatDate = new Date(submissionDate)

  const formattedDate = formatDate.toLocaleString('en-EN', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  return (
    // Sample Information Card Start
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>
            {intl.formatMessage({id: 'SAMPLEINFORMATION'})}
          </span>
        </h3>
      </div>
      <div className='card-body pt-0 px-2'>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>Sample ID :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{sampleId}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Sample Name :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{sampleName}</div>
          </div>
        </div>
        <div ref={physicialRef} className='timeline-label my-4'>
          <div className='timeline-item '>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Referring Physician(s) :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span>
                {refferingPhysState?.slice(0, 50)?.join(',')}{' '}
                <img
                  src='/media/icons/edit-icon.svg'
                  width={15}
                  onClick={handleRefferingClick}
                  className='edit-icon cursor-pointer mx-2'
                  height={15}
                  alt='Edit Referring'
                />
              </span>
            </div>
          </div>
          {isRefferingEditing && (
            <div>
              <TagsInput
                name='refferingPhys'
                onKeyUp={(e) => {
                  setRefferingKeyUpState(e?.target?.value)
                }}
                value={refferingPhysState}
                onChange={setRefferingPhysState}
                placeHolder='Enter Physician'
              />

              <button className='btn btn-primary my-2' onClick={saveRefferingEdit}>
                Save
              </button>
            </div>
          )}
        </div>
        <div ref={analystsRef} className='timeline-label my-4'>
          <div className='timeline-item '>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Analyst(s) :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              <span>{analystsState?.slice(0, 50)?.join(',')}</span>
              <img
                src='/media/icons/edit-icon.svg'
                width={15}
                onClick={handleAnalystClick}
                className='edit-icon cursor-pointer mx-2'
                height={15}
                alt='Edit Analysts'
              />
            </div>
          </div>
          {isAnalystEditing && (
            <div>
              <TagsInput
                name='analysts'
                value={analystsState}
                onChange={setAnalystsState}
                onKeyUp={(e) => {
                  setAnalystsKeyUpState(e?.target?.value)
                }}
                placeHolder='Enter Analyst'
              />
              <button className='btn btn-primary my-2' onClick={saveAnalystEdit}>
                Save
              </button>
            </div>
          )}
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Submission Date :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{formattedDate}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Clinical Diagnosis :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{clinicalDiagnosis}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Specimen Type :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{specimenType}</div>
          </div>
        </div>
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Specimen Site :
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>{specimenSite}</div>
          </div>
        </div>
        <div
          className='timeline-label my-4 cursor-pointer'
          data-bs-toggle='modal'
          data-bs-target='#PhenotypeModal'
        >
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
              Phenotypes : {phenotypesState.length < 1 && <i className='fa fa-edit'></i>}
            </div>

            {phenotypesState.length > 0 && (
              <div className='fw-mormal timeline-content text-muted  w-100 '>
                <ul className='phenotypes-preview '>
                  {phenotypesState.map((item, index) => {
                    return <li key={index}> {item} </li>
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
        <PhenotypeModal
          phenotypesKeyUpState={phenotypesKeyUpState}
          setPhenotypesKeyUpState={setPhenotypesKeyUpState}
          savePhenotypes={savePhenotypes}
          phenoTypes={phenoTypes}
          phenotypesState={phenotypesState}
          setPhenotypesState={setPhenotypesState}
        />
      </div>
      {/* <div className='familyItem d-flex align-items-center my-2'>
        <label className='w-100'>Clinical Diagnosis :</label>
        <select
          className='form-select  form-select-solid form-select-sm  w-100'
          onChange={(e) => {
            changeClinicalDiagnosis(e.target.value)
          }}
        >
          <option disabled> </option>
          {clinicalDiagnosisArray.map((item) => {
            return <option selected={clinicalDiagnosis === item}>{item}</option>
          })}
        </select>
      </div>
      <div className='familyItem d-flex align-items-center my-2'>
        <label className='w-100'>Specimen Type :</label>
        <select
          className='form-select  form-select-solid form-select-sm  w-100'
          onChange={(e) => {
            changeSpecimenType(e.target.value)
          }}
        >
          <option disabled> </option>
          {specimenTypeArray.map((item) => {
            return <option selected={specimenType === item}>{item}</option>
          })}
        </select>
      </div>
      <div className='familyItem d-flex align-items-center my-2'>
        <label className='w-100'>Specimen Site :</label>
        <select
          className='form-select  form-select-solid form-select-sm  w-100'
          onChange={(e) => {
            changeSpecimenSite(e.target.value)
          }}
        >
          <option disabled> </option>
          {specimenSiteArray.map((item) => {
            return <option selected={specimenSite === item}>{item}</option>
          })}
        </select>
      </div> */}
    </div>
    // Sample Information Card End
  )
}

export {NewSampleInformation}
