import {useState, useEffect} from 'react'
import './TableComponent.scss' // custom CSS/SASS styling
import {
  Column,
  FieldType,
  Filters,
  Formatters,
  GridOption,
  GridStateChange,
  GridStateType,
  OperatorType,
  SlickgridReact,
  TreeToggledItem,
  TreeToggleStateChange,
} from 'slickgrid-react'
import {Loader} from '../../components/Loader'
import '../../../node_modules/@slickgrid-universal/common/dist/styles/css/slickgrid-theme-bootstrap.css'

import {Tooltip} from 'react-tooltip'

const TableComponentTreeData = ({
  data,
  selectedRows,
  setSelectedRows,
  selectedWidgetItem,
  setSelectedWidgetItem,
  tableHeader,
  gridOptions,
  setGridOptions,
  gridId,
}) => {
  // const [loadingClass, setLoadingClass] = useState('')
  // const [isLargeDataset, setIsLargeDataset] = useState(false)
  const [hasNoExpandCollapseChanged, setHasNoExpandCollapseChanged] = useState(true)
  const [uniqueData, setUniqueData] = useState([])
  const [treeToggleItems, setTreeToggleItems] = useState<TreeToggledItem[]>([])
  const [columnDefinitions, setColumnDefinitions] = useState<Column[]>([])

  const onGrid1SelectedRowsChanged = (_e: Event, args: any) => {
    const grid = args && args.grid
    if (Array.isArray(args.rows)) {
      setSelectedRows(
        args.rows.map((idx: number) => {
          const item = grid.getDataItem(idx)
          return item
        })
      )
    }
  }
  const getUniqueValues = (data: {[key: string]: any}[], columnKey: string) => {
    const uniqueValues = Array()
    for (const item of data) {
      if (!uniqueValues.includes(item[columnKey])) {
        uniqueValues.push(item[columnKey])
      }
    }
    return uniqueValues
  }
  useEffect(() => {
    if (data.length > 1) {
      var olderParent = null
      const uniqueData = data.map((item, index) => {
        index++
        if (item.parentId === null) {
          olderParent = index
        }
        return {...item, id: index, parentId: item.parentId === null ? null : olderParent}
      })
      setUniqueData(uniqueData)
      defineGrid()
    }
  }, [])
  const chromComparator = (a, b) => {
    const extractChromNumber = (chrom) => {
      const match = chrom.match(/^chr(\d+|[XYM])$/i)
      if (!match) return chrom
      const value = match[1]
      if (value === 'X') return 23
      if (value === 'Y') return 24
      if (value === 'M') return 25
      return parseInt(value, 10)
    }

    const chromA = extractChromNumber(a)
    const chromB = extractChromNumber(b)

    return chromA - chromB
  }
  const fusionChromComparator = (a, b) => {
    const extractChromNumber = (chrom) => {
      const match = chrom.match(/^chr(\d+|[XYM]):/i)
      if (!match) return 1000 // Eğer match bulunamazsa, büyük bir sayı döndürerek sıralamada en sona koyma
      const value = match[1]
      if (value === 'X') return 23
      if (value === 'Y') return 24
      if (value === 'M') return 25
      return parseInt(value, 10)
    }

    const chromA = extractChromNumber(a)
    const chromB = extractChromNumber(b)

    return chromA - chromB
  }

  const defineGrid = () => {
    console.log(tableHeader, data)
    const columnDefs = Object.keys(tableHeader).map((columnKey) => {
      let additionalProps = {}
      if (columnKey === 'reportSelect') {
        additionalProps = {
          frozen: true,
          formatter: Formatters.tree,
          minWidth: 30,
          name: '',
          filterable: false,
          sortable: false,
        }
      }
      let filter
      if (
        columnKey === 'Chrom' ||
        columnKey === 'SVType' ||
        columnKey === 'OMIMInheritance' ||
        columnKey === 'ACMGClass' ||
        columnKey === 'OncoKBSVType' ||
        columnKey === 'MutationEffect' ||
        columnKey === 'Oncogenic' ||
        columnKey === 'HighestLevel' ||
        columnKey === 'HighestSensitiveLevel' ||
        columnKey === 'HighestResistanceLevel' ||
        columnKey === 'HighestDxLevel' ||
        columnKey === 'HighestPxLevel' ||
        columnKey === 'Zygosity' ||
        columnKey === 'NearestSSType' ||
        columnKey === 'ExonCount' ||
        columnKey === 'PGainSource' ||
        columnKey === 'PLossSource' ||
        columnKey === 'PInsSource' ||
        columnKey === 'PInsSource' ||
        columnKey === 'PoPGainSource' ||
        columnKey === 'PoPLossSource' ||
        columnKey === 'OncoKBGene'
      ) {
        const uniqueValues = getUniqueValues(data, columnKey)
        const collection = uniqueValues.map((value) => ({value, label: value}))

        filter = {
          model: Filters.multipleSelect,
          collection,
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }
      if (columnKey === 'Start' || columnKey === 'End' || columnKey === 'Length') {
        filter = {
          operator: OperatorType.startsWith, // defaults to inclusive
        }
      }
      if (
        columnKey === 'GeneInOncoKB' ||
        columnKey === 'VariantInOncoKB' ||
        columnKey === 'OMIMID'
      ) {
        filter = {
          model: Filters.select,
          collection: ['true', 'false', ''],
          filterOptions: {
            autoDropWidth: true,
          },
        }
      }
      if (
        columnKey === 'GnomADPLI' ||
        columnKey === 'ExACPLI' ||
        columnKey === 'Length' ||
        columnKey === 'Frameshift' ||
        columnKey === 'DistNearestSS' ||
        columnKey === 'GeneCount'
      ) {
        filter = {
          model: Filters.compoundInputText,
          // operator: '>=',
        }
        additionalProps = {
          type: FieldType.number,
        }
      }
      // if (columnKey === 'Start' || columnKey === 'Chrom') {
      //   additionalProps = {
      //     columnGroup: 'Deneme Grubu',
      //   }
      // }
      // if (columnKey === 'End' || columnKey === 'Length') {
      //   additionalProps = {
      //     columnGroup: 'Deneme Grubu 2',
      //   }
      // }
      return {
        id: columnKey,
        name: tableHeader[columnKey].value,
        columnGroup: tableHeader[columnKey].columnGroup,
        field: columnKey,
        headerCssClass: 'header-title',

        cssClass: 'cell-title',
        minWidth: 120,
        filterable: true,
        sortable: true,
        formatter: (row, cell, value, columnDef, dataContext) => {
          const extraClass = dataContext.parentId !== null ? 'tree-data' : 'parent-data'
          return `<div class="${extraClass}">${
            value === null || value === '' || value === undefined ? '.' : value
          }</div>`
        },
        ...additionalProps,
        filter,
        ...(columnKey === 'Chrom'
          ? {
              sortComparer: (a, b, sortDirection) => {
                const result = chromComparator(a, b)
                return sortDirection === 1 ? result : -result
              },
            }
          : {}),
      }
    })

    const gridOpts: GridOption = {
      autoResize: {
        container: '#demo-container',
        rightPadding: 10,
      },
      enableAutoSizeColumns: true,
      enableAutoResize: true,
      enableFiltering: true,
      frozenColumn: 1,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableTreeData: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 53,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      treeDataOptions: {
        columnId: 'reportSelect',
        parentPropName: 'parentId',
        levelPropName: 'treeLevel',
        indentMarginLeft: 15,
        initiallyCollapsed: true,
        initialSort: {
          columnId: 'id',
          direction: 'ASC',
        },
      },
      enablePagination: false,
      multiColumnSort: false,
      showCustomFooter: true,
      headerRowHeight: 40,
      rowHeight: 30,
    }
    setColumnDefinitions(columnDefs)
    setGridOptions(gridOpts)
  }

  // const hideSpinner = () => {
  //   setTimeout(() => {
  //     setLoadingClass('')
  //   }, 200)
  // }

  // const showSpinner = () => {
  //   if (isLargeDataset) {
  //     setLoadingClass('fa fa-refresh fa-spin')
  //   }
  // }

  // const handleOnTreeFullToggleEnd = (_treeToggleExecution: TreeToggleStateChange) => {
  //   hideSpinner()
  // }

  const handleOnTreeItemToggled = (treeToggleExecution: TreeToggleStateChange) => {
    setHasNoExpandCollapseChanged(false)
    setTreeToggleItems(treeToggleExecution.toggledItems as TreeToggledItem[])
  }

  const handleOnGridStateChanged = (gridStateChange: GridStateChange) => {
    setHasNoExpandCollapseChanged(false)

    if (gridStateChange?.change?.type === GridStateType.treeData) {
      setTreeToggleItems(gridStateChange?.gridState?.treeData?.toggledItems as TreeToggledItem[])
    }
  }
  const onCellClicked = (e: any, args: any) => {
    const grid = args && args.grid
    const rows = document.querySelectorAll('.slick-row')
    rows.forEach((row) => {
      row.classList.remove('background-red')
    })

    if (e.target.parentElement.parentElement.classList.contains('slick-row')) {
      e.target.parentElement.parentElement.classList.add('background-red')
    }
    setSelectedWidgetItem(grid.getDataItem(args.row))
  }

  if (data.length < 1) {
    return <div className='  text-center'>There is no data</div>
  }
  return data.length < 1 || !gridOptions || gridOptions === undefined ? (
    <div className='card'>
      <div className='card-body text-center'>
        <Loader />
      </div>
    </div>
  ) : (
    <div id='demo-container' className='container-fluid mt-5' style={{overflow: 'hidden'}}>
      <div id='grid-container' className='col-sm-12'>
        <SlickgridReact
          gridId={gridId}
          columnDefinitions={columnDefinitions}
          gridOptions={gridOptions}
          dataset={uniqueData}
          // onBeforeFilterChange={() => showSpinner()}
          // onFilterChanged={() => hideSpinner()}
          // onBeforeFilterClear={() => showSpinner()}
          // onFilterCleared={() => hideSpinner()}
          // onBeforeSortChange={() => showSpinner()}
          // onSortChanged={() => hideSpinner()}
          // onBeforeToggleTreeCollapse={() => showSpinner()}
          // onToggleTreeCollapsed={() => hideSpinner()}
          // onTreeFullToggleStart={() => showSpinner()}
          // onTreeFullToggleEnd={($event) => handleOnTreeFullToggleEnd($event.detail)}
          onTreeItemToggled={($event) => handleOnTreeItemToggled($event.detail)}
          onGridStateChanged={($event) => handleOnGridStateChanged($event.detail)}
          onSelectedRowsChanged={($event) =>
            onGrid1SelectedRowsChanged($event.detail.eventData, $event.detail.args)
          }
          onClick={($event) => {
            onCellClicked($event.detail.eventData, $event.detail.args)
          }}
        />
        <Tooltip id='my-tooltip' />
      </div>
    </div>
  )
}

export default TableComponentTreeData
