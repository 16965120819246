import {PageTitle} from '../../_metronic/layout/core'
import FileManager from '../../components/RunDetail/FileManager'

const FileManagerPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>File Manager</PageTitle>
      <FileManager />
    </>
  )
}

export {FileManagerPage}
