import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import carboneSDK from 'carbone-sdk-js'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import axios from 'axios'

const GenerateReportModal = ({show, handleClose, templateId}) => {
  const _carboneService = carboneSDK(process.env.REACT_APP_CARBONE_API_KEY ?? 'test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3MDQxODc2NjA0MjU1ODM0MTUiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzU1NjYwOTEwLCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AbU5TFdSZ0AO_63izLicXVM63RrMt1ucjugN2iRv7K4UMMlhOlDQLUtCdNy4ZK7l7sYcYlNwo2FSHPL7fw4GO55tALAnViFe2_iXSQBnAyTnh8W8hZzAe-zeVra9Qi9iz80d52sx3u1vaW5PhjREPtcynwphD9hdyhP0B1N3szLOSA7V')
  const [isLoading, setIsLoading] = useState(false)
  const [reportData, setReportData] = useState({})

 
  const getData = () => {
    axios.post(`https://anilakdeniz.com/getVariants.php`,{
      process:"getSingleJsonData",
      jsonId:parseInt(templateId),
      userId: 1
    }).then((res) => {
        setReportData(res.data)
        setIsLoading(false);
    })
  }

  useEffect(() => {
    getData()
  }, [])
var exportData = {}
  if(reportData.length>0){
     exportData = JSON.parse(reportData[0].jsonData)[0]; 
  }
   
  const handleGenerateReport = () => {
    try {
      _carboneService.render(templateId, {data: exportData, convertTo: 'pdf'}).then((res) => {
        var link = window.document.createElement('a')
        link.href = window.URL.createObjectURL(res.content)
        link.download = link.href.split('/').pop() + '.' + 'pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    } catch (error) {
      console.log('json is not valid')
    }
  }

  // const [data, setData] = useState({
  //   id: 42,
  //   date: 1492012745,
  //   company: {
  //     name: 'myCompany',
  //     address: 'here',
  //     city: 'Notfar',
  //     postalCode: 123456,
  //   },
  //   customer: {
  //     name: 'myCustomer',
  //     address: 'thert',
  //     city: 'Faraway',
  //     postalCode: 654321,
  //   },
  //   products: [
  //     {
  //       name: 'product 1',
  //       priceUnit: 0.1,
  //       quantity: 10,
  //       priceTotal: 1,
  //     },
  //     {
  //       name: 'product 2',
  //       priceUnit: 0.2,
  //       quantity: 20,
  //       priceTotal: 4,
  //     },
  //     {
  //       name: 'product 3',
  //       priceUnit: 0.3,
  //       quantity: 30,
  //       priceTotal: 9,
  //     },
  //   ],
  //   total: 14,
  // })

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-xl'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Generate Report</h5>
        </div>
        <div className='modal-body'>
          <JSONInput
            id='a_unique_id'
            placeholder={exportData}
            locale={locale}
            width="100%"
            height='550px'
            onChange={(e) => {
              setIsLoading(true)

              !!e.jsObject && setReportData(e.jsObject)

              setTimeout(() => {
                setIsLoading(false)
              }, 1000)
            }}
          />
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={() => handleClose()}>
            Cancel
          </button>
          <button
            id='submit'
            type='button'
            className='btn btn-primary'
            disabled={isLoading}
            onClick={() => !isLoading && handleGenerateReport()}
          >
            Generate
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {GenerateReportModal}
