
const CurrentPlan = ({className, color}) => {
  return (
    <div className={`card ${className} hidden-xl`}>
      <div className='card-body p-0'>
        <div className={`px-9 py-10 card-rounded  w-100 bg-${color}`}>
          <div className='d-flex text-center flex-column text-white'>
            <span className='fw-bold fs-2x pt-1'>Current Plan</span>
            <span className='fw-bold fs-2 pt-1'> Standart</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CurrentPlan}
