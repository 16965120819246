import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import somaticService from '../../../service/somatic'
import {Loader} from '../../Loader'

const ColumnChart = ({selectedRow}) => {
  // const [series, setSeries] = useState([{data: makeData()}])
  const [afData, setAfData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorData, setErrorData] = useState(false)
  const [seriesQuarter, setSeriesQuarter] = useState([{data: []}])
  var optimalColumnWidthPercent = 20 + 60 / (1 + 30 * Math.exp(-5 / 3))
  var colors = [
    '#E93645',
    '#71C6FE',
    '#6FF6C8',
    '#AD98F7',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ]
  function convertData(data) {
    var maxVal = 0
    var series = data.map((item, index) => {
      maxVal = item.alleleFreqData.af > maxVal ? item.alleleFreqData.af : maxVal
      return {
        x: item.title,
        y: item.alleleFreqData.af.toFixed(5),
        color: colors[index], // Renk belirlemesi burada yapılabilir.
        quarters: item.alleleFreqData.subPops.map((subPop) => {
          return {
            x: subPop.title,
            y: subPop.value,
          }
        }),
      }
    })

    return series
  }
  const getAF = async () => {
    setIsLoading(true)
    setErrorData(false)

    var reqData = {
      chr: selectedRow.Chrom,
      pos: selectedRow.Start,
      ref: selectedRow.Ref,
      alt: selectedRow.Alt,
      refver: 'hg38',
    }
    var reqRes = await somaticService.getAFDetails(reqData)
    if (reqRes.status === 200) {
      var val = convertData(reqRes.data)
      if (val.length < 1) {
        setErrorData(true)
        setIsLoading(false)
        return
      }
      setAfData(val)
      setIsLoading(false)
      return
    } else {
      setErrorData(true)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getAF()
  }, [selectedRow])
  const options = {
    chart: {
      id: 'barYear',
      height: 100,
      width: '100%',
      type: 'bar',
      events: {
        dataPointSelection: function (e, chart, opts) {
          var quarterChartEl = document.querySelector('#chart-quarter')
          var yearChartEl = document.querySelector('#chart-year')

          if (opts.selectedDataPoints[0].length === 1) {
            if (quarterChartEl.classList.contains('active')) {
              updateQuarterChart(chart, 'barQuarter')
            } else {
              yearChartEl.classList.add('chart-quarter-activated')
              quarterChartEl.classList.add('active')
              updateQuarterChart(chart, 'barQuarter')
            }
          } else {
            updateQuarterChart(chart, 'barQuarter')
          }

          if (opts.selectedDataPoints[0].length === 0) {
            yearChartEl.classList.remove('chart-quarter-activated')
            quarterChartEl.classList.remove('active')
          }
        },
        updated: function (chart) {
          updateQuarterChart(chart, 'barQuarter')
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: optimalColumnWidthPercent + '%',
        barHeight: '150px',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: colors,
    states: {
      normal: {
        filter: {
          type: 'desaturate',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    tooltip: {
      x: {
        formatter: function (val, opts) {
          return 'Allele Frequence'
        },
      },
      y: {
        title: {
          formatter: function (val, opts) {
            return opts.w.globals.labels[opts.dataPointIndex]
          },
        },
      },
    },
    title: {
      text: ' ',
      offsetX: 15,
    },
    subtitle: {
      text: '',
      offsetX: 15,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      tickAmount: 10,
      labels: {
        style: {
          fontSize: '6px',
        },
        formatter: function (val) {
          return val.toFixed(2)
        },
      },
    },
  }

  const optionsQuarter = {
    chart: {
      id: 'barQuarter',
      height: 200,
      width: '100%',
      type: 'column',
      stacked: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        horizontal: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    subtitle: {
      text: '',
      offsetX: 15,
    },

    yaxis: {
      min: 0,
      max: 1,
      tickAmount: 10,
      labels: {
        style: {
          fontSize: '6px',
        },
        formatter: function (val) {
          return val.toFixed(2)
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        formatter: function (val, opts) {
          return opts.w.globals.seriesNames[opts.seriesIndex]
        },
      },
      y: {
        title: {
          formatter: function (val, opts) {
            return opts.w.globals.labels[opts.dataPointIndex]
          },
        },
      },
    },
  }

  function updateQuarterChart(sourceChart, destChartIDToUpdate) {
    var series = []
    var seriesIndex = 0
    var colors = []
    if (sourceChart.w.globals.selectedDataPoints[0]) {
      var selectedPoints = sourceChart.w.globals.selectedDataPoints
      for (var i = 0; i < selectedPoints[seriesIndex].length; i++) {
        var selectedIndex = selectedPoints[seriesIndex][i]
        var yearSeries = sourceChart.w.config.series[seriesIndex]
        series.push({
          name: yearSeries.data[selectedIndex].x,
          data: yearSeries.data[selectedIndex].quarters,
        })
        colors.push(yearSeries.data[selectedIndex].color)
      }

      if (series.length === 0)
        series = [
          {
            data: [],
          },
        ]

      return ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
        series: series,
        colors: colors,
        fill: {
          colors: colors,
        },
      })
    }
  }

  if (isLoading) {
    return (
      <div className='m-10 card mx-10 h-auto text-center p-5'>
        <h5 className='m-4'> Allele Frequency</h5>

        <Loader />
      </div>
    )
  }
  if (errorData) {
    return (
      <div className='m-10 card mx-10 h-auto text-center p-5'>
        <h5 className='m-4'> Allele Frequency</h5>
        There is no data
      </div>
    )
  }
  return (
    <div className='m-10 card mx-10 h-auto'>
      <h5 className='m-4'> Allele Frequency</h5>

      <div id='wrap ' className='d-flex'>
        <select id='model' className='flat-select d-none' onChange={() => {}}>
          <option value='1'>1</option>
        </select>
        <div id='chart-year' className='w-100'>
          <ReactApexChart
            options={options}
            series={[
              {
                data: afData,
              },
            ]}
            type='bar'
            height={300}
          />
        </div>
        <div id='chart-quarter' className='w-100'>
          <ReactApexChart options={optionsQuarter} series={seriesQuarter} type='bar' height={300} />
        </div>
      </div>
    </div>
  )
}

export default ColumnChart
