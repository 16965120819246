import {useIntl} from 'react-intl'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function AdvancedFilterTable({
  processType,
  xFilters,
  changeCheck,
  userNodes,
  setFilters,
  changeCheckNode,
  filters,
  advancedFilterSubmitHandler,
  clearAdvancedFilter,
}) {
  const intl = useIntl()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown w-100 t-none position-absolute'
      data-kt-menu='true'
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='mb-10'>
          <div className='d-flex gap-8'>
            <div>
              <label className='form-label fw-bold'>Enrichment Kit</label>
              <input
                className='form-control w-auto'
                value={filters.enrichment}
                onChange={(e) =>
                  setFilters((prev) => {
                    return {...prev, enrichment: e.target.value.toLowerCase()}
                  })
                }
              />
            </div>

            <div>
              <label className='form-label fw-bold'>Genome Assembly</label>
              <input
                className='form-control w-auto'
                value={filters.genome}
                onChange={(e) =>
                  setFilters((prev) => {
                    return {...prev, genome: e.target.value.toLowerCase()}
                  })
                }
              />
            </div>
          </div>

          <div className='d-flex'></div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Nodes</label>

          <div className='d-flex'>
            {userNodes.map((item, index) => (
              <label
                key={index}
                className='form-check form-check-sm form-check-custom form-check-solid me-2'
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  // checked={filters.nodes.find((process) => process.id === item.id).isChecked}
                  defaultValue={item.nodeName}
                  onChange={() => {
                    changeCheckNode(item.nodeName)
                  }}
                />
                {/* <span className='form-check-label'>{intl.formatMessage({id: item.name.toLowerCase()})}</span> */}
                <span className='form-check-label'>{item.nodeName}</span>
              </label>
            ))}
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Process Type</label>

          <div className='d-flex'>
            {processType.map((item, index) => (
              <label
                key={index}
                className='form-check form-check-sm form-check-custom form-check-solid me-2'
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={filters.process.find((process) => process.id === item.id).isChecked}
                  defaultValue={item.name}
                  onChange={() => {
                    changeCheck(item.id)
                  }}
                />
                {/* <span className='form-check-label'>{intl.formatMessage({id: item.name.toLowerCase()})}</span> */}
                <span className='form-check-label'>{item.name}</span>
              </label>
            ))}
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Gender</label>

          <div className='d-flex'>
            <label className='form-check form-check-sm form-check-custom form-check-solid me-2'>
              <input
                className='form-check-input'
                type='radio'
                name='gender'
                value={filters.gender}
                onChange={() =>
                  setFilters((prev) => {
                    return {...prev, gender: 'Male'}
                  })
                }
              />
              <span className='form-check-label'>Male</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-2'>
              <input
                className='form-check-input'
                type='radio'
                name='gender'
                defaultValue={`female`}
                value={filters.gender}
                onChange={() =>
                  setFilters((prev) => {
                    return {...prev, gender: 'Female'}
                  })
                }
              />
              <span className='form-check-label'>Female</span>
            </label>
          </div>
        </div>
        <div className='mb-10 d-flex'>
          {xFilters.map((item, index) => (
            <div className='xFilterItem' key={index}>
              <label className='form-label fw-bold'>{item.name}</label>
              <div className='d-flex'>
                <input
                  type='number'
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Min Value'
                  step='0.01'
                  defaultValue={filters[item.filterArea]?.min}
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters[item.filterArea] = {
                      max: deepFilters[item.filterArea]?.max,
                      min: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`${item.name}Min`}
                />
                <input
                  type='number'
                  step='0.01'
                  defaultValue={filters[item.filterArea]?.max}
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Max Value'
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters[item.filterArea] = {
                      min: deepFilters[item.filterArea]?.min,
                      max: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`${item.name}Max`}
                />
              </div>
            </div>
          ))}
        </div>
        <div className='xFilterItem mt-2'>
          <div className='d-flex'>
            <div>
              <label className='form-label fw-bold'>Total Reads Count</label>
              <div className='d-flex'>
                <input
                  type='number'
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Min Value'
                  step='0.01'
                  value={filters['reads']?.min}
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters['reads'] = {
                      max: deepFilters['reads']?.max,
                      min: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`readsMin`}
                />
                <input
                  type='number'
                  step='0.01'
                  value={filters['reads']?.max}
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Max Value'
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters['reads'] = {
                      min: deepFilters['reads']?.min,
                      max: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`readsMax`}
                />
              </div>
            </div>
            <div>
              <label className='form-label fw-bold'>RoH</label>
              <div className='d-flex'>
                <input
                  type='number'
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Min Value'
                  step='0.01'
                  value={filters['RoH']?.min}
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters['RoH'] = {
                      max: deepFilters['RoH']?.max,
                      min: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`RoH Min`}
                />
                <input
                  type='number'
                  step='0.01'
                  value={filters['RoH']?.max}
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Max Value'
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters['RoH'] = {
                      min: deepFilters['RoH']?.min,
                      max: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`RoH Max`}
                />
              </div>
            </div>
            <div>
              <label className='form-label fw-bold'>Contamination</label>
              <div className='d-flex'>
                <input
                  type='number'
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Min Value'
                  step='0.01'
                  value={filters['contamination']?.min}
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters['contamination'] = {
                      max: deepFilters['contamination']?.max,
                      min: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`contamination Min`}
                />
                <input
                  type='number'
                  step='0.01'
                  value={filters['contamination']?.max}
                  className='form-control  form-control-sm form-control-solid mb-3 mx-2'
                  placeholder='Max Value'
                  onChange={(e) => {
                    const deepFilters = {...filters}
                    deepFilters['contamination'] = {
                      min: deepFilters['contamination']?.min,
                      max: Number(e.target.value),
                    }
                    setFilters(deepFilters)
                  }}
                  name={`contamination Max`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            onClick={clearAdvancedFilter}
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-primary'
            onClick={advancedFilterSubmitHandler}
            data-kt-menu-dismiss='true'
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
