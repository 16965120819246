/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import RunsTable from '../../components/RunsTable'

const RunsPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'MENU.RUNS'})}</PageTitle>
      <RunsTable />
    </>
  )
}

export {RunsPage}
