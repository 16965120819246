/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'

const OverView = ({processType, tmbLevel, msiLevel, loh}) => {
  const intl = useIntl()
  return (
    // OverView
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'OVERVIEW'})}</span>
        </h3>
      </div>

      <div className='card-body py-0 px-2 '>
        {(processType === 'som-test-tumor-rna' || processType === 'som-test-tumor') && (
          <div className='timeline-label my-4'>
            <div className='timeline-item'>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                TMB Level :
              </div>
              <div className='fw-mormal timeline-content text-muted ps-3'>
                 {tmbLevel}
              </div>
            </div>
          </div>
        )}
        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>MSI Level :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
               {msiLevel}
            </div>
          </div>
        </div>

        <div className='timeline-label my-4'>
          <div className='timeline-item'>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>LOH :</div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
               {loh}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {OverView}
