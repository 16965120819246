import axios from 'axios'
const currentUser =  JSON.parse(localStorage.getItem('user'));

const addNewTemplate = async (file) => {
  const formData = new FormData()
  formData.append('template', file, file.name)
  const res = await axios.post('https://render.carbone.io/template', formData, {
    headers: {
      Authorization: process.env.REACT_APP_CARBONE_API_KEY ?? 'test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3MDQxODc2NjA0MjU1ODM0MTUiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzU1NjYwOTEwLCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AbU5TFdSZ0AO_63izLicXVM63RrMt1ucjugN2iRv7K4UMMlhOlDQLUtCdNy4ZK7l7sYcYlNwo2FSHPL7fw4GO55tALAnViFe2_iXSQBnAyTnh8W8hZzAe-zeVra9Qi9iz80d52sx3u1vaW5PhjREPtcynwphD9hdyhP0B1N3szLOSA7V',
      'carbone-version': '4',
    },
  })

  return res.data
}
const addTemplateToDb = async (file,templateName) => { 
  console.log(file)

  const formData = new FormData()
  formData.append("name",templateName)
  formData.append("filename",file.name)
  formData.append("data",file)
  const res = await axios.post(process.env.REACT_APP_API_BASE_URL + 'templates',formData, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${currentUser.token}`, 
    },
  })

  return res.data
}

const renderReports = async (id, data) => {
  const res = await axios.post(
    `https://api.carbone.io/render/${id}`,
    {
      data,
      convertTo: 'pdf',
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_CARBONE_API_KEY ?? 'test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3MTI4MDcyMzg1NTE1NDEyNTYiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzU2Njg4NDQ0LCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.Ae6cnnjL2l3jPzwkQiCRf0OVY3AXZplTyA2mKx5MaMXZMoDSL8fIWqgzdrBui-sAaEnl9VzvuVSpdzaIStPnfQ6qAUeWqWsQ2N0FEKa1JgWojpOwjdyhYqjhHpbYhrYLbcSNDt36Iq6zBejECCQgfbiGxPr6CYL9v4YvWzONKBPv0nET.eyJpc3MiOiI3MDQxODc2NjA0MjU1ODM0MTUiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzU1NjYwOTEwLCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AbU5TFdSZ0AO_63izLicXVM63RrMt1ucjugN2iRv7K4UMMlhOlDQLUtCdNy4ZK7l7sYcYlNwo2FSHPL7fw4GO55tALAnViFe2_iXSQBnAyTnh8W8hZzAe-zeVra9Qi9iz80d52sx3u1vaW5PhjREPtcynwphD9hdyhP0B1N3szLOSA7V',
        'carbone-version': '4',
      },
    }
  )

  return res.data
}
const downloadReports = async (id) => {
  const res = await axios.get(`https://api.carbone.io/render/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_CARBONE_API_KEY ?? 'test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3MDQxODc2NjA0MjU1ODM0MTUiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzU1NjYwOTEwLCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AbU5TFdSZ0AO_63izLicXVM63RrMt1ucjugN2iRv7K4UMMlhOlDQLUtCdNy4ZK7l7sYcYlNwo2FSHPL7fw4GO55tALAnViFe2_iXSQBnAyTnh8W8hZzAe-zeVra9Qi9iz80d52sx3u1vaW5PhjREPtcynwphD9hdyhP0B1N3szLOSA7V',
      'carbone-version': '4',
    },
  })

  return res.data;
}

const CarboneService = {addNewTemplate, renderReports,downloadReports,addTemplateToDb}

export default CarboneService
