/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const SoloFamilyFilter = ({sampleId, setFamilyFilterDatas, familyFilterDatas}) => {
  return (
    <div className='familyFilterBody my-5'>
      <div className='familyFilterParents col-md-6'>
        <div className='familyItem d-flex justify-content-start align-items-center my-2'>
          <label className=''>Proband :</label>
          <span className='mx-3'> {sampleId}</span>
        </div>
      </div>
      <div className='familyFilterCheckboxes '>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              onChange={(e) => {
                familyFilterDatas.filterDetails.dominant = e.target.checked
                setFamilyFilterDatas(familyFilterDatas)
              }}
              defaultChecked={true}
            />
            <span
              className='fw-bold ps-2 fs-6'
              data-tooltip-id='my-tooltip'
              data-tooltip-content=' Returns heterozygous variants of the proband.'
            >
              Dominant
            </span>
          </label>
        </div>
        <div className='checkboxesItem my-3'>
          <label className='form-check form-check-inline form-check-solid'>
            <input
              className='form-check-input'
              name='communication[]'
              type='checkbox'
              onChange={(e) => {
                familyFilterDatas.filterDetails.recessive = e.target.checked
                setFamilyFilterDatas(familyFilterDatas)
              }}
              defaultChecked={false}
            />
            <span
              className='fw-bold ps-2 fs-6'
              data-tooltip-id='my-tooltip'
              data-tooltip-content=' Returns homozygous, hemizygous variants of the proband.'
            >
              Recessive
            </span>
          </label>
        </div>

        <Tooltip id='my-tooltip' />
      </div>
    </div>

    //  Card End
  )
}

export {SoloFamilyFilter}
