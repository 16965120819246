import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import somaticService from '../../service/somatic'
import {useParams} from 'react-router'
import {Loader} from '../../components/Loader'
import TableComponent from '../../components/TableComponent/TableComponent'
import ProteinPaintChart from './Protein'
import {ClinicDrawer} from '../../components/SomaticTable/Widgets/ClinicDrawer'
import {Tooltip} from 'react-tooltip'
import ClinicAdvancedFilterModal from '../../components/SomaticTable/Widgets/ClinicAdvancedFilterModal'

const SomaticDetailClinicPage = () => {
  const {id} = useParams()
  const [tableData, setTableData] = useState({snvData: [], svData: [], fusionsData: []})
  const [originalData, setOriginalData] = useState([])

  const [totalVariants, setTotalVariants] = useState(0)
  const [filteredTotalVariants, setFilteredTotalVariants] = useState(0)
  const [adScore, setAdScore] = useState({
    min: 0,
    max: 0,
  })
  const [openAccordion, setOpenAccordion] = useState(null) // Yeni state
  const [totalGenes, setTotalGenes] = useState(0)
  const [groupedData, setGroupedData] = useState({})
  const [filteredTotalGenes, setFilteredTotalGenes] = useState(0)
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [cosmicData, setCosmicData] = useState([])
  const [gridOptions, setGridOptions] = useState(undefined)
  const [lodSwitch, setLodSwitch] = useState(true)
  const canverEvTooltips = {
    CBP1: 'Therapeutic: FDA approved or investigational with strong evidence',
    CBP2: 'Diagnostic: In Professional guideline or reported evidence with consensus',
    CBP3: 'Prognostic: In Professional guideline or reported evidence with consensus',
    CBP4: 'Mutation type: Activating, LOF (missense, nonsense, indel, splicing), CNVs, fusions',
    CBP5: 'Variant frequencies: Mostly mosaic',
    CBP6: 'Potential germline: Mostly nonmosaic',
    CBP7: 'Population databases: Absent or extremely low MAF',
    CBP8: 'Germline databases: may be present in HGMD/ClinVar Pathogenic',
    CBP9: 'Somatic databases: Most present in COSMIC, My Cancer Genome, TCGA',
    CBP10:
      'Predictive from: SIFT, PolyPhen2, MutationTaster, CADD, MetaSVM, MetaLR, FATHMM, GERP++_RS, and mostly as Pathogenic',
    CBP11: 'Pathway: involve in Disease-associated pathways or pathogenic pathways',
    CBP12: 'Publications: Convincing evidence from Functional study, population study, other',
    CBP13: 'Additional: user specified',
  }
  const [snvHeader, setSnvHeader] = useState({
    // 'ExonicFunc_refGene',
    // 'txChange',
    // 'aaChange',
    // 'AD',
    // 'DP',
    // 'REF',
    // 'ALT',
    Variant_Type: {
      value: 'Variant Type',
    },
    ExonicFunc_refGene: {
      value: 'Exonic Function',
    },
    txChange: {
      value: 'Transcript Change',
    },
    aaChange: {
      value: 'AA Change',
    },
    AD: {
      value: 'Allelic Depth',
    },
    DP: {
      value: 'Read Depth',
    },
    Ref: {
      value: 'Reference',
    },
    Alt: {
      value: 'Alternative',
    },
    CancerVar_Tier: {
      value: 'CancerVar Tier',
    },
  })

  const [selectedTab, setSelectedTab] = useState()
  const [selectedRow, setSelectedRow] = useState({})
  const [isClinic, setIsClinic] = useState(true)
  const [snvSelectedTableDatas, setSnvSelectedTableDatas] = useState([])
  const [allelicWidgetDatas, setAllelicWidgetDatas] = useState([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({
    plof: true,
    missense: true,
    synonymous: true,
    others: true,
    ongocenic: true,
    likelyOncogenic: false,
    tier1: true,
    tier2: true,
    tier3: false,
  })
  const getUniqueGenesCount = (data) => {
    let genes = data.map((item) => item.Gene_refGene).flat()

    let uniqueGenes = [...new Set(genes)]
    return uniqueGenes.length
  }
  const getUniqueGenes = (data) => {
    let genes = data.map((item) => item.Gene_refGene).flat()

    let uniqueGenes = [...new Set(genes)]
    return uniqueGenes
  }

  const groupByGene = (data) => {
    let geneGroups = {}

    data.forEach((item) => {
      item.Gene_refGene.forEach((gene) => {
        if (geneGroups[gene]) {
          geneGroups[gene].push(item)
        } else {
          geneGroups[gene] = [item]
        }
      })
    })

    return geneGroups
  }
  const fetchVariantData = async (variantType) => {
    try {
      const requestId = id.split('&')
      const variantReq = await somaticService.getSomaticVariants(requestId[0], requestId[1], [
        variantType,
      ])
      if (variantReq.status === 200) {
        var newData = variantReq.data[`${variantType}Data`].rows
        setOriginalData(newData)
        if (newData.length === 0) {
          setIsLoadingData(false)
          return
        }
        const plofKeywords = [
          'stopgain',
          'frameshift deletion',
          'frameshift insertion',
          'stoploss',
          'startlost',
          'start gained',
          'splicing site',
        ]
        const missenseKeywords = ['nonsynonymous SNV']
        const synonymousKeywords = ['synonymous SNV']
        const otherKeywords = [
          'nonframeshift deletion',
          'nonframeshift substitution',
          'nonframeshift insertion',
          'unknown',
        ]

        newData = newData.map((item) => {
          let exonicFuncs = item.ExonicFunc_refGene

          if (!Array.isArray(exonicFuncs)) {
            exonicFuncs = [exonicFuncs]
          }

          // Varsayılan tür "others" olarak belirleniyor
          let type = 'others'

          exonicFuncs.forEach((func) => {
            if (plofKeywords.includes(func)) {
              type = 'plof'
            } else if (missenseKeywords.includes(func)) {
              type = 'missense'
            } else if (synonymousKeywords.includes(func)) {
              type = 'synonymous'
            }
          })

          return {
            ...item,
            type, // Satıra 'type' özelliğini ekliyoruz
          }
        })

        setTotalVariants(newData.length)
        const adScoreArray = newData.map((item) => item.AD)
        const minAdScore = Math.min(...adScoreArray)
        const maxAdScore = Math.max(...adScoreArray)
        setAdScore({
          min: minAdScore,
          max: maxAdScore,
        })

        setTotalGenes(getUniqueGenesCount(newData))

        var forCosmic = newData.filter((item) => {
          return (
            item?.Filter[0] === 'PASS' &&
            (item?.CancerVar_Tier === 'Tier I strong ' ||
              item?.CancerVar_Tier === 'Tier II potential ' ||
              item?.CancerVar_Tier === 'Tier III Uncertain ' ||
              item?.Oncogenic === 'Likely Oncogenic' ||
              item?.Oncogenic === 'Oncogenic')
          )
        })
        newData = newData.filter((item) => {
          return (
            item?.Filter[0] === 'PASS' &&
            ((filters.tier1 && item?.CancerVar_Tier === 'Tier I strong ') || // filters.tier1 true ise kontrol edilir
              (filters.tier2 && item?.CancerVar_Tier === 'Tier II potential ') || // filters.tier2 true ise kontrol edilir
              (filters.tier3 && item?.CancerVar_Tier === 'Tier III Uncertain ') || // filters.tier3 true ise kontrol edilir
              (filters.ongocenic && item?.Oncogenic === 'Oncogenic') || // filters.ongocenic true ise kontrol edilir
              (filters.likelyOncogenic && item?.Oncogenic === 'Likely Oncogenic')) // filters.likelyOncogenic true ise kontrol edilir
          )
        })
        var uniqueGenes = getUniqueGenes(newData)
        var forCosmicUniqueGenes = getUniqueGenes(forCosmic)

        const cosmicData = await somaticService.getClinicCosmic(forCosmicUniqueGenes)

        setCosmicData(cosmicData)

        if (lodSwitch) {
          newData = newData.filter((item) => item.DET === 'Yes')
        }

        const groupedData = groupByGene(newData)
        // cosmicData.data.forEach((item) => {
        //   groupedData[item.gene_name].cosmic = item
        // })
        // console.log(groupedData)
        setFilteredTotalVariants(newData.length)
        setFilteredTotalGenes(getUniqueGenesCount(newData))

        setGroupedData(groupedData)
        setTableData((prev) => ({
          ...prev,
          [`${variantType}Data`]: newData,
        }))
        setIsLoadingData(false)
      }
    } catch (error) {
      console.error('Failed to fetch variant data:', error)
    }
  }

  useEffect(() => {
    const loadVariantData = async () => {
      if (tableData[`snvData`] && tableData[`snvData`].length > 0) {
        return
      }
      setIsLoadingData(true)
      await fetchVariantData('snv')
    }

    loadVariantData()
  }, [])
  useEffect(() => {
    if (Object.keys(selectedRow).length > 0) {
      setDrawerOpen(true) // Eğer selectedRow boş değilse, Drawer'ı aç
    } else {
      setDrawerOpen(false) // Eğer selectedRow boşsa, Drawer'ı kapat
    }
  }, [selectedRow]) // selectedRow değiştiğinde çalışacak

  const categorizeExonicFunc = (data) => {
    const plofKeywords = [
      'stopgain',
      'frameshift deletion',
      'frameshift insertion',
      'stoploss',
      'startlost',
      'start gained',
      'splicing site',
    ]
    const missenseKeywords = ['nonsynonymous SNV']
    const synonymousKeywords = ['synonymous SNV']
    const otherKeywords = [
      'nonframeshift deletion',
      'nonframeshift substitution',
      'nonframeshift insertion',
      'unknown',
    ]

    let categorizedCounts = {
      plof: 0,
      missense: 0,
      synonymous: 0,
      others: 0,
    }

    data.forEach((item) => {
      let exonicFuncs = item.ExonicFunc_refGene

      // Eğer exonicFuncs bir array değilse, onu array yapalım ki forEach ile gezebilelim
      if (!Array.isArray(exonicFuncs)) {
        exonicFuncs = [exonicFuncs]
      }

      exonicFuncs.forEach((func) => {
        if (plofKeywords.includes(func)) {
          categorizedCounts.plof += 1
        } else if (missenseKeywords.includes(func)) {
          categorizedCounts.missense += 1
        } else if (synonymousKeywords.includes(func)) {
          categorizedCounts.synonymous += 1
        } else if (otherKeywords.includes(func)) {
          categorizedCounts.others += 1
        } else {
          categorizedCounts.others += 1 // Uyuşmayan kategorileri "others" kategorisine ekle
        }
      })
    })

    return categorizedCounts
  }
  const applyFilters = (data) => {
    return data.filter((item) => {
      if (filters.plof && item.type === 'plof') return true
      if (filters.missense && item.type === 'missense') return true
      if (filters.synonymous && item.type === 'synonymous') return true
      if (filters.others && item.type === 'others') return true
      return false // Hiçbir filtreye uymayanlar gösterilmeyecek
    })
  }
  useEffect(() => {
    const filteredGeneCounts = () => {
      // Tüm genler üzerinde filtreleri uygula ve filtreye uyan genleri say
      let filteredGenes = Object.keys(groupedData).filter((gene) => {
        const filteredVariants = applyFilters(groupedData[gene])
        return filteredVariants.length > 0 // Filtrelenmiş veri varsa, bu geni say
      })

      // Filtrelenmiş toplam gen sayısını state'e set et
      setFilteredTotalGenes(filteredGenes.length)
    }
    const updateFilteredTotalVariants = () => {
      // Her bir gene ait varyantları filtrele ve sadece filtreye uyan varyantların toplamını al
      let filteredVariantsCount = 0

      Object.keys(groupedData).forEach((gene) => {
        const filteredVariants = applyFilters(groupedData[gene])
        filteredVariantsCount += filteredVariants.length // Filtreye uyan varyantların sayısını ekle
      })

      // Filtrelenmiş toplam varyant sayısını state'e set et
      setFilteredTotalVariants(filteredVariantsCount)
    }
    const updateDepthScores = () => {
      let filteredDepths = []

      // Filtrelenmiş verilerdeki AD değerlerini topla
      Object.keys(groupedData).forEach((gene) => {
        const filteredVariants = applyFilters(groupedData[gene])
        filteredDepths.push(...filteredVariants.map((item) => item.AD)) // AD değerlerini topluyoruz
      })

      // Eğer herhangi bir veri varsa min ve max değerlerini hesapla
      if (filteredDepths.length > 0) {
        const minAdScore = Math.min(...filteredDepths)
        const maxAdScore = Math.max(...filteredDepths)

        // Depth skorlarını güncelle
        setAdScore({
          min: minAdScore,
          max: maxAdScore,
        })
      } else {
        // Eğer filtrelenmiş veri yoksa min ve max değerleri sıfır olabilir
        setAdScore({
          min: 0,
          max: 0,
        })
      }
    }

    // updateDepthScores()
    updateFilteredTotalVariants()
    filteredGeneCounts()
  }, [filters, groupedData])

  // lodSwitch değiştiğinde filtre işlemi
  useEffect(() => {
    let filteredData = originalData

    const plofKeywords = [
      'stopgain',
      'frameshift deletion',
      'frameshift insertion',
      'stoploss',
      'startlost',
      'start gained',
      'splicing site',
    ]
    const missenseKeywords = ['nonsynonymous SNV']
    const synonymousKeywords = ['synonymous SNV']
    const otherKeywords = [
      'nonframeshift deletion',
      'nonframeshift substitution',
      'nonframeshift insertion',
      'unknown',
    ]

    let categorizedCounts = {
      plof: 0,
      missense: 0,
      synonymous: 0,
      others: 0,
    }

    filteredData = filteredData.map((item) => {
      let exonicFuncs = item.ExonicFunc_refGene

      if (!Array.isArray(exonicFuncs)) {
        exonicFuncs = [exonicFuncs]
      }

      // Varsayılan tür "others" olarak belirleniyor
      let type = 'others'

      exonicFuncs.forEach((func) => {
        if (plofKeywords.includes(func)) {
          type = 'plof'
        } else if (missenseKeywords.includes(func)) {
          type = 'missense'
        } else if (synonymousKeywords.includes(func)) {
          type = 'synonymous'
        }
      })

      return {
        ...item,
        type, // Satıra 'type' özelliğini ekliyoruz
      }
    })

    filteredData = filteredData.filter((item) => {
      return (
        item?.Filter[0] === 'PASS' &&
        ((filters.tier1 && item?.CancerVar_Tier === 'Tier I strong ') || // filters.tier1 true ise kontrol edilir
          (filters.tier2 && item?.CancerVar_Tier === 'Tier II potential ') || // filters.tier2 true ise kontrol edilir
          (filters.tier3 && item?.CancerVar_Tier === 'Tier III Uncertain ') || // filters.tier3 true ise kontrol edilir
          (filters.ongocenic && item?.Oncogenic === 'Oncogenic') || // filters.ongocenic true ise kontrol edilir
          (filters.likelyOncogenic && item?.Oncogenic === 'Likely Oncogenic')) // filters.likelyOncogenic true ise kontrol edilir
      )
    })
    if (lodSwitch) {
      filteredData = filteredData.filter((item) => item.DET === 'Yes')
    }

    // Filtrelenmiş veriyi tableData'ya set ediyoruz
    setTableData((prev) => ({
      ...prev,
      snvData: filteredData,
    }))

    // Filtreleme sonrası diğer işlemler (örneğin: depth hesaplama)
    // const adScoreArray = filteredData.map((item) => item.AD)
    // const minAdScore = Math.min(...adScoreArray)
    // const maxAdScore = Math.max(...adScoreArray)

    // setAdScore({
    //   min: minAdScore,
    //   max: maxAdScore,
    // })

    // setTotalVariants(filteredData.length)
    setFilteredTotalVariants(filteredData.length)
    // setTotalGenes(getUniqueGenesCount(filteredData))
    setFilteredTotalGenes(getUniqueGenesCount(filteredData))
    setOpenAccordion(null)
    setGroupedData(groupByGene(filteredData))
  }, [lodSwitch, originalData, filters])

  const adjustGridHeight = () => {
    const slickGridContainer = document.querySelector('.slickgrid-container')
    const slickGridCanvas = document.querySelector('.grid-canvas')
    const cancerVarTierColumn = document.querySelector(
      '.slick-header-column[data-id="CancerVar_Tier"]'
    )

    if (cancerVarTierColumn) {
      cancerVarTierColumn.setAttribute('data-tooltip-id', 'cancervar-tooltip')
      cancerVarTierColumn.setAttribute('data-tooltip-content', `sa`)
      cancerVarTierColumn.setAttribute('data-tooltip-place', `left`)
    }
    if (slickGridContainer && slickGridCanvas) {
      const rowCount = slickGridCanvas.children.length

      if (rowCount < 10) {
        // Dinamik sınıf oluştur
        const dynamicClassName = `grid-height-${rowCount}`
        const res = rowCount > 6 ? 30 : rowCount > 3 ? 40 : 50
        const dynamicHeight = `${rowCount * res}px`

        // Dinamik CSS oluştur
        const style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = `
          .${dynamicClassName} {
            height: ${dynamicHeight} !important;
            min-height: 100px !important;
          }
        `

        // Daha önceki dinamik sınıfları temizlemek için
        const existingStyle = document.getElementById('dynamic-grid-height')
        if (existingStyle) {
          existingStyle.remove()
        }

        // Yeni stil etiketini head'e ekleyin
        style.id = 'dynamic-grid-height'
        document.head.appendChild(style)

        // Dinamik sınıfı slickGridContainer'a ekle
        slickGridContainer.classList.add(dynamicClassName)
        document.querySelector('#grid-container').style.height = `${
          rowCount === 1 ? '135px' : rowCount * res + 75
        }px`
      }
    }
  }

  // useEffect ile data veya grid her değiştiğinde tablo yüksekliğini güncelle
  useEffect(() => {
    if (openAccordion !== null) {
      setTimeout(() => {
        adjustGridHeight() // Accordion açıldıktan sonra yükseklik ayarını yap
      }, 100) // 100ms gecikme ile grid'in tamamen render edilmesini bekle

      const observer = new MutationObserver(() => {
        adjustGridHeight()
      })

      const slickGridCanvas = document.querySelector('.grid-canvas')
      if (slickGridCanvas) {
        observer.observe(slickGridCanvas, {childList: true})
      }

      return () => {
        observer.disconnect()
      }
    }
  }, [openAccordion])
  return (
    <>
      <div className='row'>
        <div className='card card-custom'>
          <div className='card-body table-card'>
            <PageTitle breadcrumbs={[]}>Somatic Details</PageTitle>

            {/* <ProteinPaintChart /> */}
            {!isLoadingData ? (
              <div class=' '>
                <div class='col-md-12 d-flex gap-5  align-items-center justify-content-space-between'>
                  <div
                    class='circle-genes'
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    {filteredTotalGenes}/{totalGenes} <br />
                    <small
                      style={{
                        fontWeight: 'normal',
                      }}
                    >
                      {' '}
                      Genes
                    </small>
                  </div>
                  <div class='border border-gray-300 border-dashed rounded d-flex gap-5  py-3 px-4 me-7 mb-3'>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div class='fs-6 text-gray-800 fw-bolder'>{totalVariants}</div>
                      <div class='fw-bold text-gray-500 fs-8'>Variants</div>
                    </div>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div class='fs-6 text-gray-800 fw-bolder'>{filteredTotalVariants}</div>
                      <div class='fw-bold text-gray-500 fs-8'>Retained</div>
                    </div>
                    <div>
                      <div class='fs-6 text-gray-800 fw-bolder'>
                        {totalVariants - filteredTotalVariants}
                      </div>
                      <div class='fw-bold text-gray-500 fs-8'>Low Confidence</div>
                    </div>
                  </div>
                  {/* <div class='border border-gray-300 border-dashed rounded  py-3 px-4 me-7 mb-3'></div>
                  <div class='border border-gray-300 border-dashed rounded  py-3 px-4 me-7 mb-3'>
                    <div class='fs-6 text-gray-800 fw-bolder'>
                      {totalVariants - filteredTotalVariants}
                    </div>
                    <div class='fw-bold text-gray-500 fs-8'>Low Confidence</div>
                  </div> */}
                  <div class='border border-gray-300 border-dashed rounded d-flex gap-5 py-3 px-4 me-7 mb-3'>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div class='fs-6 text-gray-800 fw-bolder'>{adScore.min}</div>
                      <div class='fw-bold text-gray-500 fs-8'>Depth Min</div>
                    </div>
                    <div>
                      <div class='fs-6 text-gray-800 fw-bolder'>{adScore.max}</div>
                      <div class='fw-bold text-gray-500 fs-8'>Depth Max</div>
                    </div>
                  </div>
                  {/* <div class='border border-gray-300 border-dashed rounded  py-3 px-4 me-7 mb-3'>
                    <div class='fs-6 text-gray-800 fw-bolder'>{adScore.max}</div>
                    <div class='fw-bold text-gray-500 fs-8'>Depth Max</div>
                  </div> */}
                  <div
                    className='filter-area d-flex gap-5 ml-auto'
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <span
                      className='filter-title'
                      style={{
                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed',
                        color: '#99a1b7',
                        letterSpacing: '0.1em',
                      }}
                    >
                      {' '}
                      - FILTER -
                    </span>
                    <div className='filter-options'>
                      <div className='type-filter border-bottom  py-2  '>
                        <label className='form-check form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name='plof'
                            type='checkbox'
                            onChange={(e) =>
                              setFilters((prev) => ({...prev, plof: e.target.checked}))
                            }
                            defaultChecked={filters.plof}
                          />
                          <span className='fw-bold ps-2 fs-6'>pLoF</span>
                        </label>

                        <label className='form-check form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name='missense'
                            type='checkbox'
                            onChange={(e) =>
                              setFilters((prev) => ({...prev, missense: e.target.checked}))
                            }
                            defaultChecked={filters.missense}
                          />
                          <span className='fw-bold ps-2 fs-6'>Missense</span>
                        </label>

                        <label className='form-check form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name='synonymous'
                            type='checkbox'
                            onChange={(e) =>
                              setFilters((prev) => ({...prev, synonymous: e.target.checked}))
                            }
                            defaultChecked={filters.synonymous}
                          />
                          <span className='fw-bold ps-2 fs-6'>Synonymous</span>
                        </label>

                        <label className='form-check form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name='others'
                            type='checkbox'
                            onChange={(e) =>
                              setFilters((prev) => ({...prev, others: e.target.checked}))
                            }
                            defaultChecked={filters.others}
                          />
                          <span className='fw-bold ps-2 fs-6'>Others</span>
                        </label>
                        <label className='form-check form-check-inline form-check-solid'>
                          <input
                            className='form-check-input'
                            name='others'
                            type='checkbox'
                            onChange={(e) =>
                              setFilters((prev) => ({...prev, others: e.target.checked}))
                            }
                            defaultChecked={filters.others}
                          />
                          f<span className='fw-bold ps-2 fs-6'>Others</span>
                        </label>
                      </div>

                      <div className='lod-switcher mt-3 d-flex align-items-center justify-content-between'>
                        <div
                          className=' form-check form-switch form-switch-sm form-check-custom form-check-solid'
                          data-tooltip-id='my-tooltip'
                          data-tooltip-content='Within Limit of Detection parameters.'
                          style={{
                            display: 'inline-block',
                          }}
                        >
                          <label className='font-weight-bold '> LoD </label>
                          <input
                            className='form-check-input mx-3'
                            type='checkbox'
                            onChange={(e) => setLodSwitch(e.target.checked)}
                            defaultChecked={lodSwitch}
                            name='lodSwitch'
                          />
                        </div>
                        <div className='advanced-filter pull-right'>
                          <button
                            className='btn btn-primary btn-sm my-2 pull-right dropdown-toggle' // dropdown-toggle sınıfı ekleniyor
                            data-bs-toggle='dropdown' // Bootstrap dropdown menü için bu özelliği ekliyoruz
                            aria-expanded='false' // Dropdown kapalı olarak başlayacak
                          >
                            Advanced Filter
                          </button>
                          <div
                            className='dropdown-menu' // Menüyü dropdown olarak belirtiyoruz
                            style={{minWidth: '200px'}} // İsteğe bağlı olarak menünün genişliğini ayarlayabilirsiniz
                          >
                            {/* <div className='px-7 py-5'>
                              <div className='fs-5 text-dark fw-bolder'>Advanced Filters</div>
                            </div> */}
                            <div className='px-7 '>
                              <div className='type-filter border-bottom  py-2  my-3 '>
                                <label className='form-check form-check-inline form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    name='tier1'
                                    type='checkbox'
                                    onChange={(e) =>
                                      setFilters((prev) => ({...prev, tier1: e.target.checked}))
                                    }
                                    defaultChecked={filters.tier1}
                                  />
                                  <span className='fw-bold ps-2 fs-6'>Tier I strong </span>
                                </label>

                                <label className='form-check form-check-inline form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    name='tier2'
                                    type='checkbox'
                                    onChange={(e) =>
                                      setFilters((prev) => ({...prev, tier2: e.target.checked}))
                                    }
                                    defaultChecked={filters.tier2}
                                  />
                                  <span className='fw-bold ps-2 fs-6'>Tier II potential</span>
                                </label>

                                <label className='form-check form-check-inline form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    name='tier3'
                                    type='checkbox'
                                    onChange={(e) =>
                                      setFilters((prev) => ({
                                        ...prev,
                                        tier3: e.target.checked,
                                      }))
                                    }
                                    defaultChecked={filters.tier3}
                                  />
                                  <span className='fw-bold ps-2 fs-6'>Tier III Uncertain</span>
                                </label>
                              </div>
                              <div className='type-filter border-bottom  py-2  my-3 '>
                                <label className='form-check form-check-inline form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    name='ongocenic'
                                    type='checkbox'
                                    onChange={(e) =>
                                      setFilters((prev) => ({...prev, ongocenic: e.target.checked}))
                                    }
                                    defaultChecked={filters.ongocenic}
                                  />
                                  <span className='fw-bold ps-2 fs-6'>Oncogenic </span>
                                </label>

                                <label className='form-check form-check-inline form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    name='likelyOncogenic'
                                    type='checkbox'
                                    onChange={(e) =>
                                      setFilters((prev) => ({
                                        ...prev,
                                        likelyOncogenic: e.target.checked,
                                      }))
                                    }
                                    defaultChecked={filters.likelyOncogenic}
                                  />
                                  <span className='fw-bold ps-2 fs-6'>Likely Oncogenic</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(groupedData).length === 0 && (
                  <div className='alert alert-custom alert-light-danger text-center' role='alert'>
                    <div className='alert-text'>
                      <h3 className='fw-bolder'>No data found!</h3>
                      <p>There is no data to display.</p>
                    </div>
                  </div>
                )}
                <div className='accordion mt-5' id='kt_accordion_1'>
                  {Object.keys(groupedData).length > 0 &&
                    Object.keys(groupedData).map((gene, index) => {
                      const filteredDataa = applyFilters(groupedData[gene])
                      if (filteredDataa.length === 0) {
                        return null
                      }
                      const categorizedCounts = categorizeExonicFunc(groupedData[gene])
                      const isAccordionOpen = openAccordion === index

                      const geneCosmicData =
                        cosmicData.data && cosmicData.data.find((item) => item.gene_name === gene)

                      const plofCount = filters.plof ? categorizedCounts.plof : 0
                      const missenseCount = filters.missense ? categorizedCounts.missense : 0
                      const synonymousCount = filters.synonymous ? categorizedCounts.synonymous : 0
                      const othersCount = filters.others ? categorizedCounts.others : 0
                      return (
                        <div className='accordion-item' key={index}>
                          <h2 className='accordion-header' id={`kt_accordion_1_header_${index}`}>
                            <button
                              className='accordion-button fs-4 fw-bold collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#kt_accordion_1_body_${index}`}
                              aria-expanded={isAccordionOpen}
                              aria-controls={`kt_accordion_1_body_${index}`}
                              onClick={() => setOpenAccordion(isAccordionOpen ? null : index)} // Tıklayınca accordion aç/kapa
                            >
                              <span className='gene-name fst-italic' style={{minWidth: '75px'}}>
                                {' '}
                                {gene}{' '}
                              </span>
                              <span
                                className='exonics d-flex gap-3 fw-normal'
                                style={{marginLeft: '20px'}}
                              >
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      backgroundColor: '#FDA99C',
                                    }}
                                  ></div>
                                  pLoF: {plofCount}{' '}
                                </label>
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      backgroundColor: '#F5E2A4',
                                    }}
                                  ></div>
                                  Missense: {missenseCount}{' '}
                                </label>
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      backgroundColor: '#7DBD7D',
                                    }}
                                  ></div>
                                  Synonymous: {synonymousCount}{' '}
                                </label>
                                <label className='exonic-item d-flex align-items-center gap-2'>
                                  <div
                                    className='square '
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      backgroundColor: '#B7B7B7',
                                    }}
                                  ></div>
                                  Others: {othersCount}{' '}
                                </label>
                              </span>
                              <span className='cosmic mx-10 fw-normal'>
                                {geneCosmicData && (
                                  <label
                                    data-tooltip-id='cosmicTooltip'
                                    data-tooltip-content={geneCosmicData?.gene_name}
                                  >
                                    Site :{' '}
                                    {geneCosmicData?.sites ? geneCosmicData.sites.length : ''}
                                    <span className='mx-3'>| </span>
                                    Count :{' '}
                                    {geneCosmicData?.total_count ? geneCosmicData.total_count : ''}
                                  </label>
                                )}
                              </span>
                            </button>
                          </h2>
                          <div
                            id={`kt_accordion_1_body_${index}`}
                            className={`accordion-collapse collapse ${
                              isAccordionOpen ? 'show' : ''
                            }`} // Açık olan accordion için "show" class'ı
                            aria-labelledby={`kt_accordion_1_header_${index}`}
                            data-bs-parent='#kt_accordion_1'
                          >
                            <div className='accordion-body'>
                              {/* Sadece açık olan accordion için TableComponent render ediliyor */}
                              {isAccordionOpen && (
                                <TableComponent
                                  tableHeader={snvHeader}
                                  data={filteredDataa} // Filtrelenmiş veriyi tabloya gönderiyoruz
                                  isTreeData={false}
                                  isFilterable={false}
                                  selectedRows={snvSelectedTableDatas}
                                  autoHeight={false}
                                  setSelectedRows={setSnvSelectedTableDatas}
                                  gridId={'snvTableClinic'}
                                  gridOptions={gridOptions}
                                  canMultiSelect={false}
                                  setAllelicWidgetDatas={setAllelicWidgetDatas}
                                  isClinic={isClinic}
                                  setGridOptions={setGridOptions}
                                  selectedWidgetItem={selectedRow}
                                  setSelectedWidgetItem={setSelectedRow}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
                {/* <div class='col-md-4   d-flex  align-items-center gap-3'>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <span> 335</span> VARIANTS
                  </div>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <span> 52</span> RETAINED
                  </div>
                  <div className='d-flex flex-column align-items-center text-center'>
                    <span> 283</span> LOW CONFIDENCE
                  </div>
                </div>
                <div class='col-md-4 d-flex  align-items-center'>
                  <p>
                    <strong>VARIANT DEPTHS</strong>
                    <br />
                    Depth Min: 31
                    <br />
                    Depth Max: 4090
                  </p>
                </div> */}
                {/* <div class='col'>
                    <div class='circle'>32/32</div>
                    <p>WARNINGS</p>
                  </div> */}
              </div>
            ) : (
              <div className='card-body table-card text-center d-flex align-items-start justify-content-center'>
                <Loader />
              </div>
            )}
            <ClinicDrawer
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              allelicWidgetDatas={allelicWidgetDatas}
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            />
          </div>
        </div>
      </div>
      <Tooltip id='my-tooltip' />

      <Tooltip
        id='cancervar-tooltip'
        render={({content, activeAnchor}) => {
          // Eğer content veya canverEvTooltips eksikse null döndür
          if (!content || !canverEvTooltips) return null

          // Tooltip içeriğini canverEvTooltips'ten alıyoruz
          const data = Object.entries(canverEvTooltips)

          return (
            <ul
              className='row'
              style={{
                paddingLeft: '0',
                maxWidth: '600px',
              }}
            >
              {data.map(([key, value], index) => (
                <li
                  key={index}
                  className='col-md-12 d-flex justify-content-between align-items-center'
                  style={{
                    borderBottom: '0.5px solid rgb(163, 164, 170)',
                  }}
                >
                  <span>{key}:</span>{' '}
                  <span
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    {value}
                  </span>
                </li>
              ))}
            </ul>
          )
        }}
      />

      <Tooltip
        id='cosmicTooltip'
        render={({content, activeAnchor}) => {
          if (!content || !cosmicData) return null
          const data = cosmicData.data.filter((item) => item.gene_name === content)[0].sites
          return (
            <ul
              className=' row'
              style={{
                paddingLeft: '0',
                maxWidth: '600px',
              }}
            >
              {data.map((e, index) => (
                <li
                  key={index}
                  className='col-md-6 d-flex justify-content-between align-items-center'
                  style={{
                    borderBottom: '0.5px solid rgb(153, 161, 183)',
                  }}
                >
                  <span>{e[0]} </span> <span> {e[1]} </span>
                </li>
              ))}
            </ul>
          )
        }}
      />
      <ClinicAdvancedFilterModal filters={filters} setFilters={setFilters} />
    </>
  )
}

export {SomaticDetailClinicPage}
