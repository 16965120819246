import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../_metronic/helpers'
import { useState } from 'react'
const GenerateReportModal = ({reportPayload,setReportPayload,generateReportFormHandler}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <div className='modal fade bd-example-modal-lg ' tabIndex={-1} id='GenerateReportModal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Generate Report</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Name Surname</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name Surname'
                  onChange={ (e) => {
                    reportPayload.name=e.target.value
                    setReportPayload(reportPayload)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Protocol No</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Protocol No'
                  onChange={ (e) => {
                    reportPayload.protocolNo=e.target.value
                    setReportPayload(reportPayload)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Patient Name </span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Patient Name'
                  onChange={ (e) => {
                    reportPayload.patientName=e.target.value
                    setReportPayload(reportPayload)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Referral Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  onChange={ (e) => {
                    reportPayload.referralDate=e.target.value
                    setReportPayload(reportPayload)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Referring  Center</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Referring Center'
                  onChange={ (e) => {
                    reportPayload.referringCenter=e.target.value
                    setReportPayload(reportPayload)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Referring    Doctor</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Referring Doctor'
                  onChange={ (e) => {
                    reportPayload.referringDoctor=e.target.value
                    setReportPayload(reportPayload)
                  }}
                />
              </div>
            </div>
            
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
            <button type='button' className='btn btn-primary'
              onClick={generateReportFormHandler}
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateReportModal
