import {useState, useEffect} from 'react'
import './TableComponent.scss' // custom CSS/SASS styling
import {Column, GridOption, SlickgridReact} from 'slickgrid-react'
import {Loader} from '../../components/Loader'
import '../../../node_modules/@slickgrid-universal/common/dist/styles/css/slickgrid-theme-bootstrap.css'
import {VariantIdFormatter} from './Formatters'
import {Tooltip} from 'react-tooltip'
import {afbData} from '../../static/afb'

const AlleleFreqTableComponent = ({
  data,
  tableHeader,
  gridOptions,
  setGridOptions,
  hasFilter = true,
  gridId,
  handleSubmitVariant,
}) => {
  const [columnDefinitions, setColumnDefinitions] = useState<Column[]>([])

  useEffect(() => {
    setGridOptions(undefined)
    defineGrid()
  }, [data])

  const defineGrid = () => {
    const columnDefs = Object.keys(tableHeader).map((columnKey) => {
      let additionalProps = {}

      if (columnKey === 'variantId') {
        additionalProps = {
          formatter: VariantIdFormatter,
          onCellClick: (e) => {
            e.preventDefault()
            if (e.target.getAttribute('href') !== null && e.target.getAttribute('href') !== '#') {
              handleSubmitVariant(e.target.getAttribute('href'))
            }
          },
        }
      }

      return {
        id: columnKey,
        name: tableHeader[columnKey],
        field: columnKey,
        cssClass: 'cell-title',
        minWidth: 120,
        filterable: hasFilter,
        sortable: true,
        ...additionalProps,
      }
    })
    const gridOpts: GridOption = {
      autoResize: {
        container: '#demo-container',
        rightPadding: 10,
      },
      enableAutoSizeColumns: true,
      enableAutoResize: true,
      enableFiltering: hasFilter,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableTreeData: false,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },

      enablePagination: true,
      pagination: {
        pageSizes: [20, 25, 50, 100],
        pageSize: 100,
      },
      multiColumnSort: true,
      showCustomFooter: true,
      headerRowHeight: 40,
      rowHeight: 30,
    }
    setColumnDefinitions(columnDefs)
    setGridOptions(gridOpts)
  }

  if (data.length < 1) {
    return <div className='  text-center'>There is no data</div>
  }
  console.log(gridOptions)
  return data.length < 1 || !gridOptions || gridOptions === undefined ? (
    <div className='card'>
      <div className='card-body text-center'>
        <Loader />
      </div>
    </div>
  ) : (
    <div id='demo-container' className='container-fluid mt-5' style={{overflow: 'hidden'}}>
      <div id='grid-container' className='col-sm-12 custom-horizontal-scrollbar'>
        <SlickgridReact
          gridId={gridId}
          columnDefinitions={columnDefinitions}
          gridOptions={gridOptions}
          dataset={data}
        />
        <Tooltip id='my-tooltip' />
      </div>
    </div>
  )
}

export default AlleleFreqTableComponent
