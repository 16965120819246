import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  link?: string
  token?: string
  logo?: string
  className?: string
  fontIcon?: string
  target?: string
  width?: any
  hasBullet?: boolean
  disabled?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  width,
  target,
  link,
  token,
  className,
  logo,
  fontIcon,
  hasBullet = false,
  disabled = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  if (disabled) {
    return (
      <div className='menu-item'>
        <span
          className={clsx('menu-link without-sub', {active: isActive})}
          style={{opacity: '.5', cursor: 'default'}}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>
        </span>
        {children}
      </div>
    )
  }
  if (logo)
    return (
      <a
        className='menu-item'
        href={link + `${!!token ? `?token=${token}` : ''}`}
        target={!!token ? '_blank' : '_self'}
        rel='noreferrer'
      >
        <span className={clsx('menu-link without-sub', {active: isActive})}>
          <img
            src={`/media/logos/${logo}`}
            alt={logo}
            width={width ? width : 19}
            style={{
              flexShrink: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '2rem',
              marginRight: '0.5rem',
            }}
          />
          {children}
          <span className={`menu-title ${className ? className : ''}`}>{title}</span>
        </span>
      </a>
    )
  if (link)
    return (
      <a
        className='menu-item'
        href={link + `${!!token ? `?token=${token}` : ''}`}
        target={!!token || !!target ? '_blank' : '_self'}
        rel='noreferrer'
      >
        <span className={clsx('menu-link without-sub', {active: isActive})}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>
        </span>
        {children}
      </a>
    )

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
