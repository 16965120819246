/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'zh',
    name: 'Mandarin',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  {
    lang: 'de',
    name: 'German',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
]

const Languages: FC = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: any): any => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <>
      {/* <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt='metronic' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {languages.map((l) => (
          <div className='menu-item my-0' key={l.lang}>
            <a
              href='#'
              className={clsx('menu-link py-2')}
              onClick={() => {
                setLanguage(l.lang)
              }}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <img className='w-15px h-15px rounded-1 ms-2' src={l?.flag} alt='metronic' />
              </span>
              <span className='menu-title'>{l.lang.toUpperCase()}</span>
            </a>
          </div>
        ))}
      </div> */}
    </>
  )
}

export {Languages}
