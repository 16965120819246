/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'

const JsonImportPage = () => {
  const intl = useIntl()
  const [jsonContent, setJsonContent] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          setJsonContent(jsonData);
        } catch (error) {
          console.error('Invalid JSON file:', error);
        }
      };

      reader.readAsText(file);
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>Json Import</PageTitle>
      <div className='card'>
        <div className='card-body'>
          <input type="file" name="" id=""  className='form-control m-5 '  onChange={handleFileUpload}/>
            {jsonContent && (
        <div> 
          <pre>{JSON.stringify(jsonContent, null, 2)}</pre>
        </div>
      )}
        </div>
      </div>
    </>
  )
}

export {JsonImportPage}
