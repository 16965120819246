import React, {useState, useRef} from 'react'
import {useSpring, animated} from 'react-spring'
import {useDrag} from 'react-use-gesture'
import {AleleChart} from './Widgets/AleleChart'
import TxCitations from './Widgets/TxCitations'
import CosmicTable from './Widgets/CosmicTable'
import ColumnChart from './Widgets/ColumnChart'
import IsppWidget from './Widgets/IsppWidget'
import IGVApp from '../../app/pages/igv'
import {Tooltip} from 'react-tooltip'

const IgvTable = ({
  isDrawerOpen,
  selectedRow,
  toggleDrawer,
  selectedTab,
  allelicWidgetDatas,
  setSelectedRow,
}) => {
  const [drawerHeight, setDrawerHeight] = useState(44)
  const [drawerIsShow, setDrawerIsShow] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const startY = useRef(0)
  const [{y}, api] = useSpring(() => ({y: 0}))

  const bind = useDrag(({offset: [, oy]}) => {
    const deltaY = startY.current - oy
    if (oy !== 0) {
      setDrawerHeight((prevHeight) => Math.max(prevHeight + deltaY, 44))
      startY.current = oy
    }
  })

  const setShowDrawer = () => {
    if (drawerIsShow === false) {
      setDrawerIsShow(true)
      setDrawerHeight(484)
    } else {
      setDrawerIsShow(false)
      setDrawerHeight(44)
    }
    if (isDrawerOpen !== true) {
      toggleDrawer()
    }
  }

  return (
    <div className='bottom-drawer-container' style={{position: 'relative'}}>
      <animated.div
        className={`bottom-drawer ${isDrawerOpen ? 'open' : ''} relative`}
        style={{height: drawerHeight}}
      >
        <div className='drawer-header d-flex justify-content-between '>
          <div className='drawer-tabs flex'>
            <div
              className={` btn border  ${activeTab === 2 ? 'drawer-active-tab' : ''}`}
              onClick={() => {
                if (activeTab === 0 || activeTab === 2) {
                  setShowDrawer()
                }
                setActiveTab(2)
              }}
              style={{
                borderTopLeftRadius: '25px',
              }}
            >
              Variant Detail
            </div>
            <div
              className={` btn border  ${activeTab === 3 ? 'drawer-active-tab' : ''}`}
              data-tooltip-id='my-tooltip'
              data-tooltip-content='In-Silico Pathogenicity Predictor'
              onClick={() => {
                if (activeTab === 0 || activeTab === 3) {
                  setShowDrawer()
                }
                setActiveTab(3)
              }}
            >
              ISPP
            </div>
            <div
              className={` btn border  ${activeTab === 1 ? 'drawer-active-tab' : ''}`}
              onClick={() => {
                if (activeTab === 0 || activeTab === 1) {
                  setShowDrawer()
                }
                setActiveTab(1)
              }}
            >
              IGV
            </div>
          </div>
          <span {...bind()} className='draggable-element'>
            <i class='fa fa-arrows' aria-hidden='true'></i>
          </span>
          <div className='drawer-right d-flex align-items-center'>
            <div className='drawer-variant-summary font-italic'>
              {selectedRow.Chrom !== undefined
                ? `${selectedRow.Chrom}-${selectedRow.Start}:${selectedRow.Ref}/${selectedRow.Alt}`
                : ''}
            </div>
            {drawerIsShow && (
              <button className='btn pull-right' onClick={setShowDrawer}>
                <i className={`fa fa-chevron-down`}></i>
              </button>
            )}
          </div>
        </div>
        <div className={`drawer-tab tab-1 ${activeTab === 1 ? '' : 'd-none'} `}>
          {isDrawerOpen && <IGVApp selectedRow={selectedRow} />}
        </div>
        <div className={`drawer-tab tab-3 ${activeTab === 3 ? '' : 'd-none'} `}>
          <IsppWidget selectedRow={selectedRow} />
        </div>
        <div className={`drawer-tab tab-2 ${activeTab === 2 ? '' : 'd-none'} `}>
          <div className='charts row'>
            {selectedRow.Chrom !== undefined && (
              <>
                <div className='col-md-4'>
                  <AleleChart
                    selectedRow={selectedRow}
                    DP={selectedRow.DP}
                    setSelectedRow={setSelectedRow}
                    AD={selectedRow.AD}
                    allelicWidgetDatas={allelicWidgetDatas}
                    AF={selectedRow.AF}
                  />
                </div>
                <div className='col-md-5'>
                  <ColumnChart selectedRow={selectedRow} />
                </div>
                <div className='col-md-3'>
                  <CosmicTable cosmic={selectedRow.cosmic91} />
                </div>
                <div className='col-md-6'>
                  <TxCitations txC={selectedRow.TxCitations} />
                </div>
              </>
            )}
          </div>
        </div>
      </animated.div>
      <Tooltip id='my-tooltip' />
    </div>
  )
}

export default IgvTable
