import React, {useEffect} from 'react'
import FileInput from '../FileInput'
import OptionsSelect from '../OptionsSelect'
import SwitchInput from '../SwitchInput'

const PrsForm = ({sampleData, setSampleData, selectedProcessType}) => {
  useEffect(() => {
    if (sampleData.length === 0) {
      setSampleData([
        {
          id: '',
          name: '',
          fqR1: '',
          fqR2: '',
          regionFile: null,
          files: {},
        },
      ])
    }
  }, [sampleData])
  const prsTests = [
    {
      label: 'DermaGene',
    },
    {
      label: 'FitGene',
    },
    {
      label: 'NutriGene',
    },
    {
      label: 'DNAware',
    },
    {
      label: 'TalentGene',
    },
  ]
  const superPopulation = [
    {
      label: 'European',
    },
    {
      label: 'African',
    },
    {
      label: 'American',
    },
    {
      label: 'East asian',
    },
    {
      label: 'South asian',
    },
  ]
  return (
    <div>
      {/* <button
        className='btn btn-primary mb-1'
        onClick={() =>
          setSampleData((prev) => [
            ...prev,
            {
              id: '',
              name: '',
              fqR1: '',
              fqR2: '',
              regionFile: null,
            },
          ])
        }
      >
        +
      </button> */}

      {sampleData.map((sample, index) => (
        <div className='border mt-2 p-2'>
          {index > 0 && (
            <button
              className='btn btn-primary mb-1 pull-right'
              onClick={() => {
                const cloned = [...sampleData]
                cloned.splice(index, 1)
                setSampleData(cloned)
              }}
            >
              X
            </button>
          )}
          <input
            className='form-control mb-4'
            placeholder='Sample ID'
            value={sample.id}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].id = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <input
            className='form-control mb-4'
            placeholder='Sample Name (Optional) '
            value={sample.name}
            required
            onChange={(e) => {
              const clonedSampleData = [...sampleData]
              clonedSampleData[index].name = e.target.value
              setSampleData(clonedSampleData)
            }}
          />
          <div className='col-md-12 row my-2'>
            <div className='col-md-6'>
              <OptionsSelect
                className='form-control  my-2 '
                label='Test Type * '
                options={prsTests}
              />

              <div className='d-flex my-2'>
                <div className='checkboxes mx-3 w-100'>
                  <input
                    className='form-control mb-4'
                    placeholder='P-Value Cutoff (Exp: "1.0 x10 -5")'
                    // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
                    required
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <OptionsSelect
                className='form-control  my-2 '
                label='Super Population * '
                options={superPopulation}
              />
            </div>
            <div className='col-md-4'>
              <label className='my-2'> Linkage-disequilibrium Clumping</label>
              <SwitchInput
                label={'Sample-Wide Clumping'}
                className={'mx-5'}
                labelClass='linkage-label'
              />
              <SwitchInput
                label={'Individual Clumping'}
                className={'mx-5'}
                labelClass='linkage-label'
              />
            </div>
            <div className='col-md-4'>
              <label className='my-2'> MAF Threshold </label>
              <input className='form-control mb-4 w-50' type='number' value={0} required />
            </div>
            <div className='col-md-4'>
              <label className='my-2'> Imputation Threshold </label>
              <input
                className='form-control mb-4 w-50'
                type='number'
                value={0}
                // pattern='[a-zA-Z][a-zA-Z0-9-_.]{3,20}'
                required
              />
            </div>
          </div>
          <FileInput label={'Upload Genotype File'} />
        </div>
      ))}
    </div>
  )
}

export default PrsForm
